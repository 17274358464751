import { cx } from '@emotion/css';
import {
  BaseComponentPropTypes,
  colors,
  CustomUiText,
  FlexLayout,
  layoutHelpers,
  ResponsiveFontSizeBreakpoint,
  StyledLink,
} from '@imtbl/design-system';
import { SUPPORT_PAGE, TRANSACTIONS_PAGE } from 'config/pages';
import { useImxLink } from 'context/ImxLink';
import React from 'react';

import * as styles from './WalletDropdownMenu.styles';

type WalletDropdownMenuProps = BaseComponentPropTypes;

export const WalletDropdownMenu = ({ testId }: WalletDropdownMenuProps) => {
  const { link } = useImxLink();
  const responsiveFontSizing: ResponsiveFontSizeBreakpoint[] = [
    { breakpoint: 'medium', fontSize: 'small' },
  ];

  return (
    <FlexLayout
      width="100%"
      height="100%"
      responsiveProperties={[
        {
          breakpoint: 'medium',
          width: '264px',
          padding: layoutHelpers.gridUnits(1),
        },
      ]}
      padding={layoutHelpers.gridUnits(6)}
      flexDirection="column"
      justifyContent="flex-start"
      className={cx(styles.WalletDropdownContainer)}
      testId={testId}
    >
      <StyledLink
        onClick={link.history}
        testId="WalletDropdownMenu--transactions"
        className={cx(styles.MenuItem)}
        fontSize="large"
        responsiveFontSize={responsiveFontSizing}
        fontWeight="medium"
        TextComponentTag={CustomUiText}
        fillColor={colors.light[700]}
      >
        {TRANSACTIONS_PAGE.label}
      </StyledLink>
      <StyledLink
        href={SUPPORT_PAGE.url}
        underline={false}
        target="_blank"
        testId="WalletDropdownMenu--support"
        className={cx(styles.MenuItem)}
        fontSize="large"
        responsiveFontSize={responsiveFontSizing}
        fontWeight="medium"
        TextComponentTag={CustomUiText}
        fillColor={colors.light[700]}
      >
        {SUPPORT_PAGE.label}
      </StyledLink>
      <StyledLink
        onClick={link.disconnect}
        testId="WalletDropdownMenu--disconnectWallet"
        className={cx(styles.MenuItem, styles.DisconnectWalletButton)}
        fontWeight="bold"
        fontSize="large"
        responsiveFontSize={responsiveFontSizing}
        fillColor={colors.red[500]}
        TextComponentTag={CustomUiText}
      >
        Disconnect Wallet
      </StyledLink>
    </FlexLayout>
  );
};
