import {
  BalanceInfo,
  BigNumberT,
  ImmutableMethodResults,
} from '@imtbl/imx-sdk';

export enum SupportedTokens {
  eth = 'ETH',
  imxd = 'IMX',
  gods = 'GODS',
  fuel = 'FUEL',
  fuel2 = 'FUEL2',
  omi = 'OMI',
  usdc = 'USDC',
  erc20 = 'ERC20',
}

export enum LocalStorageKey {
  WALLET_ADDRESS = 'WALLET_ADDRESS',
  STARK_PUBLIC_KEY = 'STARK_PUBLIC_KEY',
  SEEN_BUILD_ON_IMMUTABLE_TOAST = 'SEEN_BUILD_ON_IMMUTABLE_TOAST',
  PROVIDER_PREFERENCE = 'PROVIDER_PREFERENCE',
}

export type ErrorOrNull = Error | null;

export interface FeatureFlags {
  [key: string]: string | boolean;
}

export interface ModalContentComponentPropTypes {
  modalId?: string;
  testId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ContextActionTypes<T extends string, P extends any = undefined> = [
  P,
] extends [undefined]
  ? { type: T }
  : {
      type: T;
      payload: P;
    };

export type ImxBalances = ImmutableMethodResults.ImmutableGetBalanceResult & {
  ethL1Balance?: BigNumberT;
  erc20L1Balance?: BalanceInfo;
};
