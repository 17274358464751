import {
  colors,
  measurements,
  OuterSpace,
  ParagraphText,
  PricingDisplay,
} from '@imtbl/design-system';
import { ERC20TokenType, ETHTokenType } from '@imtbl/imx-sdk';
import {
  formatTokenBalanceWithoutTruncation,
  useL1ERC20Balance,
  useL1EthBalance,
  useL2Balance,
} from 'api/balances';
import { pickTokenImagery, pickTokenSymbol, useTokens } from 'api/tokens';
import { useInflightPurchaseContext } from 'components/AssetDetailsContent/InflightPurchase';
import { getFundsAmountRequired } from 'components/AssetDetailsContent/utils';
import { useFiatPricing } from 'context/FiatPricing/FiatPricing';
import {
  createScreenEvent,
  ScreenEventName,
  sendAnalytics,
} from 'libs/analytics';
import { useEffect, useMemo } from 'react';
import { buildFiatEquivalentPrice } from 'utils/fiatPricing';

import {
  SidebarCancelButton,
  SidebarCreditCardCta,
  SidebarDepositCta,
  SidebarDescription,
  SidebarLayout,
  SidebarTitle,
} from '../shared';
import { FundsRequiredSidebarPanelProps } from '../shared/types';

export const FundsRequiredSidebarPanel = ({
  sidebarPanelId,
  testId = 'fundsRequiredSidebarPanel',
  showWalletDepositCta,
  observable,
}: FundsRequiredSidebarPanelProps) => {
  const { result: tokensList = [] } = useTokens().tokens ?? {};

  const { inflightAssets } = useInflightPurchaseContext();

  const firstInflightAsset = inflightAssets?.[0];

  const orderCurrencyTokenAddress = useMemo(
    () =>
      firstInflightAsset?.order?.buy?.type === 'ERC20'
        ? firstInflightAsset.order?.buy?.data?.token_address
        : 'eth',
    [firstInflightAsset?.order],
  );
  const { balanceL2: balance } = useL2Balance({
    tokenAddress: orderCurrencyTokenAddress,
  });

  const { erc20L1Balance } = useL1ERC20Balance({
    tokenAddress: orderCurrencyTokenAddress,
    observable,
  });

  const { ethL1Balance } = useL1EthBalance({ observable });

  const formattedBalance = useMemo(
    () => formatTokenBalanceWithoutTruncation(balance?.balance),
    [balance?.balance],
  );

  const { fiatPrices } = useFiatPricing();
  const fiatEquivalentPrice = useMemo(
    () =>
      balance
        ? buildFiatEquivalentPrice(
            formattedBalance,
            orderCurrencyTokenAddress,
            fiatPrices,
          )
        : undefined,
    [balance, fiatPrices, formattedBalance, orderCurrencyTokenAddress],
  );

  const tokenUrl = useMemo(
    () =>
      pickTokenImagery({
        tokenAddress: orderCurrencyTokenAddress,
        tokensList,
        tokenSymbol: firstInflightAsset?.order?.buy?.type || 'ETH',
      }),
    [
      firstInflightAsset?.order?.buy?.type,
      orderCurrencyTokenAddress,
      tokensList,
    ],
  );

  // Only ETH and ERC20 tokens are supported for deposit - we specify here to conform to deposit types.
  const prefillTokenType = useMemo(
    () =>
      (firstInflightAsset?.order?.buy?.type || ETHTokenType.ETH) as
        | ETHTokenType
        | ERC20TokenType,
    [firstInflightAsset?.order?.buy?.type],
  );

  const prefillTokenSymbol = useMemo(
    () =>
      pickTokenSymbol({
        tokenAddress: orderCurrencyTokenAddress,
        tokensList,
      }),
    [orderCurrencyTokenAddress, tokensList],
  );

  const amountRequired = useMemo(
    () => getFundsAmountRequired(firstInflightAsset?.order, balance),
    [balance, firstInflightAsset?.order],
  );

  const prefillTokenAddress = useMemo(
    // The balance endpoint requires 'eth' for ETH tokens, but link.deposit requires an empty string
    () =>
      orderCurrencyTokenAddress === 'eth' ? '' : orderCurrencyTokenAddress,
    [orderCurrencyTokenAddress],
  );

  const hasEnoughL1Funds = useMemo(
    () =>
      orderCurrencyTokenAddress === 'eth'
        ? ethL1Balance?.balance.gt(amountRequired)
        : erc20L1Balance?.balance.gt(amountRequired),
    [amountRequired, erc20L1Balance, ethL1Balance, orderCurrencyTokenAddress],
  );

  useEffect(() => {
    sendAnalytics(
      createScreenEvent(ScreenEventName.marketFundsRequiredSidebarOpened),
    );
  }, []);

  return (
    <SidebarLayout testId={testId}>
      <SidebarTitle testId={testId}>Funds required</SidebarTitle>
      <OuterSpace
        top={measurements.SpacingTeeShirtAmounts.large}
        bottom={measurements.SpacingTeeShirtAmounts.medium}
      >
        <ParagraphText fillColor={colors.light[300]} fontSize="small">
          Current balance:
        </ParagraphText>
      </OuterSpace>
      <PricingDisplay
        textAlign="left"
        tokenAmount={formattedBalance}
        tokenIconUrl={tokenUrl}
        fontSize="large"
        disableTooltip
        testId={`${testId}__pricingDisplay`}
        fiatEquivalentPrice={fiatEquivalentPrice}
      />
      <SidebarDescription testId={`${testId}__description`}>
        Add some <strong>{prefillTokenSymbol} tokens</strong> to your Immutable
        X account to complete your purchase.
      </SidebarDescription>
      <SidebarCreditCardCta sidebarPanelId={sidebarPanelId} />
      {showWalletDepositCta && (
        <SidebarDepositCta
          sidebarPanelId={sidebarPanelId}
          prefillInfo={{
            hasEnoughL1Funds,
            prefillTokenType,
            prefillTokenAddress,
            prefillTokenSymbol,
          }}
        />
      )}

      <SidebarCancelButton testId={testId} sidebarPanelId={sidebarPanelId} />
    </SidebarLayout>
  );
};
