import { colors, HeadingText } from '@imtbl/design-system';

import { SidebarPanelComponentProps } from './types';

export const SidebarTitle = ({
  children,
  testId,
}: SidebarPanelComponentProps) => (
  <HeadingText
    fillColor={colors.blue[300]}
    fontSize="x-large"
    testId={`${testId}__titleText`}
  >
    {children}
  </HeadingText>
);
