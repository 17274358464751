import { css } from '@emotion/css';
import {
  BaseComponentPropTypes,
  colors,
  FlexLayout,
  layoutHelpers,
  ParagraphText,
  SectionHeading,
  VerticalSpace,
} from '@imtbl/design-system';
import { StyledNextLink } from 'components/DsConnectorButtons';
import { ButtonEventName } from 'libs/analytics';
import { sendAnalytics } from 'libs/analytics/akuma-send';
import { createButtonEvent } from 'libs/analytics/utils';
import { useCallback } from 'react';
import ROUTES from 'utils/router';

interface ErrorPagePropTypes extends BaseComponentPropTypes {
  pageTitle: string;
  pageTitleColor: string;
  errorMessage?: string;
  showReturnToMarketplaceLink?: boolean;
}

export default function ErrorPage({
  pageTitle = '404',
  pageTitleColor,
  errorMessage = "Something's gone wrong...let's get you back to the Marketplace for now.",
  showReturnToMarketplaceLink = true,
}: ErrorPagePropTypes) {
  const onErrorBackClick = useCallback(() => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.marketErrorGenericBackToMPPressed),
    );
  }, []);

  return (
    <FlexLayout
      flexGrow={1}
      alignItems="flex-start"
      justifyContent="center"
      flexDirection="column"
      className={css`
        max-width: 976px;
      `}
      paddingLeft={layoutHelpers.gridUnits(4)}
      paddingRight={layoutHelpers.gridUnits(4)}
      marginLeft="auto"
      marginRight="auto"
    >
      <VerticalSpace both="medium">
        <SectionHeading
          testId="pageTitle"
          fillColor={pageTitleColor}
          fontSize="2x-large"
        >
          {pageTitle}
        </SectionHeading>
      </VerticalSpace>
      <ParagraphText
        textAlign="left"
        fontSize="normal"
        fillColor={colors.light[300]}
        style={{ whiteSpace: 'pre-wrap' }}
      >
        {errorMessage}
      </ParagraphText>

      {showReturnToMarketplaceLink && (
        <VerticalSpace top="medium">
          <StyledNextLink
            href={ROUTES.homePath()}
            fontWeight="bold"
            data-testid="link-to-mp"
            onClick={onErrorBackClick}
          >
            Return to Marketplace
          </StyledNextLink>
        </VerticalSpace>
      )}
    </FlexLayout>
  );
}
