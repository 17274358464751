import { BigNumber } from 'bignumber.js';

export const AMOUNT_TOO_SMALL_ERROR_TEXT =
  'Please enter an amount greater than 0';

export const DEFAULT_ERROR_TEXT = 'Please enter a valid number';

export function removeCommaFormatting(value: string) {
  return value?.replace(/,/g, '');
}

export function createPriceAmountValidators(value: BigNumber) {
  return [
    {
      check: () => !value.isNaN(),
      errorMsg: DEFAULT_ERROR_TEXT,
    },
    {
      check: () => value.isGreaterThan(0),
      errorMsg: AMOUNT_TOO_SMALL_ERROR_TEXT,
    },
  ];
}
