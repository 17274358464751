/* 
  @NOTE: 
  any styles relevant to multiple "Immutable" flavoured modals 
  should go here 
*/
import { css } from '@emotion/css';

export const customModalContent = css`
  background: radial-gradient(
    50% 50% at 50% 50%,
    var(--colors_bg_500) 0%,
    var(--colors_bg_800) 100%
  );
  display: flex;
  flex-direction: column;
  padding: 0;
`;

export const customModalBackdrop = css`
  background: var(--colors_bg_900);
  opacity: 0.8;
`;

export const modalContentContainer = css`
  width: 100%;
  max-width: 640px;
  margin: auto;
`;
