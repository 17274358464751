import { BaseRouter } from 'next/dist/shared/lib/router/router';

import { LocalStorageKey } from '../../types';
import {
  ButtonEventName,
  EventType,
  FlowEventName,
  LSUserId,
  PageEventName,
  ScreenEventName,
  SSUserId,
  TextEventName,
  UTMParam,
} from './constants';
import {
  ButtonAnalyticsEvent,
  ButtonEventProps,
  FlowAnalyticsEvent,
  FlowEventProps,
  LSUderIdKey,
  PageAnalyticsEvent,
  PageEventProps,
  ScreenAnalyticsEvent,
  ScreenEventProps,
  SSUserIdKey,
  TextAnalyticsEvent,
  TextEventProps,
} from './types';

const UTM_KEYS: string[] = Object.values(UTMParam);

export function createButtonEvent(
  name: ButtonEventName,
  props?: ButtonEventProps,
): ButtonAnalyticsEvent {
  return {
    eventName: name,
    eventType: EventType.button,
    eventProperties: props ?? {},
  };
}

export function createScreenEvent(
  name: ScreenEventName,
  props?: ScreenEventProps,
): ScreenAnalyticsEvent {
  return {
    eventName: name,
    eventType: EventType.screen,
    eventProperties: props ?? {},
  };
}

export function createPageEvent(
  name: PageEventName,
  props?: PageEventProps,
): PageAnalyticsEvent {
  return {
    eventName: name,
    eventType: EventType.page,
    eventProperties: props ?? {},
  };
}

export function createFlowEvent(
  name: FlowEventName,
  props?: FlowEventProps,
): FlowAnalyticsEvent {
  return {
    eventName: name,
    eventType: EventType.flow,
    eventProperties: props ?? {},
  };
}

export function createTextEvent(
  name: TextEventName,
  props?: TextEventProps,
): TextAnalyticsEvent {
  return {
    eventName: name,
    eventType: EventType.text,
    eventProperties: props ?? {},
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isFuncValid(func: any) {
  return typeof func === 'function';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStringValid(value: any) {
  return typeof value === 'string' && value.length > 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObjectValid(obj: any) {
  if (!obj || Array.isArray(obj)) return false;
  return typeof obj === 'object' && Object.keys(obj).length > 0;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isStorageValid(storage: any) {
  const ls = storage as Storage | undefined;
  return !!ls && isFuncValid(ls?.setItem) && isFuncValid(ls?.getItem);
}

export function filterUTMParams(query: BaseRouter['query']) {
  const utmParams: Record<string, string> = {};

  try {
    Object.entries(query).forEach(([key, value]) => {
      if (isStringValid(value) && UTM_KEYS.includes(key)) {
        utmParams[key] = value as string;
      }
    });
  } catch (error) {
    console.error('Could not get UTM parameters', error);
  }

  return utmParams;
}

export function resetUTMParams() {
  UTM_KEYS.forEach(key => localStorage.removeItem(key));
}

export function setUTMParams(params: Record<string, string>) {
  if (!isObjectValid(params) || !isStorageValid(localStorage)) {
    return;
  }

  Object.entries(params).forEach(([key, value]) => {
    if (isStringValid(value) && UTM_KEYS.includes(key)) {
      localStorage.setItem(key, value);
    }
  });
}

export function getUTMParams() {
  const utmParams: Record<string, string> = {};
  if (!isStorageValid(localStorage)) {
    return utmParams;
  }

  UTM_KEYS.forEach(key => {
    const value = localStorage.getItem(key) as string;
    if (isStringValid(value)) utmParams[key] = value;
  });

  return utmParams;
}

export function getAppName() {
  const { hostname: name } = window?.location ?? {};
  if (typeof name !== 'string' || !name.length) {
    return undefined;
  }

  return name.split('.').reverse().join('.') as string;
}

export function getUserAgent() {
  const { userAgent: agent } = window?.navigator ?? {};
  if (typeof agent !== 'string' || !agent.length) {
    return undefined;
  }

  return agent;
}

export function setUserId(id: string, key?: LSUderIdKey) {
  if (!isStringValid(id) || !isStorageValid(localStorage)) {
    return;
  }

  localStorage.setItem(key ?? LocalStorageKey.WALLET_ADDRESS, id.toLowerCase());
}

export function getWalletAddressFromLocalStorage() {
  if (!isStorageValid(localStorage)) {
    return undefined;
  }

  return localStorage.getItem(LocalStorageKey.WALLET_ADDRESS);
}

export function getIdFromLocalStorage(key?: LSUserId) {
  if (!isStorageValid(localStorage)) {
    return undefined;
  }

  if (key) {
    return localStorage.getItem(key);
  }

  return localStorage.getItem(LSUserId.uid);
}

export function setSessionId(id: string, key?: SSUserIdKey) {
  if (!isStringValid(id) || !isStorageValid(sessionStorage)) {
    return;
  }

  sessionStorage.setItem(key ?? SSUserId.sid, id.toLowerCase());
}

export function getSessionId(key?: SSUserId) {
  if (!isStorageValid(sessionStorage)) {
    return undefined;
  }

  if (key) {
    return sessionStorage.getItem(key);
  }

  return (
    sessionStorage.getItem(SSUserId.sid) ??
    // Default return cannot be be 'null'.
    undefined
  );
}
