import { IncomingMessage } from 'http';
import { FeatureFlags } from 'types';

import { isDevBuildEnvironment } from '../../environment';

const ANONNYMOUS_LD_USER = {
  /**
   * @see https://docs.launchdarkly.com/sdk/features/user-config#nodejs-client-side
   * @description We recommend using the same user key for every initialization and
   * then replacing that with the actual user key when you know who the user is.
   * This way LaunchDarkly counts the initialization user key only once against your MAU,
   * instead of every time you initialize.
   *
   * We can implement the client side sdk if we want to update the user key at a later date.
   */
  key: 'ssr-init',
};

/**
 * @NOTE: only init the LD client the one time. There it can sit dormant,
 * untilwe need to fetch some user specific flags
 * */
let launchDarklyClient;
export async function initialiseLaunchDarklyNodeClient() {
  try {
    // @NOTE: only initialise the launch darkly client the one time
    // and even then, only in the server
    if (typeof window === 'undefined') {
      if (launchDarklyClient === undefined) {
        // @NOTE: we have to await import this lib, because otherwise the client tries to
        // use it, and bombs out in JS errors. :-(
        const LaunchDarkly = await import('launchdarkly-node-client-sdk');
        launchDarklyClient = LaunchDarkly.initialize(
          process.env.LAUNCH_DARKLY_CLIENT_ID ?? '',
          { ...ANONNYMOUS_LD_USER },
        );
      }
      return await launchDarklyClient.waitForInitialization();
    }
  } catch (err) {
    console.error('LAUNCH DARKLY ERROR', err);
  }
}

/**
 * @NOTE: Fetches all the LD flags from the server for a specific LD user
 *
 * */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function setNewLdUserThenGetFlags(req: IncomingMessage) {
  const forwardedIps = req.headers['x-forwarded-for'];

  const socketIp = req.socket.remoteAddress;
  const ip = forwardedIps ? pickIpAddress(forwardedIps) : socketIp;

  const newLaunchDarklyUser = {
    ...ANONNYMOUS_LD_USER,
    ip,
  };

  let flags: FeatureFlags = {};

  try {
    await launchDarklyClient.identify(newLaunchDarklyUser);
    flags = launchDarklyClient.allFlags();

    // Inject and override flags for local development
    if (isDevBuildEnvironment) {
      const localOverRides = (await import('config/local-feature-flags.json'))
        .default;
      flags = { ...flags, ...localOverRides };
    }
  } catch (err) {
    console.error(err);
  }
  return flags;
}

function pickIpAddress(ipAddresses?: string[] | string) {
  const addressFromArray = Array.isArray(ipAddresses) && ipAddresses[0];
  const addressFromString =
    typeof ipAddresses === 'string' && ipAddresses.split(',')?.[0]?.trim();

  return addressFromArray || addressFromString || '';
}
