import { css, keyframes } from '@emotion/css';
import {
  colorHelpers,
  colors,
  layoutHelpers,
  styleHelpers,
} from '@imtbl/design-system';

export const FilteringSideBar___isMobile = css`
  width: 100%;
  background-color: ${colors.bg[800]};
  position: fixed;
  z-index: 20;
  justify-content: space-between;
  align-self: flex-start;
  bottom: 0px;
`;

export const FilteringSideBar___isMobile___openFromBottom = keyframes`
  from { top: 1000px;}
  to { top: var(--measurements_standardHeaderHeight); }
`;

export const FilteringSideBar___isMobile___isOpen = css`
  bottom: unset;
  height: calc(100vh - var(--measurements_standardHeaderHeight));
  top: var(--measurements_standardHeaderHeight);
  animation: ${FilteringSideBar___isMobile___openFromBottom} 0.3s;
`;

export const FilteringSideBar___isMobile___isClosed = css`
  background-color: ${`rgba(${colorHelpers.hexToCssRgbString(
    colors.bg[900],
  )}, 0.8)`};
`;

export const LEFT_SIDEBAR_WIDTH = '300px';
export const FilteringSideBar___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
    min-width: ${LEFT_SIDEBAR_WIDTH};
    width: ${LEFT_SIDEBAR_WIDTH};
    max-width: ${LEFT_SIDEBAR_WIDTH};
    background-image: linear-gradient(
    to bottom,
    rgba(var(--colors_bg_900-rgb), 1) 0%,
    rgba(var(--colors_bg_900-rgb), 0) 100%
  );
  position: sticky;
  align-self: flex-start;
  top: var(--measurements_standardHeaderHeight);
  height: calc(100vh - var(--measurements_standardHeaderHeight));
`,
  );

export const FilteringSideBar_ChildrenWrapper___isMobile___isOpen = css`
  overflow-y: auto;
`;
export const FilteringSideBar_ChildrenWrapper___isMobile___isClosed = css`
  display: none;
`;

export const FilteringSideBar_ChildrenWrapper___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
display: flex;
overflow-y: auto;
`,
  );

export const FilteringSideBar_ButtonWrapper___isMobile = css`
  padding: ${layoutHelpers.gridUnits(4)} ${layoutHelpers.gridUnits(3)};
  backdrop-filter: blur(10px);
`;

export const FilteringSideBar_ButtonWrapper___isMobile___isOpen = css`
  position: sticky;
  width: 100%;
  bottom: 0px;
`;
export const FilteringSideBar_ButtonWrapper___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
  display: none;
`,
  );

export const FilteringSideBar_Button___filters = css`
  color: ${colors.blue[500]};
  border-radius: 5px;
  border: 1px solid ${colors.blue[500]};
  background-color: ${`rgba(${colorHelpers.hexToCssRgbString(
    colors.blue[500],
  )}, 0.1)`};

  &:hover {
    background-color: ${`rgba(${colorHelpers.hexToCssRgbString(
      colors.blue[500],
    )}, 0.3)`};
  }
`;

export const FilteringSideBar_Button___done = css`
  color: ${colors.dark[700]};
  border-radius: 5px;
  border: 1px solid ${colors.light[300]};
  background-color: ${`rgba(${colorHelpers.hexToCssRgbString(
    colors.white,
  )}, 0.85)`};

  &:hover {
    background-color: ${`rgba(${colorHelpers.hexToCssRgbString(
      colors.white,
    )}, 0.7)`};
  }
`;
