import {
  colors,
  DescriptiveCta,
  measurements,
  OnClickButton,
  OuterSpace,
} from '@imtbl/design-system';
import { openMoonpayLoadingSidebarPanel } from 'components/shared/SidebarPanelScreens/MoonpayLoading';
import { useImxLink } from 'context/ImxLink';
import {
  ButtonEventName,
  createButtonEvent,
  sendAnalytics,
} from 'libs/analytics';
import { useCallback } from 'react';

import { closeSidebarPanel } from '../rewireable-imports';
import { SidebarPanelComponentProps } from './types';

export const SidebarCreditCardCta = ({
  sidebarPanelId,
  testId = 'sidebarCreditCardCta',
}: SidebarPanelComponentProps) => {
  const { link } = useImxLink();

  const closeThisSidebarPanel = useCallback(
    () => closeSidebarPanel(sidebarPanelId),
    [sidebarPanelId],
  );
  const creditCardCtaOnClick = useCallback(async () => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.marketSidebarCardCtaBtnPressed),
    );
    closeThisSidebarPanel();
    try {
      await link.fiatToCrypto();
    } catch (error) {
      console.error(error);
      return;
    }

    openMoonpayLoadingSidebarPanel();
  }, [link, closeThisSidebarPanel]);

  return (
    <OuterSpace
      top={measurements.SpacingTeeShirtAmounts.large}
      bottom={measurements.SpacingTeeShirtAmounts.large}
    >
      <OnClickButton
        onClick={creditCardCtaOnClick}
        testId={`${testId}__button`}
      >
        <DescriptiveCta
          testId={`${testId}__button__descriptiveCta`}
          subtitleText="Instant"
          titleText="Credit card"
          bodyText="Purchase and add tokens directly to your Immutable account."
          iconLigature="misc_credit_card"
          subtitleFillColor={colors.blue[300]}
          iconFillColor={colors.blue[300]}
        />
      </OnClickButton>
    </OuterSpace>
  );
};
