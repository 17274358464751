import {
  AssetListingGridPropTypes,
  breakpoints,
  layoutHelpers,
} from '@imtbl/design-system';
import { LEFT_SIDEBAR_WIDTH } from 'components/FilteringSideBar/FilteringSideBar.styles';

export const NUMBER_OF_SIGNIFICANT_DIGITS = 8;
export const CURRENT_YEAR = new Date().getFullYear();
export const IMX_RESIZER_SERVICE_ENV_URL =
  process.env.NEXT_PUBLIC_IMAGE_RESIZER_URL;

export const RESPONSIVE_ASSET_GRID_PROPS = {
  assetItemMinWidth: '172px',
  responsiveGridProperties: [
    {
      breakpoint: '2x-small',
      assetItemMinWidth: '40%',
    },
    {
      breakpoint: 'small',
      assetItemMinWidth: '235px',
    },
  ],
} as AssetListingGridPropTypes;

// @NOTE: these sizes are used to render ResponsiveImages.
// Basically, they tell the browser roughly the size of images
// when images are displayed inside of the AssetGrid at various screen sizes
export const SINGLE_COLUMN_GUTTER = layoutHelpers.gridUnits(3);
const ROW_OF_2 = `calc((100vw - (${SINGLE_COLUMN_GUTTER} * 3)) / 2)`;
const ROW_OF_1 = `calc(100vw - (${SINGLE_COLUMN_GUTTER} * 2))`;
const rowOfItemsWithSidebar = (itemsCount: number) =>
  `calc((100vw - ${LEFT_SIDEBAR_WIDTH} - (${SINGLE_COLUMN_GUTTER} * ${
    itemsCount + 1
  })) / ${itemsCount})`;
export const RELATIVE_TOKEN_ASSET_GRID_ITEM_SIZE = `
  (min-width: 1730px) ${rowOfItemsWithSidebar(5)},
  (min-width: 1470px) ${rowOfItemsWithSidebar(4)},
  (min-width: 1160px) ${rowOfItemsWithSidebar(3)},
  (min-width: ${
    breakpoints.breakpointSizeMapping['small']
  }) ${rowOfItemsWithSidebar(2)},
  (min-width: ${breakpoints.breakpointSizeMapping['2x-small']}) ${ROW_OF_2},
  ${ROW_OF_1}
`;

const ROW_OF_4_COLLECTIONS = `calc((100vw - (${SINGLE_COLUMN_GUTTER} * 5)) / 4)`;
const ROW_OF_3_COLLECTIONS = `calc((100vw - (${SINGLE_COLUMN_GUTTER} * 4)) / 3)`;
export const RELATIVE_COLLECTION_ASSET_GRID_ITEM_SIZE = `
  (min-width: ${breakpoints.breakpointSizeMapping.large}) 235px,
  (min-width: 1140px) ${ROW_OF_4_COLLECTIONS},
  (min-width: 868px) ${ROW_OF_3_COLLECTIONS},
  (min-width: ${breakpoints.breakpointSizeMapping['2x-small']}) ${ROW_OF_2},
  ${ROW_OF_1}
`;

export const RELATIVE_FEATURED_COLLECTION_ASSET_GRID_ITEM_SIZE = `
  (min-width: ${breakpoints.breakpointSizeMapping.small}) ${ROW_OF_3_COLLECTIONS},
  (min-width: ${breakpoints.breakpointSizeMapping.medium}) 340px,
  ${ROW_OF_1}
`;
