import { ERC20TokenType, ETHTokenType, LinkParams } from '@imtbl/imx-sdk';
import { showErrorToast, showSuccessToast } from 'components/Toast';
import { FlowAnalyticsEvent, FlowEventName } from 'libs/analytics';
import { sendAnalytics } from 'libs/analytics/akuma-send';
import { createFlowEvent } from 'libs/analytics/utils';
import { Dispatch } from 'react';
import { LocalStorageKey } from 'types';
import { delay } from 'utils/delayOrDebounce';
import { closeLastOpenedModal } from 'utils/modal';
import ROUTES from 'utils/router';
import { identifyUser } from 'utils/user-logging';

import { StyledNextLink } from '../../components/DsConnectorButtons';
import {
  acceptOfferError,
  acceptOfferInit,
  acceptOfferSuccess,
  buyError,
  buyInit,
  buySuccess,
  cancelError,
  cancelInit,
  cancelOfferError,
  cancelOfferInit,
  cancelOfferSuccess,
  cancelSuccess,
  closeLinkSidebar,
  completeWithdrawError,
  completeWithdrawInit,
  completeWithdrawSuccess,
  cryptoToFiatError,
  cryptoToFiatInit,
  cryptoToFiatSuccess,
  depositError,
  depositInit,
  depositSuccess,
  fiatToCryptoError,
  fiatToCryptoInit,
  fiatToCryptoSuccess,
  historyError,
  historyInit,
  historySuccess,
  makeOfferError,
  makeOfferInit,
  makeOfferSuccess,
  openLinkSidebar,
  prepareWithdrawError,
  prepareWithdrawInit,
  prepareWithdrawSuccess,
  sellError,
  sellInit,
  sellSuccess,
  walletDisconnect,
  walletSetup,
  walletSetupError,
  walletSetupSuccess,
} from './actions';
import {
  linkAddInfoEventListener,
  linkRemoveInfoEventListener,
} from './link-event-handler';
import { Link, removeToast } from './rewirable-imports';
import {
  CustomBuyParams,
  CustomSetupParams,
  ImxLinkActionTypes,
} from './types';

export const DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE = 2000;

/**
 * NOTE: This will remove the Link iframe from the DOM
 * and keep any hidden iframes in the DOM (for syncState)
 * @param dispatch - dispatch actions to the ImxLinkReducer
 */
export function closeLinkSidebarTriggerLinkClose(
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  dispatch(closeLinkSidebar());
  const iframeContainerElements = document.querySelectorAll(
    '.imxLinkIframeContainer',
  );
  // Need to get the close button inside the link iframe (that is not syncState)
  // and click the close button in order to trigger link close events
  // as merely unmounting the iframe from the dom does not trigger close events
  iframeContainerElements?.forEach(iframeContainer => {
    if (!iframeContainer.hasAttribute('hidden')) {
      const closeButton = iframeContainer.querySelector(
        '.imxLinkIframeContainer__closeButton',
      ) as HTMLButtonElement;
      closeButton?.click();
    }
  });
}

export async function setup(
  link: Link,
  params: CustomSetupParams,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(walletSetup());

    /**
     * If this is a returning user with a connected wallet, get the
     * wallet address from local storage, skip calling 'link.setup()'.
     */
    let walletAddress =
      localStorage.getItem(LocalStorageKey.WALLET_ADDRESS) ?? '';
    let starkPublicKey =
      localStorage.getItem(LocalStorageKey.STARK_PUBLIC_KEY) ?? '';
    let res;

    if (!walletAddress) {
      const setupParams = params.providerPreference
        ? {
            providerPreference: params.providerPreference,
          }
        : {};
      // No wallet in local storage, call 'link.setup()' and store it.
      res = await link.setup(setupParams);
      walletAddress = res.address;
      starkPublicKey = res.starkPublicKey;
      identifyUser(walletAddress);
      sendAnalytics(
        createFlowEvent(FlowEventName.connectWalletSucceeded, {
          walletAddress,
        }),
      );
      localStorage.setItem(LocalStorageKey.WALLET_ADDRESS, walletAddress);
      localStorage.setItem(LocalStorageKey.STARK_PUBLIC_KEY, starkPublicKey);
    } else {
      // @NOTE: we have pulled the wallet address out of LS, so make sure that
      // various logging / trackers are aware of this user:
      identifyUser(walletAddress);
    }

    dispatch(
      walletSetupSuccess({
        starkPublicKey,
        walletAddress,
      }),
    );
  } catch (error) {
    dispatch(walletSetupError(error as Error));
    showErrorToast('Wallet connection failed', 'setupErrorToast');
    throw new Error('Wallet connection failed');
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function buy(
  link: Link,
  customParams: CustomBuyParams,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    const { assetId, price, fees, ...params } = customParams;
    dispatch(buyInit(params));
    linkAddInfoEventListener(dispatch);

    // @TODO - Add analytics for stacks as well!
    if (customParams.price && customParams.assetId) {
      sendAnalytics(
        createFlowEvent(FlowEventName.buyAssetNowStarted, {
          assetId,
          price,
        }),
      );
    }

    /**
     * NOTE: The API takes a while to update the asset status,
     * so wait for approximately '1000' ms, then complete the
     * transaction by updating orders, asset list and details
     */
    // Either spread orderIds or pass in an array of orderIds
    await link.buy({
      orderIds:
        typeof params.orderId === 'string' ? [params.orderId] : params.orderId,
      fees,
    });

    showSuccessToast('Purchase complete', 'buySuccessToast');
    await delay(DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE, params);
    dispatch(buySuccess(params));
  } catch (error) {
    dispatch(buyError(error as Error));
  } finally {
    linkRemoveInfoEventListener(dispatch);
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function sell(
  link: Link,
  params: LinkParams.Sell,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    const { tokenId: assetId, amount: price } = params;
    dispatch(sellInit(params));

    sendAnalytics(
      createFlowEvent(FlowEventName.listForSaleStarted, {
        assetId,
        price,
      }),
    );

    /**
     * NOTE: The API takes a while to update the asset status,
     * so wait for approximately '1000' ms, then complete the
     * transaction by updating orders, asset list and details
     */
    await link.sell(params);
    showSuccessToast('Listing confirmed', 'sellSuccessToast');
    await delay(DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE, params);
    dispatch(sellSuccess(params));
  } catch (error) {
    dispatch(sellError(error as Error));
    showErrorToast('Listing failed', 'sellErrorToast');
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function cancel(
  link: Link,
  params: LinkParams.Cancel,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(cancelInit(params));
    sendAnalytics(createFlowEvent(FlowEventName.cancelListingStarted));

    /**
     * NOTE: The API takes a while to update the asset status,
     * so wait for approximately '1000' ms, then complete the
     * transaction by updating orders, asset list and details
     */
    await link.cancel(params);
    showSuccessToast('Listing cancelled', 'cancelSuccessToast');
    await delay(DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE, params);
    dispatch(cancelSuccess(params));
  } catch (error) {
    showErrorToast('Listing cancellation failed', 'cancelErrorToast');
    dispatch(cancelError(error as Error));
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function deposit(
  link: Link,
  params: LinkParams.Deposit,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(depositInit(params));
    await link.deposit(params);
    dispatch(depositSuccess(params));

    closeLastOpenedModal();
    showSuccessToast('Deposit is on its way', 'depositSuccessToast');
  } catch (error) {
    showErrorToast('Deposit failed', 'depositErrorToast');
    dispatch(
      depositError({
        error: error as Error,
        data: params,
      }),
    );
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}
function sendPrepareWithdrawFlowEvent(
  name: FlowEventName,
  params: LinkParams.PrepareWithdrawal,
  walletAddress: string,
  errorText?: unknown,
) {
  let flowEvent: FlowAnalyticsEvent | null;
  switch (params.type) {
    case ETHTokenType.ETH:
      flowEvent = createFlowEvent(name, {
        amount: params?.amount,
        tokenType: params?.type,
        tokenAddress: ETHTokenType.ETH,
        tokenSymbol: ETHTokenType.ETH,
        walletAddress,
        ...(errorText ? { errorText } : {}),
      });
      break;
    case ERC20TokenType.ERC20:
      flowEvent = createFlowEvent(name, {
        amount: params?.amount,
        tokenType: params?.type,
        tokenAddress: params?.tokenAddress,
        tokenSymbol: params?.symbol,
        walletAddress,
        ...(errorText ? { errorText } : {}),
      });
      break;
    default:
      flowEvent = null;
      break;
  }
  if (flowEvent) sendAnalytics(flowEvent);
}

export async function prepareWithdraw(
  link: Link,
  params: LinkParams.PrepareWithdrawal,
  dispatch: Dispatch<ImxLinkActionTypes>,
  walletAddress: string,
  enableRevampedPrepareWithdrawlFeatureFlag: boolean,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(prepareWithdrawInit());
    await link.prepareWithdrawal(params);
    dispatch(prepareWithdrawSuccess(params));
    if (enableRevampedPrepareWithdrawlFeatureFlag) {
      sendPrepareWithdrawFlowEvent(
        FlowEventName.prepareWithdrawErc20Succeeded,
        params,
        walletAddress,
      );
    }

    // @TODO: this can be removed once we deprecate the FF: mp938_revampedPrepareWithdrawFlow
    closeLastOpenedModal();
    const successToastId = showSuccessToast(
      <>
        Now Preparing withdrawal.{' '}
        <StyledNextLink
          fontSize="small"
          fontWeight="bold"
          href={ROUTES.inventoryAssetsPath()}
          testId="moreInfoButton"
          onClick={() => removeToast(successToastId)}
        >
          More info
        </StyledNextLink>
      </>,
      'prepareWithdrawSuccessToast',
    );
  } catch (error) {
    showErrorToast('Withdraw preparation failed', 'prepareWithdrawErrorToast');
    dispatch(prepareWithdrawError(error as Error));
    if (enableRevampedPrepareWithdrawlFeatureFlag) {
      sendPrepareWithdrawFlowEvent(
        FlowEventName.prepareWithdrawErc20Failed,
        params,
        walletAddress,
        error,
      );
    }
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function completeWithdraw(
  link: Link,
  params: LinkParams.CompleteWithdrawal,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(completeWithdrawInit());
    await link.completeWithdrawal(params);
    dispatch(completeWithdrawSuccess(params));

    closeLastOpenedModal();
    showSuccessToast('Withdrawal in progress', 'completeWithdrawSuccessToast');
  } catch (error) {
    showErrorToast('Withdrawal failed', 'completeWithdrawErrorToast');
    dispatch(completeWithdrawError(error as Error));
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export function disconnect(dispatch: Dispatch<ImxLinkActionTypes>) {
  dispatch(walletDisconnect());
  localStorage.removeItem(LocalStorageKey.WALLET_ADDRESS);
  localStorage.removeItem(LocalStorageKey.STARK_PUBLIC_KEY);
  showSuccessToast('Wallet disconnected', 'disconnectSuccessToast');
}

export async function history(
  link: Link,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(historyInit());
    await link.history({});
    dispatch(historySuccess());
  } catch (error) {
    dispatch(historyError(error as Error));
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function fiatToCrypto(
  link: Link,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(fiatToCryptoInit());
    await link.fiatToCrypto({});
    dispatch(fiatToCryptoSuccess());
  } catch (error) {
    dispatch(fiatToCryptoError(error as Error));
    showErrorToast(
      'Something went wrong while loading our credit card service. Please retry.',
    );
    throw new Error('Fiat to crypto call failed');
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function cryptoToFiat(
  link: Link,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(cryptoToFiatInit());
    await link.cryptoToFiat({});
    dispatch(cryptoToFiatSuccess());
  } catch (error) {
    dispatch(cryptoToFiatError(error as Error));
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function makeOffer(
  link: Link,
  params: LinkParams.MakeOffer,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(makeOfferInit(params));
    await link.makeOffer(params);
    showSuccessToast('New offer created', 'makeOfferSuccessToast');
    await delay(DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE, params);
    dispatch(makeOfferSuccess(params));
  } catch (error) {
    dispatch(makeOfferError(error as Error));
    showErrorToast(
      'Something went wrong while making the offer. Please retry.',
    );
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function cancelOffer(
  link: Link,
  params: LinkParams.CancelOffer,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(cancelOfferInit(params));
    await link.cancelOffer(params);
    showSuccessToast(
      'Your offer has been cancelled',
      'cancelOfferSuccessToast',
    );
    await delay(DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE, params);
    dispatch(cancelOfferSuccess(params));
  } catch (error) {
    dispatch(cancelOfferError(error as Error));
    showErrorToast(
      'Something went wrong while cancelling your offer. Please retry.',
    );
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}

export async function acceptOffer(
  link: Link,
  params: LinkParams.AcceptOffer,
  dispatch: Dispatch<ImxLinkActionTypes>,
) {
  try {
    dispatch(openLinkSidebar());
    dispatch(acceptOfferInit(params));
    await link.acceptOffer(params);
    showSuccessToast(
      'You just accepted an offer and sold your asset.',
      'acceptOfferSuccessToast',
      false,
    );
    await delay(DELAY_WAITING_FOR_PUBLIC_API_TO_UPDATE, params);
    dispatch(acceptOfferSuccess(params));
  } catch (error) {
    dispatch(acceptOfferError(error as Error));
    showErrorToast(
      'Something went wrong while accepting the offer. Please retry.',
    );
  } finally {
    closeLinkSidebarTriggerLinkClose(dispatch);
  }
}
