import {
  Box,
  colors,
  FlexLayout,
  layoutHelpers,
  ParagraphText,
  RectangularLoader,
  SidebarPanelChildContentPropTypes,
} from '@imtbl/design-system';
import { ProviderPreference } from '@imtbl/imx-sdk';
import { GetBalanceResponse, useL2Balance } from 'api/balances';
import { useInflightPurchaseContext } from 'components/AssetDetailsContent/InflightPurchase';
import {
  getForSalePrice,
  isAssetAffordable,
} from 'components/AssetDetailsContent/utils';
import { openFundsProcessingSidebarPanel } from 'components/shared/SidebarPanelScreens/FundsProcessingDelay/utils';
import { openFundsRequiredSidebarPanel } from 'components/shared/SidebarPanelScreens/FundsRequired';
import { closeSidebarPanel } from 'components/shared/SidebarPanelScreens/rewireable-imports';
import { getProviderPreferenceFromLocalStorage } from 'components/shared/utils';
import { FEATURE_FLAG } from 'config/feature-flags';
import { checkFeeIsGreaterThanZero, takerFees } from 'config/fees';
import { useImxLink } from 'context/ImxLink';
import { useFeatureFlag } from 'hooks/useFeatureFlag.hook';
import { useInterval } from 'hooks/useInterval';
import {
  createScreenEvent,
  ScreenEventName,
  sendAnalytics,
} from 'libs/analytics';
import { useEffect, useMemo, useState } from 'react';

const DEFAULT_MOONPAY_POLLING_TIME_LIMIT = 30000;

export type MoonpayLoadingSidebarPanelProps =
  SidebarPanelChildContentPropTypes & {
    timeoutOverride?: number;
    intervalOverride?: number;
  };

export const MoonpayLoadingSidebarPanel = ({
  sidebarPanelId,
  timeoutOverride,
  intervalOverride,
}: MoonpayLoadingSidebarPanelProps) => {
  const {
    link: { buy },
  } = useImxLink();
  const { inflightAssets, initialBalance } = useInflightPurchaseContext();

  const firstInflightAsset = inflightAssets?.[0];
  const [providerPreference, setProviderPreference] =
    useState<ProviderPreference | null>(null);
  const enableTakerFees =
    useFeatureFlag(FEATURE_FLAG.ENABLE_TAKER_FEES) &&
    checkFeeIsGreaterThanZero(takerFees);
  const forSalePrice = getForSalePrice(firstInflightAsset?.order);
  const orderId = firstInflightAsset?.order?.order_id.toString() ?? '';
  const assetId = firstInflightAsset?.asset?.token_id.toString() ?? '';
  const orderCurrencyTokenAddress = useMemo(
    () =>
      firstInflightAsset?.order?.buy?.type === 'ERC20'
        ? firstInflightAsset?.order?.buy?.data?.token_address
        : 'eth',
    [firstInflightAsset?.order],
  );
  const defaultInterval = 1000;
  const interval = intervalOverride ?? defaultInterval;
  const { mutate } = useL2Balance({
    tokenAddress: orderCurrencyTokenAddress,
  });

  const [timeUntilNextIteration, setTimeUntilNextIteration] = useState<
    number | undefined
  >(interval);
  const [accumulatedWaitTime, setAccumulatedWaitTime] = useState<number>(0);
  const pollingTimeLimit =
    timeoutOverride ?? DEFAULT_MOONPAY_POLLING_TIME_LIMIT;

  const intervalRef = useInterval(() => {
    let repolledBalance: GetBalanceResponse;

    const pollBalance = async () => {
      try {
        repolledBalance = await mutate();
      } catch (error) {
        console.error('Error: balance polling failed: ', error);
      }

      const depositHasBeenMade =
        repolledBalance &&
        initialBalance &&
        repolledBalance.balance.gt(initialBalance.balance);

      if (depositHasBeenMade) {
        const canUserAfford = isAssetAffordable(
          firstInflightAsset?.order,
          repolledBalance,
        );

        setTimeUntilNextIteration(undefined);
        closeSidebarPanel(sidebarPanelId);
        if (canUserAfford) {
          buy({
            orderId,
            assetId,
            price: forSalePrice,
            fees: enableTakerFees ? takerFees : [],
          });
        } else {
          openFundsRequiredSidebarPanel({
            showWalletDepositCta:
              providerPreference !== ProviderPreference.MAGIC_LINK,
          });
        }
      }
    };

    pollBalance();

    if (accumulatedWaitTime > pollingTimeLimit) {
      closeSidebarPanel(sidebarPanelId);
      openFundsProcessingSidebarPanel();
      setTimeUntilNextIteration(undefined);
    }
    setAccumulatedWaitTime(accumulatedWaitTime + interval);
  }, timeUntilNextIteration);

  useEffect(() => {
    setProviderPreference(() => getProviderPreferenceFromLocalStorage());
    sendAnalytics(
      createScreenEvent(ScreenEventName.marketMoonpayPollingSidebarOpened),
    );
  }, []);

  useEffect(() => {
    return () => {
      clearInterval(intervalRef as any);
    };
  }, [intervalRef]);

  return (
    <FlexLayout
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
    >
      <RectangularLoader lineFillColor={colors.light[100]} />
      <Box padding={layoutHelpers.gridUnits(3)}>
        <ParagraphText fillColor={colors.blue[100]} fontSize="large">
          Processing your deposit
        </ParagraphText>
      </Box>
    </FlexLayout>
  );
};
