import {
  Modal,
  ModalContext,
  SidebarPanel,
  SidebarPanelContext,
} from '@imtbl/design-system';
import { useImxLink } from 'context/ImxLink';
import { useContext, useEffect, useState } from 'react';

function checkForAnyReasonsToHideOverflow(
  modalList: Modal[],
  sidebarPanelList: SidebarPanel[],
  linkSidebarIsOpen: boolean,
) {
  return (
    modalList.length > 0 || sidebarPanelList.length > 0 || linkSidebarIsOpen
  );
}

export const BodyOverflowService = () => {
  const [bodyOverflowIsHidden, setBodyOverflowIsHidden] = useState(false);
  const { linkState } = useImxLink();
  const { modalList } = useContext(ModalContext);
  const { sidebarPanelList } = useContext(SidebarPanelContext);

  useEffect(
    () =>
      checkForAnyReasonsToHideOverflow(
        modalList,
        sidebarPanelList,
        linkState.isLinkSidebarOpen,
      )
        ? setBodyOverflowIsHidden(true)
        : setBodyOverflowIsHidden(false),
    [modalList, sidebarPanelList, linkState],
  );

  useEffect(() => {
    if (bodyOverflowIsHidden) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [bodyOverflowIsHidden]);

  return null;
};
