import { cx } from '@emotion/css';
import {
  colors,
  FlexLayout,
  HyperLink,
  ImmutableLogoFullWordmarkAndSymbolLight,
  layoutHelpers,
  SimpleText,
  StyledLink,
} from '@imtbl/design-system';
import { EXTERNAL_LINKS } from 'config/external-links';
import {
  COLLECTION_STATEMENT_PAGE,
  PRIVACY_PAGE,
  TERMS_PAGE,
} from 'config/pages';
import { ButtonEventName } from 'libs/analytics';
import { sendAnalytics } from 'libs/analytics/akuma-send';
import { createButtonEvent } from 'libs/analytics/utils';
import { CURRENT_YEAR } from 'utils/constants';

import { versions } from '../../utils/versions';
import {
  copyrightLink,
  footer___isDesktop,
  footer___isMobile,
  footerLink,
  poweredByLogo,
  poweredByLogos___isDesktop,
  poweredByLogos___isMobile,
  starkwareLogo,
  termsPrivacyCopyright,
  version,
} from './AppFooter.styles';

export function AppFooter() {
  const onLinkClick = (linkTo?: string) => () => {
    const NAV_BTN_EVENT_NAMES = {
      [PRIVACY_PAGE.url]: ButtonEventName.globalNavPrivacyLinkPressed,
      [TERMS_PAGE.url]: ButtonEventName.globalNavConditionsLinkPressed,
      [COLLECTION_STATEMENT_PAGE.url]:
        ButtonEventName.globalNavCollectionStatementLinkPressed,
    };
    const name: ButtonEventName | undefined = linkTo
      ? NAV_BTN_EVENT_NAMES[linkTo]
      : undefined;
    if (name) {
      sendAnalytics(createButtonEvent(name));
    }
  };

  const { marketplaceVersion } = versions;
  const publicMarketplaceVersion = marketplaceVersion.includes('-')
    ? marketplaceVersion.split('-')[1]
    : '';

  return (
    <footer
      className={cx(footer___isMobile, footer___isDesktop)}
      data-testid="appFooter"
    >
      <FlexLayout flexDirection="row" className={termsPrivacyCopyright}>
        <StyledLink
          target="_blank"
          fontSize="tag"
          fontWeight="bold"
          href={TERMS_PAGE.url}
          anchorDomProps={{ target: '_blank' }}
          className={footerLink}
          onClick={onLinkClick(TERMS_PAGE.url)}
          underline={false}
          testId="termsLink"
        >
          {TERMS_PAGE.label}
        </StyledLink>

        <StyledLink
          target="_blank"
          fontSize="tag"
          fontWeight="bold"
          href={PRIVACY_PAGE.url}
          anchorDomProps={{ target: '_blank' }}
          className={footerLink}
          onClick={onLinkClick(PRIVACY_PAGE.url)}
          underline={false}
          testId="privacyLink"
        >
          {PRIVACY_PAGE.label}
        </StyledLink>

        <StyledLink
          target="_blank"
          fontSize="tag"
          fontWeight="bold"
          href={COLLECTION_STATEMENT_PAGE.url}
          anchorDomProps={{ target: '_blank' }}
          className={footerLink}
          onClick={onLinkClick(COLLECTION_STATEMENT_PAGE.url)}
          underline={false}
          testId="collectionStatementLink"
        >
          {COLLECTION_STATEMENT_PAGE.label}
        </StyledLink>

        <SimpleText
          fontSize="tag"
          fontWeight="bold"
          fillColor={colors.light[100]}
          className={cx(footerLink, copyrightLink)}
        >
          Immutable &copy; {CURRENT_YEAR}
        </SimpleText>
      </FlexLayout>
      <FlexLayout
        flexDirection="column"
        rowGap={layoutHelpers.gridUnits(2)}
        alignItems="center"
        className={cx(poweredByLogos___isMobile, poweredByLogos___isDesktop)}
        responsiveProperties={[
          {
            breakpoint: 'medium',
            flexDirection: 'row',
            columnGap: `${layoutHelpers.gridUnits(4)}`,
          },
        ]}
      >
        <img
          src="/images/text--powered-by.svg"
          className={poweredByLogo}
          alt="powered by"
        />
        <FlexLayout
          justifyContent="center"
          alignItems="center"
          testId="appFooter__logosArea"
        >
          <HyperLink
            target="_blank"
            href={EXTERNAL_LINKS.STARKWARE}
            className={cx(starkwareLogo)}
          >
            <img
              src="/images/logo--starkware-light.svg"
              alt="powered by starkware logo"
            />
          </HyperLink>
          <HyperLink href={EXTERNAL_LINKS.IMMUTABLE_HOMEPAGE} target="_blank">
            <ImmutableLogoFullWordmarkAndSymbolLight />
          </HyperLink>
        </FlexLayout>
      </FlexLayout>
      <FlexLayout
        className={cx(version)}
        justifyContent="center"
        alignItems="center"
        testId="appFooter__versionArea"
      >
        {publicMarketplaceVersion && (
          <SimpleText
            fontSize="tag"
            fontWeight="bold"
            fillColor={colors.dark[300]}
          >
            Version {publicMarketplaceVersion}
          </SimpleText>
        )}
      </FlexLayout>
    </footer>
  );
}
