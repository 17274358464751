// For events that need to be debounced.
export const EVENT_DISPATCH_DELAY = 200;

export enum UTMParam {
  campaign = 'utm_campaign',
  content = 'utm_content',
  source = 'utm_source',
  medium = 'utm_medium',
  term = 'utm_term',
  adId = 'ad_id',
}

export enum LSUserId {
  userId = 'user_id',
  user = 'user',
  uid = '_uid',
}

export enum SSUserId {
  sid = '_s',
}

export enum EventType {
  screen = 'screen', // Event triggered when a screen or modal is opened or closed.
  button = 'Btn', // Event triggered when a button or link is clicked by the user.
  page = 'page', // Event triggered when a page or modal is opened or closed.
  flow = 'flow', // Event triggered to specify the start or end of a flow.
  text = 'Txt', // Event triggered when user interacts with a text input.
}

export enum ButtonEventName {
  // Button events within marketplace home page.
  marketViewFeaturedCollectionPressed = 'marketView_featuredCollection_pressed',

  // Button events within marketplace collection page.
  marketViewAssetPressed = 'marketBrowse_AssetBtn_pressed',

  // Button events within the asset detail page.
  assetBackBtnPressed = 'marketAssetOverview_BackBtn_pressed',
  assetBuyNowPressed = 'marketAssetOverview_BuyNowBtn_pressed',
  assetConnectBtnPressed = 'marketAssetOverview_ConnectBtn_pressed',
  assetDepositEthPressed = 'marketAssetOverview_DepositEthBtn_pressed',
  assetListCancelPressed = 'marketAssetOverview_ListCancelBtn_pressed',
  assetListForSalePressed = 'marketAssetOverview_ListForSaleBtn_pressed',
  assetPriceHistoryListPressed = 'marketAssetOverview_PriceHistoryBtn_pressed',
  assetPropertiesListPressed = 'marketAssetOverview_PropertiesBtn_pressed',

  // Button events within the ETH deposit modal.
  ethDepositCancelPressed = 'marketEthOverviewDeposit_CancelBtn_pressed',
  ethDepositConfirmPressed = 'marketEthOverviewDeposit_ContinueBtn_pressed',

  // Button events within the ETH withdraw modal.
  ethWdrawCancelPressed = 'marketEthOverviewPrepareWithdrawal_CancelBtn_pressed',
  ethWdrawConfirmPressed = 'marketEthOverviewPrepareWithdrawal_ContinueBtn_pressed',

  // Button events within the user inventory page.
  inventoryTrnPendingPressed = 'marketInventory_PendingTransactionsBtn_pressed',
  inventoryWdrawReadyPressed = 'marketInventory_WithdrawalReadyBtn_pressed',
  inventoryDepositEthPressed = 'marketInventory_DepositETHBtn_pressed',
  inventoryBalanceEthPressed = 'marketInventory_BalanceBtn_pressed',
  inventoryViewAssetPressed = 'marketInventory_AssetBtn_pressed',
  inventoryCollectionFilterPressed = 'marketInventory_CollectionFilterBtn_pressed',

  // Button events within the header and footer nav.
  globalNavInventoryPressed = 'marketGlobalNav_InventoryBtn_pressed',
  globalNavGetSupportPressed = 'marketGlobalNav_SupportBtn_pressed',
  globalNavPrivacyLinkPressed = 'marketGlobalNav_PrivacyLnk_pressed',
  globalNavMarketplacePressed = 'marketGlobalNav_MarketplaceBtn_pressed',
  globalNavTransactionsPressed = 'marketGlobalNav_TransactionsBtn_pressed',
  globalNavConnectWalletPressed = 'marketGlobalNav_ConnectWalletBtn_pressed',
  globalNavConditionsLinkPressed = 'marketGlobalNav_TermsConditionsLnk_pressed',
  globalNavBalancesBtnPressed = 'marketGlobalNav_BalancesBtn_pressed',
  globalNavDepositBtnPressed = 'marketGlobalNav_DepositBtn_pressed',
  globalNavAddFundsBtnPressed = 'marketGlobalNav_AddFundsBtn_pressed',
  globalNavTradeAndEarnBtnPressed = 'marketGlobalNav_TradeAndEarnBtn_pressed',
  globalNavOffersBtnPressed = 'marketGlobalNav_OffersBtn_pressed',
  globalNavCollectionStatementLinkPressed = 'marketGlobalNav_CollectionStatementLnk_pressed',

  // Button events within withdraw overview modal.
  // @TODO: Some of the below events are not used!
  marketWdrawOverViewAssetPressed = 'marketWithdrawalOverview_AssetBtn_pressed',
  marketWdrawOverViewClosePressed = 'marketWithdrawalOverview_CloseBtn_pressed',

  // Button events within available error pages.
  marketErrorNotFoundBackToMPPressed = 'marketError404_ReturnToMarketBtn_pressed',
  marketErrorGenericBackToMPPressed = 'marketErrorGeneric_ReturnToMarketBtn_pressed',

  // Button events within the Link Iframe.
  marketLinkSidebarBackdropBtnPressed = 'marketLinkSidebar_BackdropBtn_pressed',

  // Button events within deposit flow.
  marketAssetOverviewDepositBtnPressed = 'marketAssetOverview_DepositBtn_pressed',
  marketAssetOverviewAddFundsBtnPressed = 'marketAssetOverview_AddFundsBtn_pressed',

  // Button events within marketplace sidebars
  marketSidebarDepositCtaBtnPressed = 'marketSidebar_DepositCtaBtn_pressed',
  marketSidebarCardCtaBtnPressed = 'marketSidebar_CardCtaBtn_pressed',

  // Button event for offers related functionality
  marketAssetOverviewMakeOfferBtnPressed = 'marketAssetOverview_MakeOfferBtn_pressed',
  marketAssetOverviewConfirmOfferBtnPressed = 'marketAssetOverview_ConfirmOfferBtn_pressed',
  marketAssetOverviewCancelOfferBtnPressed = 'marketAssetOverview_CancelOfferBtn_pressed',
  marketAssetOverviewAceeptOfferBtnPressed = 'marketAssetOverview_AcceptOfferBtn_pressed',

  inventoryOffersMadeTabCancelOfferBtnPressed = 'inventoryOffersMadeTab_CancelOfferBtn_pressed',
}

export enum ScreenEventName {
  // Screen events within the asset detail page.
  assetDetailOverviewOpened = 'marketAssetOverview_screen_opened',
  assetDetailOverviewPricingHistory = 'marketAssetPricingHistory_screen_opened',

  // Screen events within withdraw overview modal.
  marketWdrawOverViewOpened = 'marketWithdrawalOverview_screen_opened',
  marketWdrawOverViewClosed = 'marketWithdrawalOverview_screen_closed',

  // Screen events within the ETH deposit modal.
  ethOverviewDepositOpened = 'marketEthOverviewDeposit_screen_opened',
  ethOverviewDepositClosed = 'marketEthOverviewDeposit_screen_closed',

  // Screen events within the ETH withdraw modal.
  ethOverviewWdrawOpened = 'marketEthOverviewPrepareWithdrawal_screen_opened',
  ethOverviewWdrawClosed = 'marketEthOverviewPrepareWithdrawal_screen_closed',

  // Screen events within available error pages.
  marketErrorNotFoundOpened = 'marketError404_screen_opened',
  marketErrorGenericOpened = 'marketErrorGeneric_screen_opened',

  // Screen events within funds required sidebar.
  marketFundsRequiredSidebarOpened = 'marketFundsRequiredSidebar_screen_opened',

  // Screen events within Moonpay polling sidebar.
  marketMoonpayPollingSidebarOpened = 'marketMoonpayPollingSidebar_screen_opened',

  // Screen events within payment processing sidebar.
  marketPaymentProcessingSidebarOpened = 'marketPaymentProcessingSidebar_screen_opened',
}

export enum PageEventName {
  marketBrowsePageOpened = 'marketBrowse_page_opened',
  inventoryViewPageOpened = 'marketInventory_page_opened',
  marketCollectionsPageOpened = 'marketCollections_page_opened',
}

export enum FlowEventName {
  // Flows within the asset detail page.
  buyAssetNowStarted = 'buyNow_started',
  listForSaleStarted = 'listForSale_started',
  cancelListingStarted = 'listForSaleCancel_started',

  // Flows within marketplace home page.
  marketBrowseStarted = 'marketplaceBrowse_started',
  marketSearchStarted = 'marketplaceSearch_started',
  marketSearchSucceeded = 'marketplaceSearch_succeeded',

  // Flows within the user inventory page.
  inventoryBrowseStarted = 'inventoryBrowse_started',

  // Flows within the ETH deposit modal.
  ethMarketDepositFailed = 'depositEth_failed',
  ethMarketDepositStarted = 'depositEth_started',

  // Flows within the ETH withdraw modal.
  ethPrepareWdrawFailed = 'prepareWithdrawalEthtoEth_failed',
  ethPrepareWdrawStarted = 'prepareWithdrawalEthtoEth_started',

  // Flows within withdraw overview modal.
  // @TODO: Some of the below are not used!
  marketWdrawToEthFailed = 'completeWithdrawalToEth_failed',
  marketWdrawToEthStarted = 'completeWithdrawalToEth_started',

  // Flows within deposit
  // @TODO: maybe we should rename this flow to be something
  // that includes fiatToCrypto flows too?
  depositStarted = 'deposit_started',
  depositProcessingDelayed = 'deposit_processing_delayed',
  depositCancelled = 'deposit_cancelled',

  // Flows within Connect Wallet
  connectWalletStarted = 'connectWallet_started',
  connectWalletSucceeded = 'connectWallet_succeeded',

  // FLow events within revamped PrepareWithdraw
  prepareWithdrawErc20Started = 'prepareWithdrawErc20_started',
  prepareWithdrawErc20Cancelled = 'prepareWithdrawErc20_cancelled',
  prepareWithdrawErc20ProgressedToLink = 'prepareWithdrawErc20_progressedToLink',
  prepareWithdrawErc20Succeeded = 'prepareWithdrawErc20_succeeded',
  prepareWithdrawErc20Failed = 'prepareWithdrawErc20_failed',
}

export enum TextEventName {
  marketSearchTextInputted = 'marketBrowse_SearchTxt_inputted',
  assetPriceSetTextInputted = 'marketAssetOverview_PriceSetTxt_inputted',
  ethDepositAmtTextInputted = 'marketEthOverviewDeposit_DepositAmountTxt_inputted',
  ethPrepWdrawAmtTextInputted = 'marketEthOverviewPrepareWithdrawal_WithdrawAmountTxt_inputted',
}
