import { BigNumber } from '@ethersproject/bignumber';
import { ImmutableMethodResults } from '@imtbl/imx-sdk';

export const parseOrderRes = (
  orders: ImmutableMethodResults.ImmutableGetOrderResult[],
) =>
  orders.map(o => ({
    ...o,
    expiration_timestamp: o.expiration_timestamp?.toString(),
    timestamp: o.timestamp?.toString(),
    amount_sold: o && o.amount_sold ? BigNumber.from(o.amount_sold) : null,
  }));

export const convertCommaListToStringArray = (commaList?: string): string[] =>
  typeof commaList === 'string' && /,/.test(commaList)
    ? commaList.split(',')?.map(address => address.trim())
    : typeof commaList === 'string'
    ? [commaList]
    : [];
