import { CurrencyBalance } from 'components/BalanceControl/BalanceControlCurrencyList.component';

export function orderCurrencyBalanceBySymbolAlphbetically(
  a: CurrencyBalance,
  b: CurrencyBalance,
) {
  if (a.symbol < b.symbol) return -1;
  if (a.symbol > b.symbol) return 1;
  return 0;
}
