import { LSUserId, SSUserId } from './';
import { getIdFromLocalStorage, getSessionId } from './utils';

export const AKUMA_URL = process.env.NEXT_PUBLIC_AKUMA_URL;
export const AKUMA_URL_JS_PATH = `${AKUMA_URL}/j`;

export function getTrackingPixel() {
  const trackingPixel = new Image(0, 0);
  const uid = getIdFromLocalStorage(LSUserId.uid) ?? '';
  const sid = getSessionId(SSUserId.sid) ?? '';
  const user =
    getIdFromLocalStorage(LSUserId.userId) ??
    getIdFromLocalStorage(LSUserId.user) ??
    '';

  trackingPixel.src =
    `${AKUMA_URL}/?noscript=0&user=` + user + '&_s=' + sid + '&_uid=' + uid;
  return trackingPixel;
}

export function injectTrackingPixel() {
  if (!document || !document.head) return;
  document.head.appendChild(getTrackingPixel());
}
