import { useEffect, useState } from 'react';

import { isMobile } from './rewirable-imports';

export const useShouldRenderIframe = () => {
  const [shouldRenderIframe, setShouldRenderIframe] = useState<
    boolean | undefined
  >(undefined);

  useEffect(() => {
    setShouldRenderIframe(isMobile() === false);
  }, [setShouldRenderIframe]);

  return { shouldRenderIframe };
};
