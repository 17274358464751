import { openSidebarPanel } from '@imtbl/design-system';

import { isTestBuildEnvironment } from '../../../../../environment';
import {
  createFlowEvent,
  FlowEventName,
  FlowEventProps,
  sendAnalytics,
} from '../../../../libs/analytics';
import { PrepareWithdrawSidebarPanel } from './PrepareWithdrawSidebarPanel.component';

export let testHandlePrepareWithdrawFormDataChange: (
  formData: FlowEventProps,
) => void;

export const openPrepareWithdrawSidebarPanel = (userWalletAddress?: string) => {
  sendAnalytics(
    createFlowEvent(FlowEventName.prepareWithdrawErc20Started, {
      walletAddress: userWalletAddress,
    }),
  );
  let prepareWithdrawFormData: FlowEventProps = {};
  const handleFormDataChange = (formData: FlowEventProps) => {
    prepareWithdrawFormData = {
      ...prepareWithdrawFormData,
      ...formData,
    };
  };

  // @NOTE: surfacing the form data handler to tests,
  // so that we can then assert on correct analytics calls
  if (isTestBuildEnvironment) {
    testHandlePrepareWithdrawFormDataChange = handleFormDataChange;
  }

  openSidebarPanel({
    sidebarPanelContent: (
      <PrepareWithdrawSidebarPanel handleFormUpdate={handleFormDataChange} />
    ),
    onUserCancelSidebarPanel: () => {
      sendAnalytics(
        createFlowEvent(FlowEventName.prepareWithdrawErc20Cancelled, {
          ...prepareWithdrawFormData,
          walletAddress: userWalletAddress,
        }),
      );
    },
  });
};
