import { SidebarPanelChildContentPropTypes } from '@imtbl/design-system';
import { createFlowEvent, FlowEventName, sendAnalytics } from 'libs/analytics';
import { useEffect } from 'react';

import { SidebarDescription } from '../shared/SidebarDescription.component';
import { SidebarLayout } from '../shared/SidebarLayout.component';
import { SidebarTitle } from '../shared/SidebarTitle.component';

export type FundsProcessingDelaySidebarPanelProps =
  SidebarPanelChildContentPropTypes;

export const FundsProcessingDelaySidebarPanel = ({
  testId = 'FundsProcessingDelaySidebarPanel',
}: FundsProcessingDelaySidebarPanelProps) => {
  useEffect(() => {
    sendAnalytics(
      createFlowEvent(FlowEventName.depositProcessingDelayed, {
        action: 'fundsProcessingDelaySidebarOpened',
      }),
    );
  }, []);
  return (
    <SidebarLayout testId={testId}>
      <SidebarTitle testId={testId}>Processing your deposit</SidebarTitle>
      <SidebarDescription>
        <strong>Moonpay is currently processing your deposit, </strong>
        transactions normally take between a few minutes and half an hour to
        complete, but may take longer if they need to run additional checks.
        <br />
        <br />
        <strong>
          You will recieve a confirmation email from Moonpay as soon as
          it&apos;s processed.&nbsp;
        </strong>
        In the meantime, please continue to browse the Immutable Marketplace.
      </SidebarDescription>
    </SidebarLayout>
  );
};
