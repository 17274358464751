import { css } from '@emotion/css';
import { colors, layoutHelpers } from '@imtbl/design-system';

export const WalletDropdownContainer = css`
  width: 100%;
  cursor: pointer;
  background-color: ${colors.dark[500]};
`;

export const MenuItem = css`
  padding: ${layoutHelpers.gridUnits(3)};
  margin-bottom: ${layoutHelpers.gridUnits(1)};
  border-radius: ${layoutHelpers.gridUnits(1.8)};

  &:last-child {
    margin: 0;
  }

  &:hover {
    color: ${colors.light[100]};
    background-color: ${colors.dark[700]};
  }
`;

export const DisconnectWalletButton = css`
  padding: ${layoutHelpers.gridUnits(3)};
`;
