import { EthAddress } from '@imtbl/imx-sdk';
import { AuxiliaryFee } from 'types/Imx/Fees';

export const makerFeeRecipient =
  process.env.NEXT_PUBLIC_MAKER_FEE_RECIPIENT || '';
export const makerFeePercentage = parseFloat(
  // @NOTE: this is not ideal, but the API's fail for us, when they
  // recieve a fee amount of "0". :(
  process.env.NEXT_PUBLIC_MAKER_FEE_PERCENTAGE || '0',
);

export const takerFeeRecipient =
  process.env.NEXT_PUBLIC_TAKER_FEE_RECIPIENT || '';
export const takerFeePercentage = parseFloat(
  // @NOTE: this is not ideal, but the API's fail for us, when they
  // recieve a fee amount of "0". :(
  process.env.NEXT_PUBLIC_TAKER_FEE_PERCENTAGE || '0',
);

export const takerFees: AuxiliaryFee[] = [
  {
    recipient: takerFeeRecipient as EthAddress,
    percentage: takerFeePercentage,
  },
];

export const makerFees: AuxiliaryFee[] = [
  {
    recipient: makerFeeRecipient as EthAddress,
    percentage: makerFeePercentage,
  },
];

export const checkFeeIsGreaterThanZero = (fee: AuxiliaryFee[]) =>
  fee[0].percentage > 0;

export const MARKETPLACE_SHARED_WALLET =
  '0x9c782E4f67064D5cb2BD3080D043De1C5081aad3';
