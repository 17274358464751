import { ProviderPreference } from '@imtbl/imx-sdk';
import { isStorageValid } from 'libs/analytics';
import { LocalStorageKey } from 'types';

export function getProviderPreferenceFromLocalStorage(): ProviderPreference | null {
  if (!isStorageValid(localStorage)) {
    return null;
  }

  return localStorage.getItem(
    LocalStorageKey.PROVIDER_PREFERENCE,
  ) as ProviderPreference | null;
}
