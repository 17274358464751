import { colors, ErrorBoundary } from '@imtbl/design-system';
import { ERROR_PAGE } from 'config/pages';
import { ScreenEventName } from 'libs/analytics';
import { sendAnalytics } from 'libs/analytics/akuma-send';
import { createScreenEvent } from 'libs/analytics/utils';
import ErrorPage from 'pages/404';
import React, { useCallback } from 'react';

import { isProdBuildEnvironment } from '../../../environment';

export const ERROR_PAGE_MESSAGE =
  "Well this is a little awkward... looks like you were either given a bad URL or the page isn't available.";
export const TEST_ID_DEFAULT = 'error-boundary';

/**
 * If the feature flag imx1804_useErrorBoundaries
 * https://app.launchdarkly.com/immutable-x/dev/features/imx1804_useAppWideErrorBoundary/targeting
 * is enabled, it will wrap the children in an error boundary component
 * to prevent the child code from crashing the React app. Else,it will render
 * the children without the wrapper.
 *
 * @param {JSX.Element} children - components to be rendered
 * inside {@link ErrorBoundary} if present.
 * @returns {JSX.Element} - wrapped or unwrapped children.
 * @constructor
 */
export const GlobalErrorBoundary = ({ children }) => {
  const onErrorCallback = useCallback((errorMessage: string) => {
    sendAnalytics(
      createScreenEvent(ScreenEventName.marketErrorGenericOpened, {
        errorMessage,
      }),
    );
  }, []);

  const CustomErrorPage = () => (
    <ErrorPage
      pageTitle={ERROR_PAGE.title}
      pageTitleColor={colors.red[300]}
      errorMessage={ERROR_PAGE_MESSAGE}
    />
  );

  return (
    <ErrorBoundary
      onError={onErrorCallback}
      renderOnError={<CustomErrorPage />}
    >
      {children}
      {/* Disgusting hack below to test mock feature flag! */}
      {!isProdBuildEnvironment && (
        <input type="hidden" data-testid={TEST_ID_DEFAULT} />
      )}
    </ErrorBoundary>
  );
};

export default GlobalErrorBoundary;
