import { createContext, useContext } from 'react';
import { FeatureFlags } from 'types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LaunchDarklyContext = createContext<Record<string, any>>({});
LaunchDarklyContext.displayName = 'LaunchDarklyContext';

interface LaunchDarklyProviderPropTypes {
  children: JSX.Element | JSX.Element[];
  flags: FeatureFlags;
}

export function LaunchDarklyProvider({
  children,
  flags,
}: LaunchDarklyProviderPropTypes) {
  return (
    <LaunchDarklyContext.Provider value={flags}>
      {children}
    </LaunchDarklyContext.Provider>
  );
}

export function useLaunchDarklyFlags() {
  const context = useContext(LaunchDarklyContext);

  if (!Object.keys(context).length) {
    console.error(
      'useLaunchDarklyFlags must be used within a LaunchDarklyProvider',
    );
  }

  return context;
}
