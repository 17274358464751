import { version as dsVersion, versionHelpers } from '@imtbl/design-system';
import { version as sdkVersion } from '@imtbl/imx-sdk';

import {
  isDevApiEnvironment,
  isProdApiEnvironment,
  isSandboxApiEnvironment,
  nextPublicCommitId,
  nextPublicReleaseVersion,
} from '../../environment';

export const marketplaceVersion =
  isSandboxApiEnvironment || isDevApiEnvironment
    ? nextPublicCommitId || 'unknown'
    : isProdApiEnvironment
    ? nextPublicReleaseVersion || 'unknown'
    : 'unknown';

export const versions = {
  marketplaceVersion,
  sdkVersion,
  dsVersion,
};

export const prettyPrintVersions = () => {
  versionHelpers.prettyLogImtblPackageAndVersion(
    'design-system',
    versions.dsVersion,
  );
  versionHelpers.prettyLogImtblPackageAndVersion(
    'imx-sdk',
    versions.sdkVersion,
  );
  versionHelpers.prettyLogImtblPackageAndVersion(
    'imx-marketplace',
    versions.marketplaceVersion,
  );
};
