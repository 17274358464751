import { AkumaAnalytics } from './akuma-analytics';
import { triggerEvent } from './gtag';
import { AnalyticsEvent, GTagEvent } from './types';

// NOTE: 'appEnvironment' variables are
// set by tools running the 'e2e' tests.
type E2ETestEnv = { NODE_ENV?: string };

type CustomWindow = Window & {
  appEnvironment?: E2ETestEnv;
};

export class AnalyticsClient {
  private akumaStream: AkumaAnalytics;

  private canPublishEvents?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private googleAnalyticsStream: any;

  constructor(config?: { version: string }) {
    this.akumaStream = new AkumaAnalytics(config?.version);
  }

  private isTestEnv(e2eTestEnv?: E2ETestEnv) {
    const isAppTest = process.env.NODE_ENV === 'test';
    const isE2ETest = e2eTestEnv?.NODE_ENV === 'test';
    return isE2ETest || isAppTest;
  }

  private runEnvChecks() {
    if (this.canPublishEvents === undefined) {
      this.canPublishEvents = !this.isTestEnv(
        (window as CustomWindow).appEnvironment,
      );
    }
  }

  public publishEvent(analyticsEvent: AnalyticsEvent) {
    this.runEnvChecks();

    if (this.canPublishEvents) {
      this.akumaStream.publish(analyticsEvent);

      triggerEvent(
        analyticsEvent.eventName,
        analyticsEvent.eventProperties as GTagEvent,
      );
    }

    return this;
  }
}
