import { FiatEquvalentPrice } from '@imtbl/design-system';
import BigNumber from 'bignumber.js';
import {
  CryptoFiatPrices,
  TokenSymbols,
} from 'context/FiatPricing/FiatPricing';

export const buildFiatEquivalentPrice = (
  tokenPrice: string,
  tokenSymbol: string,
  fiatPrices: CryptoFiatPrices | undefined,
  fiatCurrency = 'usd',
): FiatEquvalentPrice | undefined => {
  if (!fiatPrices) return;
  if (!Object.keys(TokenSymbols).includes(tokenSymbol.toLowerCase())) return;

  const fiatEquivalentFormattedPrice = new BigNumber(tokenPrice)
    .multipliedBy(fiatPrices[tokenSymbol.toLowerCase()].usd)
    .toFormat(2);

  return {
    amount: fiatEquivalentFormattedPrice,
    currency: fiatCurrency,
    prefixSymbol: fiatCurrency === 'usd' ? '$' : '',
  } as FiatEquvalentPrice;
};
