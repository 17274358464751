import { cx } from '@emotion/css';
import { BaseComponentPropTypes, FlexLayout } from '@imtbl/design-system';
import { formatTokenBalance, useL2ListBalances } from 'api/balances';
import { useTokens } from 'api/tokens';
import { TokensResult } from 'components/CompleteWithdrawalsModal/types';
import React, { useMemo } from 'react';

import { orderCurrencyBalanceBySymbolAlphbetically } from '../../utils/sorting';
import * as styles from './BalanceControl.styles';
import {
  BalanceControlCurrencyList,
  CurrencyBalance,
} from './BalanceControlCurrencyList.component';

export function BalanceControl({ testId }: BaseComponentPropTypes) {
  const { tokens } = useTokens();
  const tokensResult: TokensResult = useMemo(
    () => tokens?.result ?? [],
    [tokens],
  );

  // Get L2 ETH and ERC20 Balances
  const { allBalancesL2, allBalancesL2Loading } = useL2ListBalances({
    refreshInterval: 20000,
  });

  const hasBalance = (currencyBalance: CurrencyBalance) =>
    parseFloat(currencyBalance.balance) > 0;

  const l2Erc20CurrencyBalances = useMemo(() => {
    if (allBalancesL2Loading) {
      return [];
    }
    return (
      allBalancesL2?.result
        .map(tokenBalance => {
          const tokenResultData = tokensResult.find(
            token => token.symbol === tokenBalance.symbol,
          );
          return {
            symbol: tokenBalance.symbol,
            balance: formatTokenBalance(
              tokenBalance?.balance.toBigInt().toString(),
              tokenResultData?.decimals.toString(),
            ),
            image_url: tokenResultData?.image_url ?? '',
            token_address: tokenResultData?.token_address,
          } as CurrencyBalance;
        })
        .filter(l2Erc20CurrencyBalance => hasBalance(l2Erc20CurrencyBalance))
        .sort(orderCurrencyBalanceBySymbolAlphbetically) ?? []
    );
  }, [allBalancesL2, tokensResult, allBalancesL2Loading]);

  return (
    <FlexLayout
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      className={cx(
        styles.BalanceControl___isMobile,
        styles.BalanceControl___isDesktop,
      )}
      testId={`${testId}`}
    >
      <BalanceControlCurrencyList
        currencyBalances={l2Erc20CurrencyBalances}
        testId={`${testId}__currencyList--imx`}
      />
    </FlexLayout>
  );
}
