// This file needs to be consumed by our Next config (`next.config.js`). Our current version of Next does not support
// ES6 modules, which means that this needs to be a CommonJS module.
// TODO: Convert this to an ES6 module once we've upgraded to version 12 of Next & converted the config to `.mjs`

module.exports = {
  // Development & build environments
  isDevBuildEnvironment: process.env.NODE_ENV === 'development',
  isProdBuildEnvironment: process.env.NODE_ENV === 'production',
  isTestBuildEnvironment: process.env.NEXT_PUBLIC_ENV === 'test',
  isDevApiEnvironment: process.env.NEXT_PUBLIC_ENV === 'development',
  isSandboxApiEnvironment: process.env.NEXT_PUBLIC_ENV === 'sandbox',
  isProdApiEnvironment: process.env.NEXT_PUBLIC_ENV === 'production',

  // Commit & release versions
  nextPublicCommitId: process.env.NEXT_PUBLIC_COMMIT_ID,
  nextPublicReleaseVersion: process.env.NEXT_PUBLIC_RELEASE_VERSION,

  // LogRocket
  logRocketProject: process.env.NEXT_PUBLIC_LOGROCKET_PROJECT,
  logRocketReleaseName: process.env.LOGROCKET_RELEASE_NAME,
};
