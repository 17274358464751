import { css, cx } from '@emotion/css';
import {
  BaseComponentPropTypes,
  colors,
  FlexLayout,
  Icon,
  layoutHelpers,
  measurements,
  OuterSpace,
  ParagraphText,
  SearchBox,
  SimpleText,
  StandardButton,
  StyledLink,
} from '@imtbl/design-system';
import { FeatureFlag } from 'components/FeatureFlag';
import { AddFundsButton } from 'components/shared/AddFundsAction/AddFundsButton.component';
import { AddFundsStyledLink } from 'components/shared/AddFundsAction/AddFundsStyledLink.component';
import { FEATURE_FLAG } from 'config/feature-flags';
import { useFiatPricing } from 'context/FiatPricing/FiatPricing';
import { useImxLink } from 'context/ImxLink';
import {
  ButtonEventName,
  createButtonEvent,
  createFlowEvent,
  FlowEventName,
  sendAnalytics,
} from 'libs/analytics';
import React, { useCallback, useMemo, useState } from 'react';
import { buildFiatEquivalentPrice } from 'utils/fiatPricing';

import { openPrepareWithdrawSidebarPanel } from '../shared/SidebarPanelScreens/PrepareWithdraw/utils';
import * as styles from './BalanceControl.styles';
import { BalanceControlCurrencyListItem } from './BalanceControlCurrencyListItem.component';

export interface CurrencyBalance {
  balance: string;
  image_url: string;
  symbol: string;
  token_address?: string;
}
export interface BalanceControlCurrencyListProps {
  currencyBalances: CurrencyBalance[];
}

export function BalanceControlCurrencyList(
  props: BalanceControlCurrencyListProps & BaseComponentPropTypes,
) {
  const { currencyBalances, testId } = props;
  const {
    linkState: { walletAddress },
  } = useImxLink();
  const { fiatPrices } = useFiatPricing();
  const { link } = useImxLink();
  const [searchTerm, setSearchTerm] = useState('');
  const filteredCurrencyBalances = useMemo(() => {
    return currencyBalances.filter(currencyBalance =>
      currencyBalance.symbol.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [currencyBalances, searchTerm]);

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const depositCtaOnClick = () => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.globalNavDepositBtnPressed),
      createFlowEvent(FlowEventName.depositStarted),
    );
    link.deposit({} as any);
  };

  const onWithdrawCtaButtonClick = useCallback(
    () => openPrepareWithdrawSidebarPanel(walletAddress),
    [walletAddress],
  );

  return (
    <FlexLayout
      width="100%"
      flexDirection="column"
      justifyContent="flex-start"
      className={cx(
        styles.BalanceControlCurrencyList___isMobile,
        styles.BalanceControlCurrencyList___isDesktop,
      )}
      testId={testId}
    >
      {currencyBalances.length === 0 ? (
        <FlexLayout
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={css`
            flex-basis: 100%;
          `}
        >
          <FlexLayout
            className={css`
              position: relative;
            `}
          >
            <Icon
              ligature="currency_dollar__circle"
              fillColor={colors.dark[300]}
              iconSize="33.33px"
              className={css`
                position: absolute;
                right: 0;
                transform: translateX(50%);
              `}
            />
            <Icon
              ligature="currency_dollar__circle"
              fillColor={colors.dark[100]}
              iconSize="78.33px"
              className={css`
                position: relative;
              `}
            />
          </FlexLayout>
          <OuterSpace
            top={measurements.SpacingTeeShirtAmounts.small}
            bottom={measurements.SpacingTeeShirtAmounts.medium}
          >
            <SimpleText
              fontSize="small"
              fontWeight="bold"
              fillColor={colors.light[100]}
              textAlign="center"
            >
              No funds available
            </SimpleText>
          </OuterSpace>
          <ParagraphText
            fontSize="tag"
            fillColor={colors.light[300]}
            textAlign="center"
          >
            To make purchases,{' '}
            <AddFundsStyledLink
              testId="balanceControlCurrencyList"
              fontSize="tag"
              buttonEventName={ButtonEventName.globalNavAddFundsBtnPressed}
            />{' '}
            by depositing from your connected Wallet or with a Credit Card
          </ParagraphText>
        </FlexLayout>
      ) : (
        <>
          <SearchBox
            labelText="Search Balances"
            onChange={handleSearch}
            testId={`${testId}__searchBox`}
          />
          <FlexLayout
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
            rowGap={layoutHelpers.gridUnits(4)}
            className={cx(styles.BalanceControlCurrencyList_list)}
            testId={`${testId}__list`}
          >
            {filteredCurrencyBalances.map(currencyBalance => {
              const fiatEquivalentPrice = buildFiatEquivalentPrice(
                currencyBalance.balance,
                currencyBalance.symbol,
                fiatPrices,
              );
              return (
                <BalanceControlCurrencyListItem
                  key={`${currencyBalance.symbol}-${currencyBalance.token_address}`}
                  balance={currencyBalance.balance}
                  image_url={currencyBalance.image_url}
                  symbol={currencyBalance.symbol}
                  fiatEquivalentPrice={fiatEquivalentPrice}
                  testId={`${testId}__item--${currencyBalance.symbol.toLowerCase()}`}
                />
              );
            })}
          </FlexLayout>
        </>
      )}

      <FeatureFlag
        name={FEATURE_FLAG.ENABLE_REVAMPED_DEPOSIT}
        fallback={
          <StandardButton
            icon="action_deposit"
            buttonKind="ultimate-cta"
            onClick={depositCtaOnClick}
            testId={`${testId}__actionButton--deposit`}
          >
            Deposit
          </StandardButton>
        }
      >
        <AddFundsButton
          testId="balanceControlCurrencyList"
          buttonEventName={ButtonEventName.globalNavAddFundsBtnPressed}
        />
      </FeatureFlag>

      {currencyBalances?.length > 0 && (
        <FeatureFlag name={FEATURE_FLAG.REVAMPED_PREPARE_WITHDRAW_FLOW}>
          <OuterSpace
            top={measurements.SpacingTeeShirtAmounts.large}
            bottom={measurements.SpacingTeeShirtAmounts['2x-small']}
          >
            <StyledLink
              testId={`${testId}__actionButton--withdraw`}
              onClick={onWithdrawCtaButtonClick}
              className={css`
                align-self: center;
              `}
              fontWeight="bold"
            >
              Withdraw
            </StyledLink>
          </OuterSpace>
        </FeatureFlag>
      )}
    </FlexLayout>
  );
}
