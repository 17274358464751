import { BaseComponentPropTypes, StandardButton } from '@imtbl/design-system';
import { useImxLink } from 'context/ImxLink';

import { TEST_ID } from './consts';
import { onAddFundsButtonClick } from './utils';

export function AddFundsButton({
  testId,
  buttonEventName,
}: BaseComponentPropTypes & { buttonEventName: string }) {
  const { fiatToCrypto } = useImxLink().link;

  return (
    <StandardButton
      buttonKind="ultimate-cta"
      onClick={() => onAddFundsButtonClick({ fiatToCrypto, buttonEventName })}
      testId={`${testId}${TEST_ID}`}
    >
      Add funds
    </StandardButton>
  );
}
