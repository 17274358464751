import { css } from '@emotion/css';
import { FlexLayout, layoutHelpers, measurements } from '@imtbl/design-system';

import { SidebarPanelComponentProps } from './types';
export const SidebarLayout = ({
  children,
  testId = 'sidebarLayout',
}: SidebarPanelComponentProps) => (
  <FlexLayout
    testId={testId}
    flexDirection="column"
    padding={layoutHelpers.gridUnits(6)}
    paddingTop={layoutHelpers.gridUnits(8)}
    maxWidth={measurements.sidebarPanelDesktopWidth}
    minWidth={layoutHelpers.gridUnits(85)}
    flexGrow={1}
    alignSelf="center"
    className={css`
      overflow-y: auto;
    `}
    responsiveProperties={[
      { breakpoint: 'medium', alignSelf: 'stretch', minWidth: 'auto' },
    ]}
  >
    {children}
  </FlexLayout>
);
