import { ProviderPreference } from '@imtbl/imx-sdk';
import { openAddFundsSidebarPanel } from 'components/shared/SidebarPanelScreens/AddFunds/utils';
import { ButtonEventName, FlowEventName } from 'libs/analytics';
import { sendAnalytics } from 'libs/analytics/akuma-send';
import { createButtonEvent, createFlowEvent } from 'libs/analytics/utils';

import { getProviderPreferenceFromLocalStorage } from '../utils';

export const onAddFundsButtonClick = ({
  fiatToCrypto,
  buttonEventName,
}: {
  fiatToCrypto: () => Promise<void>;
  buttonEventName: string;
}) => {
  const sendAddFundsAnalytics = ({
    walletType,
  }: {
    walletType: ProviderPreference | null;
  }) => {
    sendAnalytics(
      createButtonEvent(buttonEventName as ButtonEventName),
      createFlowEvent(FlowEventName.depositStarted, {
        walletType: walletType || undefined,
      }),
    );
  };

  const providerPreference = getProviderPreferenceFromLocalStorage();
  switch (providerPreference) {
    case ProviderPreference.MAGIC_LINK:
      sendAddFundsAnalytics({ walletType: ProviderPreference.MAGIC_LINK });
      fiatToCrypto()?.catch(console.error);
      break;
    default:
      sendAddFundsAnalytics({
        walletType: providerPreference,
      });
      openAddFundsSidebarPanel({ showWalletDepositCta: true });

      return;
  }
};
