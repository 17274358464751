import { css } from '@emotion/css';
import { layoutHelpers, styleHelpers } from '@imtbl/design-system';

export const footer___isMobile = css`
  border-top: 1px solid var(--colors_dark_300);
  padding-top: calc(var(--measurements_gridUnit) * 6);
  padding-bottom: calc(var(--measurements_gridUnit) * 21);
  display: grid;
  justify-content: center;
  row-gap: ${layoutHelpers.gridUnits(4)};
  position: relative;
  grid-template-areas:
    'termsPrivacyCopyright'
    'poweredByLogos'
    'version';
`;
export const footer___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
    padding: calc(var(--measurements_gridUnit) * 3) calc(var(--measurements_gridUnit) * 6);
    justify-content: space-between;
    align-items: center;
    grid-template-areas: 'termsPrivacyCopyright version poweredByLogos';`,
  );

export const termsPrivacyCopyright = css`
  grid-area: termsPrivacyCopyright;
`;

export const poweredByLogos___isMobile = css`
  grid-area: poweredByLogos;
`;

export const poweredByLogos___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
  justify-content: flex-end;
`,
  );

export const version = css`
  grid-area: version;
`;

export const footerLink = css`
  color: var(--colors_light_100);
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-right: calc(var(--measurements_gridUnit) * 3);

  &:nth-last-child(1) {
    margin-right: 0;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const copyrightLink = css`
  display: flex;

  &:hover {
    text-decoration: none;
  }
`;

export const poweredByLogo = css`
  height: 14px;
`;

export const starkwareLogo = css`
  margin-right: ${layoutHelpers.gridUnits(4)};
`;
