import { BigNumber } from '@ethersproject/bignumber';
import { formatEther, formatUnits } from '@ethersproject/units';
import { numberHelpers } from '@imtbl/design-system';
import {
  BalanceInfo,
  ethBalanceV2,
  ImmutableMethodResults,
} from '@imtbl/imx-sdk';
import { API_URL } from 'api/constants';
import { useImxLink } from 'context/ImxLink';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Observable, Subscription } from 'rxjs';
import useSWR, { SWRConfiguration } from 'swr';
import { SupportedTokens } from 'types';
import { NUMBER_OF_SIGNIFICANT_DIGITS } from 'utils/constants';
import { errorLogger } from 'utils/error-logger';

import { erc20BalanceV2 } from '../context/ImxLink/rewirable-imports';
import { useTokens } from './tokens';

export interface GetBalanceParams {
  user: string;
  tokenAddress: string;
}

export interface ListBalancesParams {
  user: string;
  symbols?: string[];
}

export type GetBalanceResponse =
  | ImmutableMethodResults.ImmutableGetBalanceResult
  | undefined;

export type ListBalancesResponse =
  | ImmutableMethodResults.ImmutableListBalancesResult
  | undefined;

export interface UseL2BalanceHookProps {
  refreshInterval?: number;
  tokenAddress?: string;
}

export interface UseL2ListBalancesHookProps {
  symbols?: string[];
  refreshInterval?: number;
}

export interface UseL1ERC20BalanceHookProps {
  observable?: Observable<BalanceInfo>;
  tokenAddress: string;
}

export interface UseL1EthBalanceHookProps {
  observable?: Observable<BalanceInfo>;
}

export function getBalanceHexStr(balance?: Record<string, string | BigNumber>) {
  if (!balance) {
    return '';
  }

  return Object.values(balance).reduce(
    (hexString, bigNumber, i) =>
      hexString +
      (i > 0 ? '_' : '') +
      (typeof bigNumber === 'string' ? bigNumber : bigNumber.toHexString()),
    '',
  );
}

// @NOTE: This is a placeholder while we still hardcode max decimal places: https://immutable.atlassian.net/browse/MP-993
export function formatTokenBalanceWithoutTruncation(
  value?: BigNumber | string,
  decimals?: string,
) {
  if (!value) {
    return '0';
  }

  return decimals ? formatUnits(value, decimals) : formatUnits(value);
}

export function formatTokenBalance(
  value?: BigNumber | string,
  decimals?: string,
): string {
  if (!value) {
    return '0';
  }

  return numberHelpers.truncateNumberStringToSignificantDigits(
    decimals ? formatUnits(value, decimals) : formatEther(value),
    NUMBER_OF_SIGNIFICANT_DIGITS,
  );
}

export function useL2Balance({
  refreshInterval = 0,
  tokenAddress,
}: UseL2BalanceHookProps) {
  const { linkState, imxClient } = useImxLink();
  const user = linkState.walletAddress;
  const params: GetBalanceParams = {
    user,
    tokenAddress: tokenAddress ? tokenAddress : 'eth',
  };

  const swrConfig = {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  } as SWRConfiguration;

  if (refreshInterval > 0) {
    swrConfig.refreshInterval = refreshInterval;
    swrConfig.dedupingInterval = refreshInterval;
  }

  const { data, mutate, error } = useSWR<GetBalanceResponse>(
    // NOTE: Check if the user's 'walletAddress' can be empty!
    params.user
      ? `${API_URL}/balances/${params.user}/${params.tokenAddress}`
      : null,
    () => imxClient.getBalance(params),
    swrConfig,
  );

  return {
    balanceL2: data,
    balanceL2Error: error,
    balanceL2Loading: !data && !error,
    mutate,
  };
}

export function useL2ListBalances({
  symbols,
  refreshInterval = 0,
}: UseL2ListBalancesHookProps = {}) {
  const { linkState, imxClient } = useImxLink();
  const user = linkState.walletAddress;
  const params: ListBalancesParams = {
    user,
    symbols,
  };

  const swrConfig = {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  } as SWRConfiguration;

  if (refreshInterval > 0) {
    swrConfig.refreshInterval = refreshInterval;
    swrConfig.dedupingInterval = refreshInterval;
  }

  const { data, error } = useSWR<ListBalancesResponse>(
    user
      ? `${API_URL}/balances/${user}?symbols=${
          symbols ? symbols.join(',') : ''
        }`
      : null,
    () => imxClient.listBalances(params),
    swrConfig,
  );

  if (error) {
    errorLogger(`Failed to fetch balance for user: ${user}`, error);
  }

  return {
    allBalancesL2: data,
    allBalancesL2Error: error,
    allBalancesL2Loading: !data && !error,
  };
}

export function useImxTokenInfo() {
  const { result = [] } = useTokens().tokens ?? {};
  const imxToken = result.find(t => t.symbol === SupportedTokens.imxd);
  const { token_address: tokenAddress, decimals } = imxToken ?? {};

  const imxTokenInfoFactory = () => ({ tokenAddress, decimals });
  const memoizedImxTokenInfo = useMemo(imxTokenInfoFactory, [
    tokenAddress,
    decimals,
  ]);

  return memoizedImxTokenInfo;
}

export function useImxTokenBalances() {
  const { tokenAddress, decimals } = useImxTokenInfo();
  /* Not as important, so increasing refresh interval. */
  const { balanceL2 } = useL2Balance({
    refreshInterval: 10000,
    tokenAddress,
  });

  const formattedBalAmount = useMemo(
    () => formatTokenBalance(balanceL2?.balance, decimals),
    /* NOTE: If the balance changes, 'hex' will change too! */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [balanceL2?.balance?._hex, decimals],
  );

  /**
   * @TODO: This is just a placeholder, need to get locked
   * balance and token amounts from the API (in discovery)!
   */
  const dailyTokenAmount = '0';
  const imxPayoutAmount = '0';
  const lockedBalAmount = '0';
  const hasSeen = false;

  const tokenBalanceFactory = () => ({
    balances: { amount: formattedBalAmount, locked: lockedBalAmount },
    dailyTokenAmount,
    imxPayoutAmount,
    tokenAddress,
    hasSeen,
  });

  const memoizedTokenBalance = useMemo(tokenBalanceFactory, [
    formattedBalAmount,
    dailyTokenAmount,
    imxPayoutAmount,
    lockedBalAmount,
    tokenAddress,
    hasSeen,
  ]);

  return memoizedTokenBalance;
}

export function useL1ERC20Balance({
  tokenAddress,
  observable,
}: UseL1ERC20BalanceHookProps) {
  const { linkState } = useImxLink();
  const [error, setError] = useState<Error>();
  const subscription = useRef<Subscription>();
  const balanceObservable = useRef<Observable<BalanceInfo>>();
  const [erc20L1Balance, setErc20L1Balance] = useState<BalanceInfo>();

  balanceObservable.current = !observable
    ? erc20BalanceV2({
        owner: linkState.walletAddress,
        tokenAddress,
        rpcUrl: process.env.NEXT_PUBLIC_JSON_RPC_URL || '',
      })
    : observable;

  useEffect(() => {
    if (error) {
      /* NOTE: Prevents infinite looping on error! */
      return () => subscription.current?.unsubscribe();
    }

    subscription.current = balanceObservable.current?.subscribe(
      nextBalance => {
        /**
         * NOTE: These checks are required to stop an infinite
         * state update loop because two objects are never equal.
         */

        /**
         * NOTE: Set balance if we don't have one, ie on first render (OR) If current
         * balance is a BigNumber and is not equal to next balance, then update state.
         */
        if (
          !erc20L1Balance ||
          (erc20L1Balance.balance?._isBigNumber &&
            !erc20L1Balance.balance.eq(nextBalance.balance))
        ) {
          setErc20L1Balance(nextBalance);
        }
      },
      error => setError(error),
    );

    return () => subscription.current?.unsubscribe();
  }, [erc20L1Balance, error]);

  return {
    erc20L1Balance,
    erc20L1BalanceError: error,
    erc20L1BalanceLoading: !erc20L1Balance && !error,
  };
}

export function useL1EthBalance({ observable }: UseL1EthBalanceHookProps) {
  const [ethL1Balance, setEthL1Balance] = useState<BalanceInfo>();
  const balanceObservable = useRef<Observable<BalanceInfo>>();
  const subscription = useRef<Subscription>();
  const [error, setError] = useState<Error>();
  const { linkState } = useImxLink();

  balanceObservable.current = !observable
    ? ethBalanceV2({
        address: linkState.walletAddress,
        rpcUrl: process.env.NEXT_PUBLIC_JSON_RPC_URL || '',
      })
    : observable;

  useEffect(() => {
    if (error) {
      /* NOTE: Prevents infinite looping on error! */
      return () => subscription.current?.unsubscribe();
    }

    subscription.current = balanceObservable.current?.subscribe(
      nextBalance => {
        /**
         * NOTE: These checks are required to stop an infinite
         * state update loop because two objects are never equal.
         */

        /**
         * NOTE: Set balance if we don't have one, ie on first render (OR) If current
         * balance is a BigNumber and is not equal to next balance, then update state.
         */
        if (
          !ethL1Balance ||
          (ethL1Balance.balance?._isBigNumber &&
            !ethL1Balance.balance.eq(nextBalance.balance))
        ) {
          setEthL1Balance(nextBalance);
        }
      },
      error => setError(error),
    );

    return () => subscription.current?.unsubscribe();
  }, [ethL1Balance, error]);

  return {
    ethL1Error: error,
    ethL1Balance: ethL1Balance,
    ethL1Loading: !ethL1Balance?.balance && !error,
  };
}
