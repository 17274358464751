import { css } from '@emotion/css';
import {
  BaseComponentPropTypes,
  colors,
  CustomUiText,
  FiatEquvalentPrice,
  FlexLayout,
  layoutHelpers,
  PricingDisplay,
} from '@imtbl/design-system';
import React from 'react';
export interface BalanceControlCurrencyListItemProps {
  balance: string;
  image_url: string;
  symbol: string;
  fiatEquivalentPrice?: FiatEquvalentPrice;
}
export function BalanceControlCurrencyListItem(
  props: BalanceControlCurrencyListItemProps & BaseComponentPropTypes,
) {
  const { balance, image_url, symbol, fiatEquivalentPrice, testId } = props;

  return (
    <FlexLayout
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      key={symbol}
      className={css`
        &:first-child {
          padding-top: ${layoutHelpers.gridUnits(3)};
        }
      `}
      testId={testId}
    >
      <PricingDisplay
        tokenAmount={balance}
        tokenIconUrl={image_url}
        testId={`${testId}__balance`}
        fontSize="large"
        responsiveFontSize={[{ breakpoint: 'medium', fontSize: 'small' }]}
        disableTooltip
        textAlign="left"
        fiatEquivalentPrice={fiatEquivalentPrice}
      />
      <CustomUiText
        fillColor={colors.light[300]}
        fontWeight="medium"
        testId={`${testId}__symbol`}
        fontSize="large"
        responsiveFontSize={[{ breakpoint: 'medium', fontSize: 'small' }]}
      >
        {symbol}
      </CustomUiText>
    </FlexLayout>
  );
}
