import { SidebarCancelButton } from '../shared/SidebarCancelButton.component';
import { SidebarCreditCardCta } from '../shared/SidebarCreditCardCta.component';
import { SidebarDepositCta } from '../shared/SidebarDepositCta.component';
import { SidebarDescription } from '../shared/SidebarDescription.component';
import { SidebarLayout } from '../shared/SidebarLayout.component';
import { SidebarTitle } from '../shared/SidebarTitle.component';
import { AddFundsSidebarPanelProps } from '../shared/types';

export const AddFundsSidebarPanel = ({
  sidebarPanelId,
  testId = 'AddFundsSidebarPanel',
  showWalletDepositCta,
}: AddFundsSidebarPanelProps) => {
  return (
    <SidebarLayout testId={testId}>
      <SidebarTitle testId={testId}>Add funds</SidebarTitle>
      <SidebarDescription>
        To make purchases on Marketplace, you&apos;ll need to Add funds into
        your <strong>Immutable account</strong>. <br />
        <br />
        How would you like to Add funds?
      </SidebarDescription>
      <SidebarCreditCardCta sidebarPanelId={sidebarPanelId} />
      {showWalletDepositCta && (
        <SidebarDepositCta sidebarPanelId={sidebarPanelId} />
      )}
      <SidebarCancelButton testId={testId} sidebarPanelId={sidebarPanelId} />
    </SidebarLayout>
  );
};
