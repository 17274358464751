import { GetBalanceResponse } from 'api/balances';
import { createContext, useContext, useState } from 'react';
import { AssetData } from 'types/Imx';

import {
  InflightPurchaseContextPropTypes,
  InflightPurchaseContextValue,
} from './types';

export const initialContext: InflightPurchaseContextValue = {
  inflightAssets: [],
  initialBalance: undefined,
  setInflightAssets: () => null,
  setInitialBalance: () => null,
  clearInflightPurchase: () => null,
};

export const InflightPurchaseContext =
  createContext<InflightPurchaseContextValue>(initialContext);

export const InflightPurchaseProvider = ({
  children,
  initialPurchase,
}: InflightPurchaseContextPropTypes) => {
  const [inflightAssets, setInflightAssets] = useState<Partial<AssetData>[]>();
  const [initialBalance, setInitialBalance] = useState<GetBalanceResponse>();
  const clearInflightPurchase = () => {
    setInflightAssets([]);
    setInitialBalance(undefined);
  };

  return (
    <InflightPurchaseContext.Provider
      value={{
        inflightAssets,
        initialBalance,
        setInflightAssets,
        setInitialBalance,
        clearInflightPurchase,
        ...initialPurchase,
      }}
    >
      {children}
    </InflightPurchaseContext.Provider>
  );
};

export const useInflightPurchaseContext = () => {
  const context = useContext(InflightPurchaseContext);

  if (!context) {
    throw new Error(
      "useInflightPurchaseContext() must be used within a '<InflightPurchaseProvider />'",
    );
  }

  return context;
};
