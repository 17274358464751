import { LinkParams } from '@imtbl/imx-sdk';
import { ContextActionTypes } from 'types';

import { ImxLinkActions } from './constants';
import { ImxLinkState } from './state';

export const openLinkSidebar =
  (): ContextActionTypes<ImxLinkActions.OPEN_LINK_SIDEBAR> => ({
    type: ImxLinkActions.OPEN_LINK_SIDEBAR,
  });
export const closeLinkSidebar =
  (): ContextActionTypes<ImxLinkActions.CLOSE_LINK_SIDEBAR> => ({
    type: ImxLinkActions.CLOSE_LINK_SIDEBAR,
  });
export const walletSetup =
  (): ContextActionTypes<ImxLinkActions.CONNECT_WALLET_LOADING> => ({
    type: ImxLinkActions.CONNECT_WALLET_LOADING,
  });

export const walletSetupSuccess = (
  data: Pick<ImxLinkState, 'starkPublicKey' | 'walletAddress'>,
): ContextActionTypes<ImxLinkActions.CONNECT_WALLET_SUCCESS, typeof data> => ({
  type: ImxLinkActions.CONNECT_WALLET_SUCCESS,
  payload: data,
});

export const walletSetupError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.CONNECT_WALLET_ERROR, Error> => ({
  type: ImxLinkActions.CONNECT_WALLET_ERROR,
  payload: error,
});

export const walletDisconnect =
  (): ContextActionTypes<ImxLinkActions.WALLET_DISCONNECT> => ({
    type: ImxLinkActions.WALLET_DISCONNECT,
  });

export const buyInit = (
  data: LinkParams.Buy,
): ContextActionTypes<ImxLinkActions.BUY_LOADING, typeof data> => ({
  type: ImxLinkActions.BUY_LOADING,
  payload: data,
});

export const buySuccess = (
  data: LinkParams.Buy,
): ContextActionTypes<ImxLinkActions.BUY_SUCCESS, typeof data> => ({
  type: ImxLinkActions.BUY_SUCCESS,
  payload: data,
});

export const buyError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.BUY_ERROR, Error> => ({
  type: ImxLinkActions.BUY_ERROR,
  payload: error,
});

export const sellInit = (
  data: LinkParams.Sell,
): ContextActionTypes<ImxLinkActions.SELL_LOADING, typeof data> => ({
  type: ImxLinkActions.SELL_LOADING,
  payload: data,
});

export const sellSuccess = (
  data: LinkParams.Sell,
): ContextActionTypes<ImxLinkActions.SELL_SUCCESS, typeof data> => ({
  type: ImxLinkActions.SELL_SUCCESS,
  payload: data,
});

export const sellError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.SELL_ERROR, Error> => ({
  type: ImxLinkActions.SELL_ERROR,
  payload: error,
});

export const cancelInit = (
  data: LinkParams.Cancel,
): ContextActionTypes<ImxLinkActions.CANCEL_LOADING, typeof data> => ({
  type: ImxLinkActions.CANCEL_LOADING,
  payload: data,
});

export const cancelSuccess = (
  data: LinkParams.Cancel,
): ContextActionTypes<ImxLinkActions.CANCEL_SUCCESS, typeof data> => ({
  type: ImxLinkActions.CANCEL_SUCCESS,
  payload: data,
});

export const cancelError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.CANCEL_ERROR, Error> => ({
  type: ImxLinkActions.CANCEL_ERROR,
  payload: error,
});

export const depositInit = (
  data: LinkParams.Deposit,
): ContextActionTypes<ImxLinkActions.DEPOSIT_LOADING, typeof data> => ({
  type: ImxLinkActions.DEPOSIT_LOADING,
  payload: data,
});

export const depositSuccess = (
  data: LinkParams.Deposit,
): ContextActionTypes<ImxLinkActions.DEPOSIT_SUCCESS, typeof data> => ({
  type: ImxLinkActions.DEPOSIT_SUCCESS,
  payload: data,
});

export const depositError = (payload: {
  data: LinkParams.Deposit;
  error: Error;
}): ContextActionTypes<ImxLinkActions.DEPOSIT_ERROR, typeof payload> => ({
  type: ImxLinkActions.DEPOSIT_ERROR,
  payload,
});

export const prepareWithdrawInit =
  (): ContextActionTypes<ImxLinkActions.PREPARE_WITHDRAW_LOADING> => ({
    type: ImxLinkActions.PREPARE_WITHDRAW_LOADING,
  });

export const prepareWithdrawSuccess = (
  data: LinkParams.PrepareWithdrawal,
): ContextActionTypes<
  ImxLinkActions.PREPARE_WITHDRAW_SUCCESS,
  typeof data
> => ({
  type: ImxLinkActions.PREPARE_WITHDRAW_SUCCESS,
  payload: data,
});

export const prepareWithdrawError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.PREPARE_WITHDRAW_ERROR, Error> => ({
  type: ImxLinkActions.PREPARE_WITHDRAW_ERROR,
  payload: error,
});

export const completeWithdrawInit =
  (): ContextActionTypes<ImxLinkActions.COMPLETE_WITHDRAW_LOADING> => ({
    type: ImxLinkActions.COMPLETE_WITHDRAW_LOADING,
  });

export const completeWithdrawSuccess = (
  data: LinkParams.CompleteWithdrawal,
): ContextActionTypes<
  ImxLinkActions.COMPLETE_WITHDRAW_SUCCESS,
  typeof data
> => ({
  type: ImxLinkActions.COMPLETE_WITHDRAW_SUCCESS,
  payload: data,
});

export const completeWithdrawError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.COMPLETE_WITHDRAW_ERROR, Error> => ({
  type: ImxLinkActions.COMPLETE_WITHDRAW_ERROR,
  payload: error,
});

export const historyInit =
  (): ContextActionTypes<ImxLinkActions.HISTORY_LOADING> => ({
    type: ImxLinkActions.HISTORY_LOADING,
  });

export const historySuccess =
  (): ContextActionTypes<ImxLinkActions.HISTORY_SUCCESS> => ({
    type: ImxLinkActions.HISTORY_SUCCESS,
  });

export const historyError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.HISTORY_ERROR, Error> => ({
  type: ImxLinkActions.HISTORY_ERROR,
  payload: error,
});

export const fiatToCryptoInit =
  (): ContextActionTypes<ImxLinkActions.FIAT_TO_CRYPTO_LOADING> => ({
    type: ImxLinkActions.FIAT_TO_CRYPTO_LOADING,
  });

export const fiatToCryptoSuccess =
  (): ContextActionTypes<ImxLinkActions.FIAT_TO_CRYPTO_SUCCESS> => ({
    type: ImxLinkActions.FIAT_TO_CRYPTO_SUCCESS,
  });

export const fiatToCryptoError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.FIAT_TO_CRYPTO_ERROR, Error> => ({
  type: ImxLinkActions.FIAT_TO_CRYPTO_ERROR,
  payload: error,
});

export const cryptoToFiatInit =
  (): ContextActionTypes<ImxLinkActions.CRYPTO_TO_FIAT_LOADING> => ({
    type: ImxLinkActions.CRYPTO_TO_FIAT_LOADING,
  });

export const cryptoToFiatSuccess =
  (): ContextActionTypes<ImxLinkActions.CRYPTO_TO_FIAT_SUCCESS> => ({
    type: ImxLinkActions.CRYPTO_TO_FIAT_SUCCESS,
  });

export const cryptoToFiatError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.CRYPTO_TO_FIAT_ERROR, Error> => ({
  type: ImxLinkActions.CRYPTO_TO_FIAT_ERROR,
  payload: error,
});

export const makeOfferInit = (
  data: LinkParams.MakeOffer,
): ContextActionTypes<ImxLinkActions.MAKE_OFFER_LOADING, typeof data> => ({
  type: ImxLinkActions.MAKE_OFFER_LOADING,
  payload: data,
});

export const makeOfferSuccess = (
  data: LinkParams.MakeOffer,
): ContextActionTypes<ImxLinkActions.MAKE_OFFER_SUCCESS, typeof data> => ({
  type: ImxLinkActions.MAKE_OFFER_SUCCESS,
  payload: data,
});

export const makeOfferError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.MAKE_OFFER_ERROR, Error> => ({
  type: ImxLinkActions.MAKE_OFFER_ERROR,
  payload: error,
});

export const cancelOfferInit = (
  data: LinkParams.CancelOffer,
): ContextActionTypes<ImxLinkActions.CANCEL_OFFER_LOADING, typeof data> => ({
  type: ImxLinkActions.CANCEL_OFFER_LOADING,
  payload: data,
});

export const cancelOfferSuccess = (
  data: LinkParams.CancelOffer,
): ContextActionTypes<ImxLinkActions.CANCEL_OFFER_SUCCESS, typeof data> => ({
  type: ImxLinkActions.CANCEL_OFFER_SUCCESS,
  payload: data,
});

export const cancelOfferError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.CANCEL_OFFER_ERROR, Error> => ({
  type: ImxLinkActions.CANCEL_OFFER_ERROR,
  payload: error,
});

export const acceptOfferInit = (
  data: LinkParams.AcceptOffer,
): ContextActionTypes<ImxLinkActions.ACCEPT_OFFER_LOADING, typeof data> => ({
  type: ImxLinkActions.ACCEPT_OFFER_LOADING,
  payload: data,
});

export const acceptOfferSuccess = (
  data: LinkParams.AcceptOffer,
): ContextActionTypes<ImxLinkActions.ACCEPT_OFFER_SUCCESS, typeof data> => ({
  type: ImxLinkActions.ACCEPT_OFFER_SUCCESS,
  payload: data,
});

export const acceptOfferError = (
  error: Error,
): ContextActionTypes<ImxLinkActions.ACCEPT_OFFER_ERROR, Error> => ({
  type: ImxLinkActions.ACCEPT_OFFER_ERROR,
  payload: error,
});
