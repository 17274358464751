import { cx } from '@emotion/css';
import { ModalProvider, ModalSystem } from '@imtbl/design-system';
import { useBrowserLayoutEffect } from 'hooks/useBrowserLayoutEffect.hook';
import React, { ReactNode, useRef, useState } from 'react';
import { customModalBackdrop, customModalContent } from 'styles/modals.styles';

export function DsModalProvider({ children }: { children: ReactNode }) {
  const mountDom = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useBrowserLayoutEffect(() => {
    mountDom.current = document.querySelector('#modal');
    setMounted(true);
  }, []);

  return (
    <ModalProvider>
      {mounted && mountDom.current && (
        <ModalSystem
          portalMountPoint={mountDom.current}
          backdropClassName={cx(customModalBackdrop)}
          modalContentClassName={cx(customModalContent)}
        />
      )}
      {children}
      <div id="modal" />
    </ModalProvider>
  );
}
