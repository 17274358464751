import { closeModal, externalModalSystemContext } from '@imtbl/design-system';

export function closeLastOpenedModal() {
  const { modalList = [] } = externalModalSystemContext;
  if (!modalList.length) {
    return;
  }

  const { id: openModalId } = modalList[modalList.length - 1];
  closeModal(openModalId);
}
