import packageJSON from '../../../package.json';
import { AnalyticsEvent } from './types';
import {
  getAppName,
  getUserAgent,
  getUTMParams,
  getWalletAddressFromLocalStorage,
} from './utils';

export type AkumaEvent = AnalyticsEvent & {
  appName: string;
  appBrand: string;
  appPlatform: string;
  appVersion: string;
  moduleName: string;
};

declare const window: {
  akuma: (e: AkumaEvent) => void;
};

export class AkumaAnalytics {
  // Default values for the passed core attributes.
  // NOTE: These could be overriden by constructor!
  private appName? = '';

  private appBrand = 'imx';

  private appPlatform = 'web';

  private appVersion = `v${packageJSON.version}`;

  private moduleName = '';

  private userAgent? = '';

  constructor(version?: string) {
    if (version && version.length) {
      this.appVersion = version;
    }
  }

  private checkAttributes() {
    if (!this.appName) {
      this.appName = getAppName();
    }

    if (!this.userAgent) {
      this.userAgent = getUserAgent();
    }
  }

  public publish(analyticsEvent: AnalyticsEvent) {
    this.checkAttributes();
    const publishEvent = {
      appName: this.appName,
      appBrand: this.appBrand,
      appPlatform: this.appPlatform,
      appVersion: this.appVersion,
      moduleName: this.moduleName,
      ...analyticsEvent,
    } as AkumaEvent;

    const ua = this.userAgent;
    const walletAddress = getWalletAddressFromLocalStorage();
    const utmParams = getUTMParams();
    let { userProperties = {} } = publishEvent;

    if (!userProperties.userAgent && ua) {
      publishEvent.userProperties = {
        ...userProperties,
        userAgent: ua,
      };
    }

    if (Object.entries(utmParams).length) {
      ({ userProperties = {} } = publishEvent);
      publishEvent.userProperties = {
        ...userProperties,
        ...utmParams,
      };
    }

    if (walletAddress) {
      publishEvent.userId = walletAddress;
    }

    try {
      window.akuma(publishEvent);
    } catch (e) {
      console.error('Akuma Event Publish ERROR', e);
    }
  }
}
