import { BigNumber } from '@ethersproject/bignumber';
import { formatUnits } from '@ethersproject/units';
import { colors, TokenNetwork as Network } from '@imtbl/design-system';
import {
  ERC20TokenType,
  ETHTokenType,
  ImmutableAssetStatus,
  ImmutableFeeType,
  ImmutableMethodResults,
} from '@imtbl/imx-sdk';
import { AssetData, AssetStatus, AssetUiStatus, FeeBreakdown } from 'types/Imx';

import { formatTokenBalanceWithoutTruncation } from '../../api/balances';

export function getForSalePrice(
  order?: ImmutableMethodResults.ImmutableGetOrderV3ResultTS,
) {
  if (!order?.user) {
    return '';
  }

  return order?.buy?.type === ERC20TokenType.ERC20 ||
    order?.buy?.type === ETHTokenType.ETH
    ? formatUnits(
        order.taker_fees?.quantity_with_fees,
        order.taker_fees.decimals,
      )
    : formatUnits(
        order.maker_fees?.quantity_with_fees,
        order.maker_fees.decimals,
      );
}

export function getSellOrderBasePrice(
  order?: ImmutableMethodResults.ImmutableGetOrderV3ResultTS,
) {
  if (!order?.user) {
    return '';
  }

  return order?.buy?.type === ERC20TokenType.ERC20 ||
    order?.buy?.type === ETHTokenType.ETH
    ? formatUnits(order.buy.data.quantity, order.buy.data.decimals)
    : formatUnits(order.buy.data.quantity);
}

// We are situationally finding the number of decimal places here. When this ticket is resolved: https://immutable.atlassian.net/browse/MP-993 we should standardise quantisation across the marketplace.
export function getFundsAmountRequired(
  order?: ImmutableMethodResults.ImmutableGetOrderV3ResultTS,
  balance?: ImmutableMethodResults.ImmutableGetBalanceResult,
) {
  if (!balance || !order) {
    return '0';
  }

  // It only makes sense to calculate the amount required if the order.buy.type is a fungible token.
  if (
    order?.buy?.type !== ERC20TokenType.ERC20 &&
    order?.buy?.type !== ETHTokenType.ETH
  ) {
    return '0';
  }

  // @NOTE: This fixes a hard to replicate bug where rawPrice is a string.
  const rawPrice = BigNumber.from(order.taker_fees?.quantity_with_fees);
  const priceDiff = rawPrice.sub(balance?.balance);

  return priceDiff;
}

export function getOrderFeeBreakdown(
  order?: ImmutableMethodResults.ImmutableGetOrderV3ResultTS,
): FeeBreakdown | null {
  if (!order?.taker_fees?.fees) return null;
  const amount = order?.user ? order?.buy?.data?.quantity : 0;
  const orderAmountBN = BigNumber.from(amount);

  return order?.taker_fees?.fees?.reduce(
    (breakdown, fee) => {
      const feeAmountBN = BigNumber.from(fee.amount);
      const feePercentage = feeAmountBN
        .div(orderAmountBN.sub(feeAmountBN))
        .toNumber();

      switch (fee.type) {
        case ImmutableFeeType.royalty:
          breakdown.royalties += feePercentage;
          break;
        case ImmutableFeeType.ecosystem:
        case ImmutableFeeType.maker:
        case ImmutableFeeType.taker:
          breakdown.ecosystem += feePercentage;
          break;
        case ImmutableFeeType.protocol:
          breakdown.protocol += feePercentage;
          break;
        default:
          break;
      }
      breakdown.total += feePercentage;
      return breakdown;
    },
    {
      royalties: 0,
      protocol: 0,
      ecosystem: 0,
      total: 0,
    } as FeeBreakdown,
  );
}

export function getAssetFeeBreakdown(
  asset?: AssetData['asset'],
): FeeBreakdown | null {
  if (!asset?.fees) return null;
  return asset?.fees?.reduce(
    (breakdown, fee) => {
      //royalties are the only fee type for assets
      if (fee.type === ImmutableFeeType.royalty) {
        breakdown.royalties += fee.percentage;
        breakdown.total += fee.percentage;
      }
      return breakdown;
    },
    {
      royalties: 0,
      protocol: 0,
      ecosystem: 0,
      total: 0,
    } as FeeBreakdown,
  );
}

export function isAssetAffordable(
  order?: ImmutableMethodResults.ImmutableGetOrderV3ResultTS,
  balance?: ImmutableMethodResults.ImmutableGetBalanceResult,
) {
  const isForSale = !!order?.user;
  const forSalePrice = getForSalePrice(order);

  // @NOTE: This is calculated incorrectly since we still hardcode max decimal places: https://immutable.atlassian.net/browse/MP-993
  const orderDecimals =
    order?.buy?.type === ERC20TokenType.ERC20 ||
    order?.buy?.type === ETHTokenType.ETH
      ? String(order?.buy?.data?.decimals)
      : undefined;
  const userImxBalance = balance
    ? formatTokenBalanceWithoutTruncation(balance.balance, orderDecimals)
    : '';

  return isForSale
    ? parseFloat(forSalePrice) <= parseFloat(userImxBalance)
    : false;
}

export function pickAssetNetwork(s: AssetStatus | ImmutableAssetStatus) {
  return s === AssetStatus.ETH ? Network.ethereum : Network.immutableX;
}

export function pickImxEthAmountColour(
  isWalletConnected: boolean,
  isEthBalanceZero: boolean,
) {
  if (isWalletConnected && isEthBalanceZero) {
    return colors.red[300];
  }

  if (!isWalletConnected && isEthBalanceZero) {
    return colors.dark[100];
  }

  return colors.light[100];
}

export function pickAssetStatusTextColoring(statusText?: AssetUiStatus) {
  switch (statusText) {
    case AssetUiStatus.NOT_ON_IMX:
      return colors.red[300];

    case AssetUiStatus.NOT_FOR_SALE:
      return colors.dark[100];

    case AssetUiStatus.LISTED_FOR_SALE:
      return colors.orange[300];

    case AssetUiStatus.OWNED:
      return colors.blue[300];

    case AssetUiStatus.AVAILABLE:
    default:
      return colors.light[100];
  }
}

export function buildAssetPageTitle(
  assetId?: string | null,
  assetName?: string | null,
  collection?: string | null,
) {
  const collectionName = `${collection ? ` | ${collection}` : ''}`;
  const assetIdentifier = `${collection && assetId ? ` #${assetId}` : ''}`;
  return `${assetName ?? ''}${collectionName}${assetIdentifier}`;
}

export function buildTwitterTitle(
  assetName?: string | null,
  collection?: string | null,
) {
  const collectionName = `${collection ? ` | ${collection}` : ''}`;
  return `${assetName ?? ''}${collectionName}`;
}

// getFormattedValueForDisplay accepts an amount and precision and returns a formatted string
export function getFormattedValueForDisplay(
  amount: number,
  precision: number,
): string {
  const fixedPlaceAmt: number = parseFloat(amount.toFixed(precision));
  return fixedPlaceAmt % 1 !== 0
    ? fixedPlaceAmt.toFixed(precision)
    : fixedPlaceAmt.toString();
}
