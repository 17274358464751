export enum AssetStatus {
  // Default statuses.
  ETH = 'eth',
  IMX = 'imx',

  // Statuses related to deposits.
  DEPOSITING = 'depositing',

  // Statuses related to withdrawal.
  PREPARE_WITHDRAW = 'preparing_withdrawal',
  WITHDRAWABLE = 'withdrawable',
  WITHDRAWING = 'withdrawing',
}

export enum AssetUiStatus {
  // Default statuses.
  OWNED = 'Owned',
  UNKNOWN = 'Unknown',
  AVAILABLE = 'Available',
  NOT_ON_IMX = 'Not on Immutable',

  // Statuses related to buy / sell.
  LISTED_FOR_SALE = 'Listed for Sale',
  NOT_FOR_SALE = 'Not for Sale',

  // Statuses related to withdrawal.
  PREPARE_WITHDRAW = 'Preparing Withdrawal',
  WITHDRAWABLE = 'Withdrawable',
  DEPOSITING = 'Depositing...',
}
