import { useLaunchDarklyFlags } from 'context/LaunchDarkly';

interface FeatureFlagPropTypes {
  children: JSX.Element;
  fallback?: JSX.Element;
  name: string;
}

export function FeatureFlag({
  children,
  fallback,
  name,
}: FeatureFlagPropTypes) {
  const flags = useLaunchDarklyFlags();
  const shouldRender = Boolean(flags[name]);
  return shouldRender ? children : fallback || null;
}
