import { ImxLinkInfoEventType, LINK_INFO_MESSAGE_TYPE } from '@imtbl/imx-sdk';
import { Dispatch } from 'react';
import { identifyUser } from 'utils/user-logging';

import { walletSetupSuccess } from './actions';
import { ImxLinkActionTypes } from './types';

export const linkInfoEventHandler =
  (dispatch: Dispatch<ImxLinkActionTypes>) => (event: CustomEvent) => {
    let starkPublicKey = '';
    let walletAddress = '';

    if (event?.detail?.payload) {
      starkPublicKey = event.detail.payload.starkPublicKey;
      walletAddress = event.detail.payload.walletAddress;
    }

    if (event?.detail?.type === ImxLinkInfoEventType.WALLET_CONNECTION) {
      dispatch(
        walletSetupSuccess({
          starkPublicKey,
          walletAddress,
        }),
      );

      if (walletAddress) identifyUser(walletAddress);
    }
  };

export const linkAddInfoEventListener = (
  dispatch: Dispatch<ImxLinkActionTypes>,
) =>
  window.addEventListener(
    LINK_INFO_MESSAGE_TYPE,
    linkInfoEventHandler(dispatch),
  );

export const linkRemoveInfoEventListener = (
  dispatch: Dispatch<ImxLinkActionTypes>,
) =>
  window.removeEventListener(
    LINK_INFO_MESSAGE_TYPE,
    linkInfoEventHandler(dispatch),
  );
