import {
  colors,
  measurements,
  OuterSpace,
  ParagraphText,
} from '@imtbl/design-system';

import { SidebarPanelComponentProps } from './types';

export const SidebarDescription = ({
  children,
  testId = 'sidebarDescription',
}: SidebarPanelComponentProps) => (
  <OuterSpace
    top={measurements.SpacingTeeShirtAmounts.large}
    bottom={measurements.SpacingTeeShirtAmounts.medium}
  >
    <ParagraphText
      testId={testId}
      fillColor={colors.light[300]}
      fontSize="small"
    >
      {children}
    </ParagraphText>
  </OuterSpace>
);
