import { TooltipProvider, TooltipSystem } from '@imtbl/design-system';
import { useBrowserLayoutEffect } from 'hooks/useBrowserLayoutEffect.hook';
import React, { ReactNode, useRef, useState } from 'react';

export function DsTooltipProvider({ children }: { children: ReactNode }) {
  const mountDom = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useBrowserLayoutEffect(() => {
    mountDom.current = document.querySelector('#tooltip');
    setMounted(true);
  }, []);

  return (
    <TooltipProvider>
      {mounted && mountDom.current && (
        <TooltipSystem portalMountPoint={mountDom.current} />
      )}
      {children}
      <div id="tooltip" />
    </TooltipProvider>
  );
}
