// @NOTE: The values listed in this 'enum' should match the keys in LaunchDarkly.
// For local development they can be overwritten in './local-feature-flags.json
export enum FEATURE_FLAG {
  //
  // @NOTE: These FF's can be safely deprecated / archived:
  ENABLE_GLOBAL_NAVBAR = 'mp368_enableGlobalNavbar',
  ENABLE_ASSET_DETAILS_V2 = 'mp227_enable_new_look_asset_details_page',
  ENABLE_EXCHANGE = 'imx4307_enableExchange',

  //
  // @NOTE: These FF's are still required:
  DEMO_FEATURE_FLAG = 'test-key',
  ENABLE_SELL_WITH_PRICE_AND_CURRENCY_IN_LINK = 'imx4663_sellWithDefiningPriceAndCurrencyInLink',
  SHOW_DAILYPOINT_SUPPORT_HELPER = 'imx2121_webDailyPointsSupportArticle',
  SHOW_IMXD_WITHDRAWAL_BALANCE = 'imx1501_imxdWithdrawalBalance',
  SHOW_DAILY_POINTS_BALANCE = 'imx1612_dailyPointsBalance',
  ENABLE_INVENTORY_IMX_AND_ETH_TABS = 'imx2048_enableInventoryImxAndEthTabs',
  ENABLE_CUSTODIAL_WALLET = 'imx3767_enableCustodialWallet',
  FEATURED_COLLECTIONS_LIST = 'imx4079_featuredCollectionAddresses',
  HOMEPAGE_COLLECTIONS_SORT_BY_VALUE = 'imx4079_homepageCollectionsSortByValue',
  HOMEPAGE_COLLECTIONS_DIRECTION_VALUE = 'imx4079_homepageCollectionsSortDirectionValue',
  HOMEPAGE_COLLECTIONS_BLACKLIST = 'imx4329_homepageCollectionsBlackList',
  ENABLE_IMX_AWAITING_CLIFF = 'imx4320_enableImxAwaitingCliff',
  PROVIDER_PREFERENCE = 'imx3767_providerPreference',
  ENABLE_TEST_IP_FILTERING = 'MP-209-test-ip-filtering',
  ENABLE_IP_FILTERING = 'mp209_IpFiltering',
  AVERAGE_WITHDRAWAL_TIME = 'mp206_average_withdrawal_time',
  ENABLE_CURRENCY_FILTER = 'imx5088_erc20CurrencyFilter',
  ENABLE_HOMEPAGE_FAKE_SEARCH_BAR = 'MP207_enableHomepageFakeSearchBar',
  ENABLE_LOGROCKET_RECORDING = 'mp1008_enableLogrocketRecording',
  ENABLE_MAKER_FEES = 'mp313_enableMarketplaceMakerFee',
  ENABLE_TAKER_FEES = 'mp315_enableMarketplaceTakerFee',
  OPT_OUT_IMAGE_CACHING = 'opt-out-of-image-caching',
  ENABLE_USD_PRICING = 'MP761_showUSDPricing',
  ENABLE_TRACKING_IN_AMPLITUDE_OF_FEATURED_COLLECTIONS = 'IMX_757_enable_tracking_in_amplitude_for_featured_collections',
  ENABLE_SELL_CRYPTO = 'wt394_enableSellCrypto',
  ENABLE_REVAMPED_DEPOSIT = 'mp688_revamped-deposit-flow',
  ENABLE_PARTIALLY_UNBLOCKED_BUY_BUTTON = 'mp851_partiallyEnableBuyButton',
  REVAMPED_PREPARE_WITHDRAW_FLOW = 'mp938_revampedPrepareWithdrawFlow',
  ENABLE_ALWAYS_ENABLED_BUY_BUTTON = 'mp852_always-enabled-buy-now-button',
  ENABLE_RECENTLY_SOLD_FILTER = 'mp950_recently_sold_filter',
  ENABLE_TRADE_AND_EARN_BANNER = 'mp1042_enableTradeAndEarnBanner',
  ENABLE_OFFERS_BANNER = 'enableOffersBanner',
  ENABLE_ASSET_DETAIL_PRICE_HISTORY = 'mp1081_enable_asset_detail_price_history',
  DISABLE_SHOW_BUY_ASSET_FEES_PERCENTAGE = 'mp1140_disable_show_buy_asset_fees_percentage',
  ENABLE_STACKED_ASSETS = 'mp951_enableStackedAssets',
  ENABLE_IMMUTASCAN_LINK = 'mp991_enable-link-to-immutascan',
  ENABLE_OFFERS = 'mp1159_enable-bids-and-offers',
  ENABLE_NEW_HOMEPAGE = 'mp1233_enable-redesigned-homepage',
  ENABLE_COLLECTION_STATS = 'mp1160_enableCollectionStats',
  ENABLE_SPLIT_FEES = 'TD376-enable-split-fees',
  ENABLE_V3_LISTINGS = 'TD524-enable-v3-listings',
}
