import { css, cx } from '@emotion/css';
import {
  BaseComponentPropTypes,
  StyledLink,
  StyledLinkPropTypes,
} from '@imtbl/design-system';
import Link, { LinkProps as NextLinkProps } from 'next/link';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

interface NextLinkPropTypes
  extends BaseComponentPropTypes<HTMLAnchorElement>,
    Omit<
      DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
      'onClick' | 'onMouseEnter' | 'onTouchStart' | 'ref'
    >,
    NextLinkProps {
  underline?: boolean;
}

interface StyledNextLinkPropTypes
  extends Omit<StyledLinkPropTypes, 'href' | 'domRef'>,
    Omit<NextLinkPropTypes, 'children' | 'onClick'> {
  href: string;
}

export const NextLink = ({
  testId,
  children,
  className,
  onClick,
  domRef,
  underline,
  ...nextLinkProps
}: NextLinkPropTypes) => (
  /*
  @NOTE: these props are needed purely because we allow an onClick prop
  to be passed through and added to the <a>. Eslint dont like this. :zany_face:
  */
  /* eslint jsx-a11y/anchor-is-valid: "off" */
  /* eslint jsx-a11y/click-events-have-key-events: "off" */
  /* eslint jsx-a11y/no-static-element-interactions: "off" */
  <Link {...nextLinkProps}>
    <a
      ref={domRef}
      data-testid={testId}
      className={cx(
        css`
          text-decoration: ${underline ? 'underline' : 'none'};
        `,
        className,
      )}
      onClick={onClick}
    >
      {children}
    </a>
  </Link>
);

export const StyledNextLink = ({
  href,
  onClick,
  className,
  children,
  underline,
  ...props
}: StyledNextLinkPropTypes) => {
  return (
    <StyledLink
      {...props}
      className={cx(
        css`
          text-decoration: ${underline ? 'underline' : 'none'};
        `,
        className,
      )}
    >
      <NextLink href={href} onClick={onClick}>
        {children}
      </NextLink>
    </StyledLink>
  );
};
