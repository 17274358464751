import { ImmutableMethodResults } from '@imtbl/imx-sdk';
import BigNumber from 'bignumber.js';

export const DEFAULT_TOKEN_ICON_URL =
  'https://design-system.immutable.com/currency_icons/currency--eth.svg';

export function calculateMinimumSafeDecimalValue(
  currentlySelectedToken: ImmutableMethodResults.ImmutableGetTokenResult,
): BigNumber {
  const maxSafeDecimals = parseInt(currentlySelectedToken.decimals, 10) - 4;
  return new BigNumber(1).dividedBy(10 ** maxSafeDecimals);
}

export const generateDecimalErrorMessage = ({
  symbol,
  minSafeValue,
}: {
  symbol: string;
  minSafeValue: BigNumber;
}) =>
  `Please enter an amount greater than ${minSafeValue.toFormat()} ${symbol}`;
