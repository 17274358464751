import { useLaunchDarklyFlags } from 'context/LaunchDarkly';
import { convertCommaListToStringArray } from 'utils/parser';

export const useFeatureFlag = <ValueType = boolean>(
  flagName: string,
): ValueType => {
  const flags = useLaunchDarklyFlags();
  return flags[flagName];
};

export const useCommaListFeatureFlag = (flagName: string): string[] => {
  const flags = useLaunchDarklyFlags();
  return convertCommaListToStringArray(flags[flagName]);
};
