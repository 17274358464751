import { setUser } from '@sentry/nextjs';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { logRocketProject, logRocketReleaseName } from '../../environment';

const LOGROCKET_ORGANIZATION = 'kpaz4s';
let logRocketInitialised = false;

export const initLogRocket = () => {
  LogRocket.init(`${LOGROCKET_ORGANIZATION}/${logRocketProject}`, {
    release: logRocketReleaseName,
  });
  setupLogRocketReact(LogRocket);
  logRocketInitialised = true;
};

declare const window: {
  _sva: {
    setVisitorTraits: ({
      user_wallet,
      user_id,
    }: {
      user_wallet: string;
      user_id: string;
    }) => void;
  };
};

export const identifyUser = (walletAddress: string) => {
  if (logRocketInitialised) {
    LogRocket.identify(walletAddress);
  }
  setUser({ walletAddress });

  // @NOTE: the below makes "survicate" aware of our users, once they connect their wallets...
  window._sva?.setVisitorTraits?.({
    user_wallet: walletAddress,
    user_id: walletAddress,
  });
};
