import {
  colors,
  ParagraphText,
  SidebarPanelChildContentPropTypes,
} from '@imtbl/design-system';

import { FlowEventProps } from '../../../../libs/analytics';
import { SidebarDescription } from '../shared/SidebarDescription.component';
import { SidebarLayout } from '../shared/SidebarLayout.component';
import { SidebarTitle } from '../shared/SidebarTitle.component';
import { PrepareWithdrawFormUi } from './PrepareWithdrawFormUi.component';

export type PrepareWithdrawSidebarPanelProps =
  SidebarPanelChildContentPropTypes & {
    handleFormUpdate: (formData: FlowEventProps) => void;
  };

export const PrepareWithdrawSidebarPanel = ({
  sidebarPanelId,
  handleFormUpdate,
}: PrepareWithdrawSidebarPanelProps) => {
  return (
    <SidebarLayout testId="PrepareWithdraw">
      <SidebarTitle>Withdraw</SidebarTitle>
      <SidebarDescription>
        Initiate a withdrawal of tokens from Immutable into your Ethereum
        wallet.
      </SidebarDescription>
      <ParagraphText
        fillColor={colors.light[100]}
        fontWeight="bold"
        fontSize="small"
      >
        Please note, this process can take over 48hrs.
      </ParagraphText>

      <PrepareWithdrawFormUi
        sidebarPanelId={sidebarPanelId}
        handleFormUpdate={handleFormUpdate}
      />
    </SidebarLayout>
  );
};
