import { GTagEvent } from './types';

export const GA_URL = process.env.GA_URL;
export const GA_URL_JS_PATH = `${GA_URL}/gtag/js`;
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;

export function triggerEvent(eventName: string, eventProps: GTagEvent) {
  const { category, label, ...otherEventProperties } = eventProps;
  window?.gtag?.('event', eventName, {
    ...otherEventProperties,
    event_category: category,
    event_label: label,
  });
}

export function triggerPageView(url: string) {
  window?.gtag?.('config', GA_TRACKING_ID as string, {
    page_path: url,
  });
}
