import { css, cx } from '@emotion/css';
import { styleHelpers } from '@imtbl/design-system';
import { ImmutableIMXDClient, LinkParams } from '@imtbl/imx-sdk';
import { API_URL, IMXD_DAILY_POINTS_ENDPOINT } from 'api/constants';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

import { FEATURE_FLAG } from '../../config/feature-flags';
import { useLaunchDarklyFlags } from '../LaunchDarkly';
import { RequestType } from './constants';
import {
  acceptOffer,
  buy,
  cancel,
  cancelOffer,
  closeLinkSidebarTriggerLinkClose,
  completeWithdraw,
  cryptoToFiat,
  deposit,
  disconnect,
  fiatToCrypto,
  history,
  makeOffer,
  prepareWithdraw,
  sell,
  setup,
} from './link-wrappers';
import { ImxLinkReducer } from './reducer';
import { ImmutableXClient, Link } from './rewirable-imports';
import { ImxLinkState } from './state';
import {
  CustomBuyParams,
  ImxLinkContextValue,
  ImxLinkProviderPropTypes,
} from './types';

export const ImxLinkContext = createContext<ImxLinkContextValue | undefined>(
  undefined,
);
ImxLinkContext.displayName = 'ImxLinkContext';

export const initialState: ImxLinkState = {
  starkPublicKey: '',
  walletAddress: '',
  walletError: null,
  isLinkSidebarOpen: false,

  request: {
    [RequestType.TRANSFER]: {
      inProgress: false,
      error: null,
    },

    [RequestType.DEPOSIT]: {
      inProgress: false,
      error: null,
    },

    [RequestType.PREPARE_WITHDRAW]: {
      inProgress: false,
      error: null,
    },

    [RequestType.COMPLETE_WITHDRAW]: {
      inProgress: false,
      error: null,
    },

    [RequestType.BUY]: {
      inProgress: false,
      error: null,
    },

    [RequestType.SELL]: {
      inProgress: false,
      error: null,
    },

    [RequestType.SETUP]: {
      inProgress: false,
      error: null,
    },

    [RequestType.CANCEL]: {
      inProgress: false,
      error: null,
    },

    [RequestType.FIAT_TO_CRYPTO]: {
      inProgress: false,
      error: null,
    },

    [RequestType.ACCEPT_OFFER]: {
      inProgress: false,
      error: null,
    },
    [RequestType.CANCEL_OFFER]: {
      inProgress: false,
      error: null,
    },
    [RequestType.MAKE_OFFER]: {
      inProgress: false,
      error: null,
    },
  },

  pendingDeposits: {},
};

export function ImxLinkProvider({
  children,
  shouldRenderIframe,
  mountDomElement,
  linkState,
  linkDispatch,
}: ImxLinkProviderPropTypes) {
  const [link, setLink] = useState<Link>({} as Link);

  useEffect(() => {
    if (shouldRenderIframe === undefined) {
      return;
    }

    if (mountDomElement.current && shouldRenderIframe) {
      const linkDesktopStyle = styleHelpers.renderEmotionStylesWithMediaQuery(
        'medium',
        `
          position: relative !important;
          height: 100vh !important;
        `,
      );
      const linkMobileStyle = css`
        position: relative !important;
        width: 100% !important;
        height: 90vh !important;
      `;

      setLink(
        new Link(process.env.NEXT_PUBLIC_LINK_URL || '', {
          containerElement: mountDomElement?.current || undefined,
          // unfortunately, without changing the styles within the sdk we have to override them using !important
          // this allows the iframe to fill the container which we control on the marketplace side
          className: cx([linkMobileStyle, linkDesktopStyle]),
        }),
      );
    }
    if (!shouldRenderIframe) {
      setLink(new Link(process.env.NEXT_PUBLIC_LINK_URL || '', null));
    }
  }, [mountDomElement, shouldRenderIframe]);

  const [state, dispatch] = useReducer(ImxLinkReducer, {
    ...initialState,
    ...linkState,
  });

  /*
    memo new Link() so it happens only once on app load.
    dispatch doesn't change on re-rendering so it is stable.
  */
  const imxdClient = useMemo(
    () => new ImmutableIMXDClient(IMXD_DAILY_POINTS_ENDPOINT ?? ''),
    [],
  );

  const imxClient = useMemo(
    () =>
      /* eslint-disable @typescript-eslint/no-explicit-any */
      new ImmutableXClient(
        API_URL,
        {} as any,
        {} as any,
        {} as any,
        {} as any,
        {} as any,
      ),
    [],
  );

  const flags = useLaunchDarklyFlags();

  const exposedMethods = useMemo(() => {
    // NOTE: Without the 'webUrl' it will always connect to 'DEV'!

    const providerPreference = flags[FEATURE_FLAG.PROVIDER_PREFERENCE];
    const setupParams = { providerPreference };

    return {
      setup: () => setup(link, setupParams, dispatch),
      disconnect: () => disconnect(dispatch),
      buy: (params: CustomBuyParams) => buy(link, params, dispatch),
      sell: (params: LinkParams.Sell) => sell(link, params, dispatch),
      cancel: (params: LinkParams.Cancel) => cancel(link, params, dispatch),
      deposit: (params: LinkParams.Deposit) => deposit(link, params, dispatch),
      prepareWithdraw: (params: LinkParams.PrepareWithdrawal) =>
        prepareWithdraw(
          link,
          params,
          dispatch,
          state.walletAddress,
          flags[FEATURE_FLAG.REVAMPED_PREPARE_WITHDRAW_FLOW],
        ),
      completeWithdraw: (params: LinkParams.CompleteWithdrawal) =>
        completeWithdraw(link, params, dispatch),
      history: () => history(link, dispatch),
      fiatToCrypto: () => fiatToCrypto(link, dispatch),
      cryptoToFiat: () => cryptoToFiat(link, dispatch),
      closeLinkSidebarTriggerLinkClose: () =>
        closeLinkSidebarTriggerLinkClose(dispatch),
      makeOffer: (params: LinkParams.MakeOffer) =>
        makeOffer(link, params, dispatch),
      acceptOffer: (params: LinkParams.AcceptOffer) =>
        acceptOffer(link, params, dispatch),
      cancelOffer: (params: LinkParams.CancelOffer) =>
        cancelOffer(link, params, dispatch),
      ...linkDispatch,
    };
  }, [link, linkDispatch, flags, state.walletAddress]);

  return (
    <ImxLinkContext.Provider
      value={{
        linkState: state,
        link: exposedMethods,
        imxdClient,
        imxClient,
      }}
    >
      {children}
    </ImxLinkContext.Provider>
  );
}

export function useImxLink() {
  const context = useContext(ImxLinkContext);

  if (context === undefined) {
    throw new Error('useImxLink must be used within a ImxLinkProvider');
  }

  return context;
}
