export const fetcher = async (
  url: string | RequestInfo,
  options?: RequestInit,
) => {
  const res = await fetch(url, { ...(options ? { ...options } : {}) });
  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const data = await res.json();
    throw new Error(`${res.status}: ${data?.message}`);
  }

  return res.json();
};
