const ROUTE_FUNCTIONS = {
  home: () => '/',
  collection: ({ collectionId }) => `/collections/${collectionId}`,
  collectionAsset: ({ collectionId, tokenId }) =>
    `/collections/${collectionId}/assets/${tokenId}`,
  inventoryAssets: () => '/inventory',
  inventoryOffersReceived: () => `/inventory/offers-received`,
  inventoryOffersMade: () => `/inventory/offers-made`,
  inventoryAsset: ({ collectionId, tokenId }) =>
    `/inventory/assets/${collectionId}/${tokenId}`,
  stackedAssets: ({ collectionId, stackId, tokenAddress, tokenType }) =>
    `/collections/${collectionId}/stacked-assets/${stackId}?token_type=${tokenType}&token_address=${tokenAddress}`,
  marketplaces: () => 'https://www.immutable.com/marketplaces',
};

const ROUTES = Object.keys(ROUTE_FUNCTIONS).reduce((acc, route) => {
  acc[route + 'Path'] = ROUTE_FUNCTIONS[route];

  return acc;
}, {});

export type IROUTES = {
  [TRoute in keyof typeof ROUTE_FUNCTIONS as `${TRoute}Path`]: typeof ROUTE_FUNCTIONS[TRoute];
};

export default ROUTES as IROUTES;
