import { fetcher } from 'api/fetch';
import { FEATURE_FLAG } from 'config/feature-flags';
import { useFeatureFlag } from 'hooks/useFeatureFlag.hook';
import { createContext, useContext, useEffect, useState } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FiatPricingContext = createContext<{
  fiatPrices: CryptoFiatPrices | undefined;
}>({
  fiatPrices: undefined,
});
FiatPricingContext.displayName = 'FiatPricingContext';

interface FiatPricingProviderPropTypes {
  children: JSX.Element | JSX.Element[];
}

export type CoinGeckoPricesResponse = {
  [CoinGeckoCoinIds.eth]: FiatPrice;
  [CoinGeckoCoinIds.usdc]: FiatPrice;
  [CoinGeckoCoinIds.imx]: FiatPrice;
  [CoinGeckoCoinIds.gods]: FiatPrice;
  [CoinGeckoCoinIds.gog]: FiatPrice;
  [CoinGeckoCoinIds.omi]: FiatPrice;
  [CoinGeckoCoinIds.ape]: FiatPrice;
};

export enum CoinGeckoCoinIds {
  eth = 'ethereum',
  usdc = 'usd-coin',
  imx = 'immutable-x',
  gods = 'gods-unchained',
  gog = 'guild-of-guardians',
  omi = 'ecomi',
  ape = 'apecoin',
}

export enum TokenSymbols {
  eth = 'eth',
  usdc = 'usdc',
  imx = 'imx',
  gods = 'gods',
  gog = 'gog',
  omi = 'omi',
  ape = 'ape',
}

export type CryptoFiatPrices = {
  eth: FiatPrice;
  usdc: FiatPrice;
  imx: FiatPrice;
  gods: FiatPrice;
  gog: FiatPrice;
  omi: FiatPrice;
  ape: FiatPrice;
};
export type FiatPrice = {
  usd: number;
};

export function FiatPricingProvider({
  children,
}: FiatPricingProviderPropTypes) {
  const [fiatPrices, setFiatPrices] = useState<CryptoFiatPrices | undefined>(
    undefined,
  );
  const enableUsdPricingDisplay = useFeatureFlag(
    FEATURE_FLAG.ENABLE_USD_PRICING,
  );
  const swrConfig = {
    revalidateOnFocus: true,
    shouldRetryOnError: false,
  } as SWRConfiguration;

  swrConfig.refreshInterval =
    1000 *
    parseInt(process.env.NEXT_PUBLIC_COIN_GECKO_PRICES_REFRESH_SECONDS ?? '60'); // default to refresh every minute

  const coinGeckoPricesUrl =
    process.env.NEXT_PUBLIC_COIN_GECKO_PRICES_API_URL ??
    `https://api.coingecko.com/api/v3/simple/price?ids=immutable-x%2Cethereum%2Cgods-unchained%2Cguild-of-guardians%2Cusd-coin%2Cecomi%2Capecoin&vs_currencies=usd`;

  const { data, error } = useSWR<CoinGeckoPricesResponse>(
    enableUsdPricingDisplay ? coinGeckoPricesUrl : null,
    () => fetcher(coinGeckoPricesUrl),
    swrConfig,
  );

  useEffect(() => {
    if (error) {
      console.error('Failed to retrieve prices from CoinGecko');
    } else if (data) {
      const transformedPrices = Object.keys(CoinGeckoCoinIds).reduce(
        (acc, key) => {
          return { ...acc, [TokenSymbols[key]]: data[CoinGeckoCoinIds[key]] };
        },
        {} as unknown as CryptoFiatPrices,
      );
      setFiatPrices(transformedPrices);
    }
  }, [data, error]);

  return (
    <FiatPricingContext.Provider value={{ fiatPrices }}>
      {children}
    </FiatPricingContext.Provider>
  );
}

export function useFiatPricing() {
  const context = useContext(FiatPricingContext);

  if (context === undefined) {
    console.error('Unable to find fiat pricing');
  }

  return context;
}
