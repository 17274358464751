import { css } from '@emotion/css';
import {
  colors,
  layoutHelpers,
  measurements,
  styleHelpers,
} from '@imtbl/design-system';

export const BalanceControl___isMobile = css`
  width: 100%;
  height: calc(100% - 78px);
`;

export const BalanceControl___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
  width: ${layoutHelpers.gridUnits(68)};
  height: ${layoutHelpers.gridUnits(92)};
`,
  );

export const BalanceControl_tabButton = css`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: none;
  border-bottom: solid 2px ${colors.dark[300]};
  cursor: pointer;
  background: none;
`;

export const BalanceControl_tabButton___first = css`
  border-right: solid ${measurements.standardBorderSize} ${colors.dark[300]};
`;

export const BalanceControl_tabButton___isSelected = css`
  border-bottom: solid 2px ${colors.blue[300]};
`;

export const BalanceControlCurrencyList___isMobile = css`
  padding: ${layoutHelpers.gridUnits(4)};
  height: 100%;
`;

export const BalanceControlCurrencyList___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
    cursor: auto;
  `,
  );

export const BalanceControlCurrencyList_list = css`
  margin: ${layoutHelpers.gridUnits(1)} 0;
  overflow-y: auto;
  flex-grow: 1;
`;
