import { BigNumber } from '@ethersproject/bignumber';
import { BalanceInfo } from '@imtbl/imx-sdk';
import { Observable } from 'rxjs';

import { openSidebarPanel } from '../rewireable-imports';
import { FundsRequiredSidebarPanel } from './FundsRequiredSidebarPanel.component';

export const openFundsRequiredSidebarPanel = ({
  showWalletDepositCta,
}: {
  showWalletDepositCta?: boolean;
}) => {
  openSidebarPanel({
    sidebarPanelContent: (
      <FundsRequiredSidebarPanel showWalletDepositCta={showWalletDepositCta} />
    ),
  });
};

export const getObservableWithMockBalanceOf = (l1Balance = '0') =>
  new Observable<BalanceInfo>(subscriber => {
    setTimeout(() => {
      subscriber.next({
        balance: BigNumber.from(l1Balance),
        decimal: 18,
      });
    });
  });
