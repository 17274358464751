import { css } from '@emotion/css';
import {
  Form,
  FormButton,
  measurements,
  NumberPrettyInput,
  OnClickButton,
  OuterSpace,
  PricingDisplay,
  SelectBox,
  SelectBoxOption,
} from '@imtbl/design-system';
import {
  ERC20TokenType,
  ETHTokenType,
  ImmutableMethodResults,
  LinkParams,
} from '@imtbl/imx-sdk';
import { formatTokenBalance, useL2ListBalances } from 'api/balances';
import { pickTokenDetailsFromBalanceItem, useTokens } from 'api/tokens';
import BigNumber from 'bignumber.js';
import { calculateMinimumSafeDecimalValue } from 'components/AssetDetailsContent/shared-constants';
import { useImxLink } from 'context/ImxLink';
import { useCallback, useMemo, useState } from 'react';
import { DEFAULT_ERROR_TEXT, removeCommaFormatting } from 'utils/numbers';

import {
  createFlowEvent,
  FlowEventName,
  sendAnalytics,
} from '../../../../libs/analytics';
import { PrepareWithdrawSidebarPanelProps } from './PrepareWithdrawSidebarPanel.component';
import { closeSidebarPanel } from './rewirable-imports';

type PrepareWithdrawFormUiProps = PrepareWithdrawSidebarPanelProps;

export const PrepareWithdrawFormUi = ({
  sidebarPanelId,
  handleFormUpdate,
}: PrepareWithdrawFormUiProps) => {
  const {
    linkState: { walletAddress },
  } = useImxLink();
  const { allBalancesL2 } = useL2ListBalances();
  const l2BalancesList = useMemo(
    () => allBalancesL2?.result.filter(balance => balance.balance.gt(0)),
    [allBalancesL2],
  );
  const [currentlySelectedTokenMetadata, setCurrentSelectedTokenMetadata] =
    useState<ImmutableMethodResults.ImmutableGetTokenResult | undefined>();
  const [currentlySelectedBalanceIndex, setCurrentlySelectedBalanceIndex] =
    useState<undefined | number>();
  const [temporaryInputAmount, setTemporaryInputAmount] = useState<
    string | undefined
  >();
  const currentHumanReadableBalance = useMemo(() => {
    return typeof currentlySelectedBalanceIndex === 'number'
      ? formatTokenBalance(
          l2BalancesList?.[currentlySelectedBalanceIndex]?.balance.toString(),
          currentlySelectedTokenMetadata?.decimals.toString(),
        )
      : '';
  }, [
    l2BalancesList,
    currentlySelectedTokenMetadata,
    currentlySelectedBalanceIndex,
  ]);

  const onBalanceClick = useCallback(
    () => setTemporaryInputAmount(currentHumanReadableBalance),
    [setTemporaryInputAmount, currentHumanReadableBalance],
  );

  const { prepareWithdraw } = useImxLink().link;
  const { result: tokensList = [] } = useTokens().tokens ?? {};

  const minimumSafeValue = useMemo(() => {
    return currentlySelectedTokenMetadata
      ? calculateMinimumSafeDecimalValue(currentlySelectedTokenMetadata)
      : new BigNumber(0);
  }, [currentlySelectedTokenMetadata]);

  const getAmountValidators = useCallback(
    (value: BigNumber) => {
      return [
        {
          check: () => !value.isNaN(),
          errorMsg: DEFAULT_ERROR_TEXT,
        },
        {
          check: () => value.isGreaterThanOrEqualTo(minimumSafeValue),
          errorMsg: `Please enter an amount greater than the minimum amount for this ERC20 token: ${minimumSafeValue.toFormat()}`,
        },
        {
          check: () => {
            return value.isLessThanOrEqualTo(
              new BigNumber(currentHumanReadableBalance),
            );
          },
          errorMsg: `Please enter an amount less than or equal to your current balance of: ${currentHumanReadableBalance}`,
        },
      ];
    },
    [minimumSafeValue, currentHumanReadableBalance],
  );

  const handleCurrencyTypeChange = useCallback(
    (selected: SelectBoxOption<number>, index: number) => {
      const newSelectedTokenDetails = pickTokenDetailsFromBalanceItem(
        tokensList,
        l2BalancesList?.[index],
      );
      setCurrentlySelectedBalanceIndex(index);
      setTemporaryInputAmount(undefined);
      handleFormUpdate({
        tokenAddress: newSelectedTokenDetails?.token_address,
        tokenSymbol: newSelectedTokenDetails?.symbol,
        tokenType:
          newSelectedTokenDetails?.symbol === 'ETH'
            ? ETHTokenType.ETH
            : ERC20TokenType.ERC20,
      });
      setCurrentSelectedTokenMetadata(newSelectedTokenDetails);
    },
    [
      tokensList,
      l2BalancesList,
      setCurrentlySelectedBalanceIndex,
      setTemporaryInputAmount,
      setCurrentSelectedTokenMetadata,
      handleFormUpdate,
    ],
  );

  const handleAmountChange = useCallback(
    (newAmount: BigNumber) => {
      setTemporaryInputAmount(undefined);
      handleFormUpdate({ amount: removeCommaFormatting(newAmount.toFormat()) });
    },
    [setTemporaryInputAmount, handleFormUpdate],
  );

  const onFormSubmit = useCallback(
    formData => {
      const amount = removeCommaFormatting(
        formData.currencyAmount.value.toFormat(),
      );
      let params: LinkParams.PrepareWithdrawal = {
        type: ETHTokenType.ETH,
        amount,
      };
      let analyticsFlowEvent = createFlowEvent(
        FlowEventName.prepareWithdrawErc20ProgressedToLink,
        {
          amount: params.amount,
          tokenSymbol: ETHTokenType.ETH,
          tokenType: ETHTokenType.ETH,
          walletAddress,
        },
      );
      if (currentlySelectedTokenMetadata?.symbol !== 'ETH') {
        params = {
          type: ERC20TokenType.ERC20,
          tokenAddress: currentlySelectedTokenMetadata?.token_address || '',
          symbol: currentlySelectedTokenMetadata?.symbol || '',
          amount,
        };
        analyticsFlowEvent = createFlowEvent(
          FlowEventName.prepareWithdrawErc20ProgressedToLink,
          {
            amount: params.amount,
            tokenType: params.type,
            tokenAddress: params?.tokenAddress,
            tokenSymbol: params?.symbol,
            walletAddress,
          },
        );
      }
      sendAnalytics(analyticsFlowEvent);
      closeSidebarPanel(sidebarPanelId);
      prepareWithdraw(params);
    },
    [
      currentlySelectedTokenMetadata,
      sidebarPanelId,
      prepareWithdraw,
      walletAddress,
    ],
  );

  return (
    <>
      {currentlySelectedTokenMetadata && currentHumanReadableBalance && (
        <OuterSpace top={measurements.SpacingTeeShirtAmounts['x-large']}>
          <OnClickButton
            onClick={onBalanceClick}
            testId="userBalanceAmountButton"
          >
            <PricingDisplay
              textAlign="left"
              testId="userBalanceAmountButton__pricingDisplay"
              tokenAmount={currentHumanReadableBalance}
              tokenIconUrl={currentlySelectedTokenMetadata?.image_url || ''}
              disableTooltip
            />
          </OnClickButton>
        </OuterSpace>
      )}

      <Form onSubmit={onFormSubmit} flexGrow={1}>
        {l2BalancesList && (
          <OuterSpace
            top={measurements.SpacingTeeShirtAmounts['x-large']}
            bottom={measurements.SpacingTeeShirtAmounts['large']}
          >
            <SelectBox
              fieldIsRequired
              inputId="currencyKind"
              testId="currencySelectBox"
              labelText="Choose a currency"
              onChange={handleCurrencyTypeChange}
              options={l2BalancesList?.map((balanceItem, i) => {
                const fullErc20TokenDetails = pickTokenDetailsFromBalanceItem(
                  tokensList,
                  balanceItem,
                );
                return {
                  label: `${fullErc20TokenDetails?.name} (${fullErc20TokenDetails?.symbol})`,
                  value: i,
                };
              })}
            />
          </OuterSpace>
        )}

        <OuterSpace bottom={measurements.SpacingTeeShirtAmounts['large']}>
          <NumberPrettyInput
            inputId="currencyAmount"
            testId="currencyAmount"
            labelText="Withdrawal amount"
            onChange={handleAmountChange}
            valueOveride={
              temporaryInputAmount ? temporaryInputAmount : undefined
            }
            disabled={!currentlySelectedTokenMetadata}
            validation={getAmountValidators}
            fieldIsRequired
          />
        </OuterSpace>

        <FormButton
          buttonKind="ultimate-cta"
          className={css`
            margin-top: auto;
          `}
          testId="withdrawCtaButton"
        >
          Withdraw
        </FormButton>
      </Form>
    </>
  );
};
