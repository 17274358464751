import { css } from '@emotion/css';
import {
  colors,
  DescriptiveCta,
  FlexLayout,
  HeadingText,
  layoutHelpers,
  measurements,
  OnClickButton,
  OuterSpace,
  ParagraphText,
  StyledLink,
} from '@imtbl/design-system';
import { AddFundsSidebarPanelProps } from 'components/shared/SidebarPanelScreens/shared/types';
import { useImxLink } from 'context/ImxLink';
import {
  ButtonEventName,
  createButtonEvent,
  sendAnalytics,
} from 'libs/analytics';
import { useCallback } from 'react';

import { closeSidebarPanel } from './rewireable-imports';
import { onUserCancelSidebarPanel } from './utils';

export const AddFundsSidebarPanel = ({
  testId = 'AddFundsSidebarPanel',
  sidebarPanelId,
}: AddFundsSidebarPanelProps) => {
  const { link } = useImxLink();

  const closeThisSidebarPanel = useCallback(() => {
    closeSidebarPanel(sidebarPanelId);
  }, [sidebarPanelId]);

  const cancelOnClick = () => {
    closeThisSidebarPanel();
    onUserCancelSidebarPanel();
  };

  const creditCardCtaOnClick = useCallback(() => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.marketSidebarCardCtaBtnPressed),
    );
    closeThisSidebarPanel();
    link.fiatToCrypto()?.catch(console.error);
  }, [link, closeThisSidebarPanel]);

  const depositCtaOnClick = useCallback(() => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.marketSidebarDepositCtaBtnPressed),
    );
    closeThisSidebarPanel();
    link.deposit({} as any);
  }, [link, closeThisSidebarPanel]);

  return (
    <FlexLayout
      testId={testId}
      flexDirection="column"
      padding={layoutHelpers.gridUnits(6)}
      paddingTop={layoutHelpers.gridUnits(8)}
      maxWidth={measurements.sidebarPanelDesktopWidth}
      flexGrow={1}
      alignSelf="center"
      className={css`
        overflow-y: auto;
      `}
    >
      <HeadingText
        fillColor={colors.blue[300]}
        fontSize="x-large"
        testId={`${testId}__titleText`}
      >
        Add funds
      </HeadingText>
      <OuterSpace
        top={measurements.SpacingTeeShirtAmounts.large}
        bottom={measurements.SpacingTeeShirtAmounts.medium}
      >
        <ParagraphText fillColor={colors.light[300]} fontSize="small">
          To make purchases on Marketplace, you&apos;ll need to Add funds into
          your <strong>Immutable account</strong>.
        </ParagraphText>
      </OuterSpace>
      <ParagraphText fillColor={colors.light[300]} fontSize="small">
        How would you like to Add funds?
      </ParagraphText>

      <OuterSpace
        top={measurements.SpacingTeeShirtAmounts.large}
        bottom={measurements.SpacingTeeShirtAmounts.large}
      >
        <OnClickButton onClick={creditCardCtaOnClick} testId="creditCardCta">
          <DescriptiveCta
            subtitleText="Instant"
            titleText="Credit card"
            bodyText="Purchase and add tokens directly to your Immutable account."
            iconLigature="misc_credit_card"
            subtitleFillColor={colors.blue[300]}
            iconFillColor={colors.blue[300]}
          />
        </OnClickButton>
      </OuterSpace>

      <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.large}>
        <OnClickButton onClick={depositCtaOnClick} testId="depositCta">
          <DescriptiveCta
            subtitleText="May take some time"
            titleText="Deposit from Wallet"
            bodyText="Deposit tokens from your connected Ethereum Wallet. Gas required."
            iconLigature="action_deposit"
          />
        </OnClickButton>
      </OuterSpace>

      <StyledLink
        onClick={cancelOnClick}
        fontSize="small"
        fontWeight="bold"
        className={css`
          margin-top: auto;
          align-self: center;
        `}
        testId="cancelButton"
      >
        Cancel
      </StyledLink>
    </FlexLayout>
  );
};
