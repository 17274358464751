import { css } from '@emotion/css';
import { StyledLink } from '@imtbl/design-system';
import { useCallback } from 'react';

import { onUserCancelSidebarPanel } from '../AddFunds/utils';
import { closeSidebarPanel } from '../rewireable-imports';
import { SidebarPanelComponentProps } from './types';

export const SidebarCancelButton = ({
  sidebarPanelId,
  testId = 'sidebarCancelButton',
}: SidebarPanelComponentProps) => {
  const closeThisSidebarPanel = useCallback(
    () => closeSidebarPanel(sidebarPanelId),
    [sidebarPanelId],
  );

  const cancelOnClick = () => {
    closeThisSidebarPanel();
    onUserCancelSidebarPanel();
  };

  return (
    <StyledLink
      onClick={cancelOnClick}
      fontSize="small"
      fontWeight="bold"
      className={css`
        margin-top: auto;
        align-self: center;
      `}
      testId={testId}
    >
      Cancel
    </StyledLink>
  );
};
