import {
  BaseComponentPropTypes,
  SingleSidebarDrawer,
} from '@imtbl/design-system';
import { useImxLink } from 'context/ImxLink';
import {
  ButtonEventName,
  createButtonEvent,
  sendAnalytics,
} from 'libs/analytics';
import React, { RefObject, useCallback } from 'react';

export interface LinkSideBarProps {
  mountElementRef: RefObject<HTMLElement | undefined>;
}
export const LinkSideBar = ({
  mountElementRef,
}: LinkSideBarProps & BaseComponentPropTypes) => {
  const {
    linkState,
    link: { closeLinkSidebarTriggerLinkClose },
  } = useImxLink();

  const handleClickOutside = useCallback(() => {
    const isAnEphemeralLinkFlow = linkState.request.buy.inProgress;
    if (isAnEphemeralLinkFlow && linkState.isLinkSidebarOpen) {
      sendAnalytics(
        createButtonEvent(ButtonEventName.marketLinkSidebarBackdropBtnPressed),
      );

      closeLinkSidebarTriggerLinkClose();
    }
  }, [
    linkState.isLinkSidebarOpen,
    linkState.request,
    closeLinkSidebarTriggerLinkClose,
  ]);
  return (
    <SingleSidebarDrawer
      isOpen={linkState.isLinkSidebarOpen}
      onClickOutside={handleClickOutside}
      mountElementRef={mountElementRef}
      testId="LinkSideBar"
    />
  );
};
