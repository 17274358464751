import {
  addToast,
  colors,
  Icon,
  ParagraphText,
  removeToast,
  ToastAlignment,
  ToastType,
} from '@imtbl/design-system';
import { noop } from 'lodash';
import { ReactNode } from 'react';

function ToastContent({
  testId,
  children,
}: {
  testId?: string;
  children: React.ReactNode;
}) {
  return (
    <ParagraphText testId={testId} fontSize="small">
      {children}
    </ParagraphText>
  );
}

function closeToast(id: string) {
  try {
    removeToast(id);
  } catch {
    noop;
  }
}

export function showSuccessToast(
  content: ReactNode,
  testId?: string,
  selfCloseToast = true,
) {
  const id: string = addToast({
    title: 'Success',
    type: ToastType.SUCCESS,
    alignment: ToastAlignment.TOP,
    dismissable: true,
    toastContent: <ToastContent testId={testId}>{content}</ToastContent>,
    toastIcon: (
      <Icon ligature="action_accept__circle" fillColor={colors.green[500]} />
    ),
  });

  selfCloseToast &&
    setTimeout(() => {
      closeToast(id);
      // @NOTE: @manoj has stipulated 3.5 seconds:
      // https://imtbl.slack.com/archives/C035SGDNB4J/p1659675126672989?thread_ts=1659674133.115039&cid=C035SGDNB4J
      // But we should maybe circle back to this when auditing all of the toasts
    }, 3500);

  return id;
}

export function showErrorToast(text: string, testId?: string) {
  const id: string = addToast({
    title: 'Error',
    type: ToastType.CRITICAL,
    alignment: ToastAlignment.TOP,
    dismissable: true,
    toastContent: <ToastContent testId={testId}>{text}</ToastContent>,
    toastIcon: <Icon ligature="misc_alert" fillColor={colors.red[500]} />,
  });

  setTimeout(() => {
    closeToast(id);
  }, 2000);
}
