import { AddFundsSidebarPanel as OldAddFundsSidebarPanel } from 'components/AddFundsSidebarPanel/AddFundsSidebarPanel.component';
import { FeatureFlag } from 'components/FeatureFlag';
import { FEATURE_FLAG } from 'config/feature-flags';
import { createFlowEvent, FlowEventName, sendAnalytics } from 'libs/analytics';

import { openSidebarPanel } from '../rewireable-imports';
import { AddFundsSidebarPanel } from './AddFundsSidebarPanel.component';

export const onUserCancelSidebarPanel = () =>
  sendAnalytics(
    createFlowEvent(FlowEventName.depositCancelled, {
      action: 'addFundsSidebarClosed',
    }),
  );

export const openAddFundsSidebarPanel = ({
  showWalletDepositCta,
}: {
  showWalletDepositCta?: boolean;
}) =>
  openSidebarPanel({
    sidebarPanelContent: (
      <FeatureFlaggedAddFundsSidebarPanel
        showWalletDepositCta={showWalletDepositCta}
      />
    ),
    onUserCancelSidebarPanel: onUserCancelSidebarPanel,
  });

export const FeatureFlaggedAddFundsSidebarPanel = ({
  showWalletDepositCta,
  sidebarPanelId,
}: {
  showWalletDepositCta?: boolean;
  sidebarPanelId?: string;
}) => (
  <FeatureFlag
    name={FEATURE_FLAG.ENABLE_ALWAYS_ENABLED_BUY_BUTTON}
    fallback={<OldAddFundsSidebarPanel sidebarPanelId={sidebarPanelId} />}
  >
    <AddFundsSidebarPanel
      sidebarPanelId={sidebarPanelId}
      showWalletDepositCta={showWalletDepositCta}
    />
  </FeatureFlag>
);
