import { css } from '@emotion/css';
import {
  SidebarPanelChildContentPropTypes,
  StyledLink,
} from '@imtbl/design-system';
import { useCallback } from 'react';

import { openFundsRequiredSidebarPanel } from '../FundsRequired';
import { closeSidebarPanel } from '../rewireable-imports';
import { SidebarCreditCardCta } from '../shared';
import { SidebarDescription } from '../shared/SidebarDescription.component';
import { SidebarErrorTitle } from '../shared/SidebarErrorTitle.component';
import { SidebarLayout } from '../shared/SidebarLayout.component';

type InsufficientFundsErrorSidebarPanelProps =
  SidebarPanelChildContentPropTypes;

export const InsufficientFundsErrorSidebarPanel = ({
  sidebarPanelId,
  testId = 'insufficientFundsErrorSidebarPanel',
}: InsufficientFundsErrorSidebarPanelProps) => {
  const closeThisSidebarPanel = useCallback(
    () => closeSidebarPanel(sidebarPanelId),
    [sidebarPanelId],
  );

  const handleGoBackButtonClick = () => {
    closeThisSidebarPanel();
    // To get to this screen, the user should already have an L1 wallet.
    openFundsRequiredSidebarPanel({ showWalletDepositCta: true });
  };

  return (
    <SidebarLayout testId={testId}>
      <SidebarErrorTitle testId={`${testId}__title`}>
        Insufficient Funds
      </SidebarErrorTitle>
      <SidebarDescription>
        You have insufficient funds in your L1 wallet available to deposit now.
        Add funds to your L1 wallet and try depositing again. <br />
        <br />
        <strong>Try adding funds with your credit card instead:</strong>
      </SidebarDescription>
      <SidebarCreditCardCta sidebarPanelId={sidebarPanelId} />
      <StyledLink
        onClick={handleGoBackButtonClick}
        className={css`
          margin-top: auto;
          align-self: center;
          width: 100%;
        `}
        fontSize="small"
        fontWeight="bold"
        textAlign="center"
        testId={`${testId}__goBackButton`}
      >
        Go back
      </StyledLink>
    </SidebarLayout>
  );
};
