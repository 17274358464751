export enum RequestType {
  TRANSFER = 'transfer',
  DEPOSIT = 'deposit',
  PREPARE_WITHDRAW = 'prepareWithdraw',
  COMPLETE_WITHDRAW = 'completeWithdraw',
  BUY = 'buy',
  SELL = 'sell',
  SETUP = 'setup',
  CANCEL = 'cancel',
  FIAT_TO_CRYPTO = 'fiatToCrypto',
  MAKE_OFFER = 'makeOffer',
  CANCEL_OFFER = 'cancelOffer',
  ACCEPT_OFFER = 'acceptOffer',
}

export enum ImxLinkActions {
  OPEN_LINK_SIDEBAR = 'OPEN_LINK_SIDEBAR',
  CLOSE_LINK_SIDEBAR = 'CLOSE_LINK_SIDEBAR',
  CONNECT_WALLET_LOADING = 'CONNECT_WALLET_LOADING',
  CONNECT_WALLET_SUCCESS = 'CONNECT_WALLET_SUCCESS',
  CONNECT_WALLET_ERROR = 'CONNECT_WALLET_ERROR',
  WALLET_DISCONNECT = 'WALLET_DISCONNECT',
  BUY_LOADING = 'BUY_LOADING',
  BUY_SUCCESS = 'BUY_SUCCESS',
  BUY_ERROR = 'BUY_ERROR',
  SELL_LOADING = 'SELL_LOADING',
  SELL_SUCCESS = 'SELL_SUCCESS',
  SELL_ERROR = 'SELL_ERROR',
  CANCEL_LOADING = 'CANCEL_LOADING',
  CANCEL_SUCCESS = 'CANCEL_SUCCESS',
  CANCEL_ERROR = 'CANCEL_ERROR',
  DEPOSIT_LOADING = 'DEPOSIT_LOADING',
  DEPOSIT_SUCCESS = 'DEPOSIT_SUCCESS',
  DEPOSIT_ERROR = 'DEPOSIT_ERROR',
  PREPARE_WITHDRAW_LOADING = 'PREPARE_WITHDRAW_LOADING',
  PREPARE_WITHDRAW_SUCCESS = 'PREPARE_WITHDRAW_SUCCESS',
  PREPARE_WITHDRAW_ERROR = 'PREPARE_WITHDRAW_ERROR',
  COMPLETE_WITHDRAW_LOADING = 'COMPLETE_WITHDRAW_LOADING',
  COMPLETE_WITHDRAW_SUCCESS = 'COMPLETE_WITHDRAW_SUCCESS',
  COMPLETE_WITHDRAW_ERROR = 'COMPLETE_WITHDRAW_ERROR',
  HISTORY_LOADING = 'HISTORY_LOADING',
  HISTORY_SUCCESS = 'HISTORY_SUCCESS',
  HISTORY_ERROR = 'HISTORY_ERROR',
  FIAT_TO_CRYPTO_LOADING = 'FIAT_TO_CRYPTO_LOADING',
  FIAT_TO_CRYPTO_SUCCESS = 'FIAT_TO_CRYPTO_SUCCESS',
  FIAT_TO_CRYPTO_ERROR = 'FIAT_TO_CRYPTO_ERROR',
  CRYPTO_TO_FIAT_LOADING = 'CRYPTO_TO_FIAT_LOADING',
  CRYPTO_TO_FIAT_SUCCESS = 'CRYPTO_TO_FIAT_SUCCESS',
  CRYPTO_TO_FIAT_ERROR = 'CRYPTO_TO_FIAT_ERROR',
  MAKE_OFFER_LOADING = 'MAKE_OFFER_LOADING',
  MAKE_OFFER_SUCCESS = 'MAKE_OFFER_SUCCESS',
  MAKE_OFFER_ERROR = 'MAKE_OFFER_ERROR',
  ACCEPT_OFFER_LOADING = 'ACCEPT_OFFER_LOADING',
  ACCEPT_OFFER_SUCCESS = 'ACCEPT_OFFER_SUCCESS',
  ACCEPT_OFFER_ERROR = 'ACCEPT_OFFER_ERROR',
  CANCEL_OFFER_LOADING = 'CANCEL_OFFER_LOADING',
  CANCEL_OFFER_SUCCESS = 'CANCEL_OFFER_SUCCESS',
  CANCEL_OFFER_ERROR = 'CANCEL_OFFER_ERROR',
}
