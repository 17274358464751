import { BaseTextPropTypes, StyledLink } from '@imtbl/design-system';
import { useImxLink } from 'context/ImxLink';

import { TEST_ID } from './consts';
import { onAddFundsButtonClick } from './utils';

export function AddFundsStyledLink({
  testId,
  fontSize,
  buttonEventName,
}: BaseTextPropTypes<HTMLLinkElement> & {
  buttonEventName: string;
}) {
  const { fiatToCrypto } = useImxLink().link;

  return (
    <StyledLink
      onClick={() => onAddFundsButtonClick({ fiatToCrypto, buttonEventName })}
      testId={`${testId}${TEST_ID}`}
      fontSize={fontSize}
      underline
    >
      Add funds
    </StyledLink>
  );
}
