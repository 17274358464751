import { ERC721TokenType } from '@imtbl/imx-sdk';
import { Draft, produce } from 'immer';

import { ImxLinkActions } from './constants';
import { ImxLinkState } from './state';
import { ImxLinkActionTypes } from './types';

export function ImxLinkReducer(
  state: ImxLinkState,
  action: ImxLinkActionTypes,
) {
  return produce(state, (draft: Draft<ImxLinkState>) => {
    switch (action.type) {
      case ImxLinkActions.OPEN_LINK_SIDEBAR: {
        draft.isLinkSidebarOpen = true;
        break;
      }

      case ImxLinkActions.CLOSE_LINK_SIDEBAR: {
        draft.isLinkSidebarOpen = false;
        break;
      }

      case ImxLinkActions.CONNECT_WALLET_LOADING: {
        draft.request.setup.inProgress = true;
        break;
      }

      case ImxLinkActions.CONNECT_WALLET_SUCCESS: {
        draft.request.setup.inProgress = false;
        draft.walletAddress = action.payload.walletAddress;
        draft.starkPublicKey = action.payload.starkPublicKey;
        break;
      }

      case ImxLinkActions.CONNECT_WALLET_ERROR: {
        draft.request.setup.inProgress = false;
        draft.walletError = action.payload;
        break;
      }

      case ImxLinkActions.WALLET_DISCONNECT: {
        draft.walletAddress = '';
        draft.starkPublicKey = '';
        break;
      }

      case ImxLinkActions.BUY_LOADING: {
        draft.request.buy.inProgress = true;
        draft.request.buy.error = null;
        break;
      }

      case ImxLinkActions.BUY_SUCCESS: {
        draft.request.buy.inProgress = false;
        break;
      }

      case ImxLinkActions.BUY_ERROR: {
        draft.request.buy.inProgress = false;
        break;
      }

      case ImxLinkActions.SELL_LOADING: {
        draft.request.sell.inProgress = true;
        draft.request.sell.error = null;
        break;
      }

      case ImxLinkActions.SELL_SUCCESS: {
        draft.request.sell.inProgress = false;
        break;
      }

      case ImxLinkActions.SELL_ERROR: {
        draft.request.sell.inProgress = false;
        break;
      }

      case ImxLinkActions.CANCEL_LOADING: {
        draft.request.cancel.inProgress = true;
        draft.request.cancel.error = null;
        break;
      }

      case ImxLinkActions.CANCEL_SUCCESS: {
        draft.request.cancel.inProgress = false;
        break;
      }

      case ImxLinkActions.CANCEL_ERROR: {
        draft.request.cancel.inProgress = false;
        break;
      }

      case ImxLinkActions.DEPOSIT_LOADING: {
        draft.request.deposit.inProgress = true;
        draft.request.deposit.error = null;
        if (action.payload.type === ERC721TokenType.ERC721) {
          const { tokenAddress, tokenId } = action.payload;
          const key = `${tokenAddress}#${tokenId}`;
          draft.pendingDeposits[key] = {};
        }
        break;
      }

      case ImxLinkActions.DEPOSIT_SUCCESS: {
        draft.request.deposit.inProgress = false;
        if (action.payload.type === ERC721TokenType.ERC721) {
          const { tokenAddress, tokenId } = action.payload;
          const key = `${tokenAddress}#${tokenId}`;
          delete draft.pendingDeposits[key];
        }
        break;
      }

      case ImxLinkActions.DEPOSIT_ERROR: {
        draft.request.deposit.inProgress = false;
        draft.request.deposit.error = action.payload.error;
        if (action.payload.data.type === ERC721TokenType.ERC721) {
          const { tokenAddress, tokenId } = action.payload.data;
          const key = `${tokenAddress}#${tokenId}`;
          delete draft.pendingDeposits[key];
        }
        break;
      }

      case ImxLinkActions.PREPARE_WITHDRAW_LOADING: {
        draft.request.prepareWithdraw.inProgress = true;
        break;
      }

      case ImxLinkActions.PREPARE_WITHDRAW_SUCCESS: {
        draft.request.prepareWithdraw.inProgress = false;
        draft.request.prepareWithdraw.error = null;
        break;
      }

      case ImxLinkActions.PREPARE_WITHDRAW_ERROR: {
        draft.request.prepareWithdraw.inProgress = false;
        draft.request.prepareWithdraw.error = action.payload;
        break;
      }

      case ImxLinkActions.COMPLETE_WITHDRAW_LOADING: {
        draft.request.completeWithdraw.inProgress = true;
        break;
      }

      case ImxLinkActions.COMPLETE_WITHDRAW_SUCCESS: {
        draft.request.completeWithdraw.inProgress = false;
        draft.request.completeWithdraw.error = null;
        break;
      }

      case ImxLinkActions.COMPLETE_WITHDRAW_ERROR: {
        draft.request.completeWithdraw.inProgress = false;
        draft.request.completeWithdraw.error = action.payload;
        break;
      }

      case ImxLinkActions.MAKE_OFFER_LOADING: {
        draft.request.makeOffer.inProgress = true;
        break;
      }

      case ImxLinkActions.MAKE_OFFER_SUCCESS: {
        draft.request.makeOffer.inProgress = false;
        draft.request.makeOffer.error = null;
        break;
      }

      case ImxLinkActions.MAKE_OFFER_ERROR: {
        draft.request.makeOffer.inProgress = false;
        draft.request.makeOffer.error = action.payload;
        break;
      }

      case ImxLinkActions.CANCEL_OFFER_LOADING: {
        draft.request.cancelOffer.inProgress = true;
        break;
      }

      case ImxLinkActions.CANCEL_OFFER_SUCCESS: {
        draft.request.cancelOffer.inProgress = false;
        draft.request.cancelOffer.error = null;
        break;
      }

      case ImxLinkActions.CANCEL_OFFER_ERROR: {
        draft.request.cancelOffer.inProgress = false;
        draft.request.cancelOffer.error = action.payload;
        break;
      }

      case ImxLinkActions.ACCEPT_OFFER_LOADING: {
        draft.request.acceptOffer.inProgress = true;
        break;
      }

      case ImxLinkActions.ACCEPT_OFFER_SUCCESS: {
        draft.request.acceptOffer.inProgress = false;
        draft.request.acceptOffer.error = null;
        break;
      }

      case ImxLinkActions.ACCEPT_OFFER_ERROR: {
        draft.request.acceptOffer.inProgress = false;
        draft.request.acceptOffer.error = action.payload;
        break;
      }

      default:
        console.warn(`Unknown action: ${action.type}`);
        break;
    }
  });
}
