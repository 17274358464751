import '../styles/globals.css';

import { LinkProvidedApp } from 'components/LinkProvidedApp.component';
import { FiatPricingProvider } from 'context/FiatPricing/FiatPricing';
import { LaunchDarklyProvider } from 'context/LaunchDarkly';
import { useInjectTrackingPixel } from 'hooks/useInjectTrackingPixel';
import { useSetResetUTMParams } from 'hooks/useSetResetUTMParams';
import App, { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { StrictMode, useEffect, useRef } from 'react';
import { FeatureFlags } from 'types';
import {
  initialiseLaunchDarklyNodeClient,
  setNewLdUserThenGetFlags,
} from 'utils/launch-darkly-flags';
import { initLogRocket } from 'utils/user-logging';

import { FEATURE_FLAG } from '../config/feature-flags';
import { prettyPrintVersions } from '../utils/versions';

interface ImxAppPropTypes extends AppProps {
  flags: FeatureFlags;
}

export type TypeSafeServerFeatureFlags = {
  imx4079_featuredCollectionAddresses: string;
  mp315_enableMarketplaceTakerFee: boolean;
  mp368_enableGlobalNavbar: boolean;
  mp951_enableStackedAssets: boolean;
  'mp1233_enable-redesigned-homepage': boolean;
};

export let serverLaunchDarklyFlagsInstance: TypeSafeServerFeatureFlags = {
  imx4079_featuredCollectionAddresses: '',
  mp315_enableMarketplaceTakerFee: false,
  mp368_enableGlobalNavbar: false,
  mp951_enableStackedAssets: false,
  'mp1233_enable-redesigned-homepage': false,
};

export default function IMXApp({
  Component,
  pageProps,
  flags,
}: ImxAppPropTypes) {
  /**
   * Inject tracking pixel
   * for 'Akuma' analytics.
   */
  useInjectTrackingPixel();

  /**
   * Set UTM parameters for tracking,
   * after resetting previous values.
   */
  useSetResetUTMParams();

  /**
   * useRef returns a persisted object for the lifecycle of the component.
   * This avoids the flags being reset to {} when getInitialProps runs client side.
   */
  const memoFlags = useRef(flags);
  const enableLogRocket =
    memoFlags.current[FEATURE_FLAG.ENABLE_LOGROCKET_RECORDING];

  if (typeof window !== 'undefined' && enableLogRocket) {
    initLogRocket();
  }

  useEffect(() => {
    prettyPrintVersions();
  }, []);

  return (
    <StrictMode>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <LaunchDarklyProvider flags={memoFlags.current}>
        <FiatPricingProvider>
          <LinkProvidedApp pageProps={pageProps} Component={Component} />
        </FiatPricingProvider>
      </LaunchDarklyProvider>
    </StrictMode>
  );
}

initialiseLaunchDarklyNodeClient();

IMXApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  // req is only available server side so launchDarklyFlags will only be called for SSR.
  const flags = appContext.ctx.req
    ? await setNewLdUserThenGetFlags(appContext.ctx.req)
    : {};

  serverLaunchDarklyFlagsInstance = flags as TypeSafeServerFeatureFlags;
  return { ...appProps, flags };
};
