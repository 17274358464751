import { SidebarPanelProvider, SidebarPanelSystem } from '@imtbl/design-system';
import { useBrowserLayoutEffect } from 'hooks/useBrowserLayoutEffect.hook';
import React, { ReactNode, useRef, useState } from 'react';

export function DsSidebarPanelProvider({ children }: { children: ReactNode }) {
  const mountDom = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useBrowserLayoutEffect(() => {
    mountDom.current = document.querySelector('#sidebar-panel');
    setMounted(true);
  }, []);

  return (
    <SidebarPanelProvider>
      {mounted && mountDom.current && (
        <SidebarPanelSystem portalMountPoint={mountDom.current} />
      )}
      {children}
      <div id="sidebar-panel" />
    </SidebarPanelProvider>
  );
}
