import { createFlowEvent, FlowEventName, sendAnalytics } from 'libs/analytics';

import { AddFundsSidebarPanel } from './AddFundsSidebarPanel.component';
import { openSidebarPanel } from './rewireable-imports';

export const onUserCancelSidebarPanel = () =>
  sendAnalytics(
    createFlowEvent(FlowEventName.depositCancelled, {
      action: 'addFundsSidebarClosed',
    }),
  );

export const openAddFundsSidebarPanel = () =>
  openSidebarPanel({
    sidebarPanelContent: <AddFundsSidebarPanel />,
    onUserCancelSidebarPanel,
  });
