import {
  DescriptiveCta,
  measurements,
  OnClickButton,
  OuterSpace,
} from '@imtbl/design-system';
import { useImxLink } from 'context/ImxLink';
import {
  ButtonEventName,
  createButtonEvent,
  sendAnalytics,
} from 'libs/analytics';
import { useCallback } from 'react';

import { openInsufficientFundsErrorSidebarPanel } from '../InsufficientFundsError/utils';
import { closeSidebarPanel } from '../rewireable-imports';
import { SidebarDepositCtaProps } from './types';

export const SidebarDepositCta = ({
  sidebarPanelId,
  prefillInfo,
  testId = 'sidebarDepositCta',
}: SidebarDepositCtaProps) => {
  const { link } = useImxLink();

  const closeThisSidebarPanel = useCallback(
    () => closeSidebarPanel(sidebarPanelId),
    [sidebarPanelId],
  );
  const depositCtaOnClick = useCallback(() => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.marketSidebarDepositCtaBtnPressed),
    );

    closeThisSidebarPanel();

    prefillInfo
      ? prefillInfo.hasEnoughL1Funds
        ? link.deposit({
            type: prefillInfo.prefillTokenType,
            tokenAddress: prefillInfo.prefillTokenAddress,
            symbol: prefillInfo.prefillTokenSymbol,
          } as any) // @NOTE: Link typing is inconsistent with docs here, so we cast to any
        : openInsufficientFundsErrorSidebarPanel()
      : link.deposit({} as any);
  }, [closeThisSidebarPanel, link, prefillInfo]);

  return (
    <OuterSpace bottom={measurements.SpacingTeeShirtAmounts.large}>
      <OnClickButton onClick={depositCtaOnClick} testId={`${testId}__button`}>
        <DescriptiveCta
          testId={`${testId}__button__descriptiveCta`}
          subtitleText="May take some time"
          titleText="Deposit from Wallet"
          bodyText="Deposit tokens from your connected Ethereum Wallet. Gas required."
          iconLigature="action_deposit"
        />
      </OnClickButton>
    </OuterSpace>
  );
};
