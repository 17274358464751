import { useImxLink } from 'context/ImxLink';
import { useEffect } from 'react';
import { LocalStorageKey } from 'types';

export function AutoConnectWallet() {
  /**
   * Automatically call link.setup() if we already have a wallet address
   */
  const { link } = useImxLink();
  useEffect(() => {
    const walletAddress = localStorage.getItem(LocalStorageKey.WALLET_ADDRESS);
    if (walletAddress !== null) link?.setup()?.catch(console.error);
  }, [link]);

  return null;
}
