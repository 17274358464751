import { css, cx } from '@emotion/css';
import {
  AppHeaderBar as DsAppHeaderBar,
  AppHeaderBarItemWithContainer,
  AppHeaderBarNavItem,
  colors,
  CustomUiText,
  HeadingText,
  Icon,
  ImmutableLogoFullWordmarkAndSymbolLight,
  InlineEllipsizedTextLine,
  StandardButton,
  StyledLink,
} from '@imtbl/design-system';
import { BalanceControl } from 'components/BalanceControl';
import { NextLink, StyledNextLink } from 'components/DsConnectorButtons';
import {
  BALANCE_CONTROL,
  COLLECTION_PAGE,
  HOME_PAGE,
  INVENTORY_PAGE,
} from 'config/pages';
import { useImxLink } from 'context/ImxLink';
import { useFeatureFlag } from 'hooks/useFeatureFlag.hook';
import {
  ButtonEventName,
  createButtonEvent,
  createFlowEvent,
  FlowEventName,
  sendAnalytics,
} from 'libs/analytics';
import { useRouter } from 'next/router';
import ROUTES from 'utils/router';

import { FEATURE_FLAG } from '../../config/feature-flags';
import { FeatureFlag } from '../FeatureFlag';
import { WalletDropdownMenu } from '../WalletDropdownMenu';
import * as styles from './AppHeaderBar.styles';

export function AppHeaderBar() {
  const {
    linkState: { walletAddress, request },
    link,
  } = useImxLink();
  const walletLoading = request.setup.inProgress;
  const { route } = useRouter();

  const showRevampedDepositFlow = useFeatureFlag(
    FEATURE_FLAG.ENABLE_REVAMPED_DEPOSIT,
  );

  const useAlternateHeaderStyling = useFeatureFlag(
    FEATURE_FLAG.ENABLE_NEW_HOMEPAGE,
  );

  // NOTE: Route contains /inventory*' or '/inventory/assets*'.
  const isInventorySelected = route.match(INVENTORY_PAGE.matcher) != null;

  // NOTE: Route contains '/assets*'.
  const isMarketplaceSelected =
    !isInventorySelected && route.match(HOME_PAGE.matcher) != null;

  const handleConnectWalletClick = () => {
    sendAnalytics(
      createButtonEvent(ButtonEventName.globalNavConnectWalletPressed),
      createFlowEvent(FlowEventName.connectWalletStarted),
    );
    link?.setup()?.catch(console.error);
  };

  const handleBalancesButtonClick = () => {
    // @NOTE: this could result in duplicate click events if users press button to close balances dropdown.
    sendAnalytics(
      createButtonEvent(ButtonEventName.globalNavBalancesBtnPressed),
    );
  };

  return (
    <div>
      <DsAppHeaderBar
        testId="appHeader"
        logoArea={
          <NextLink
            href={ROUTES.homePath()}
            title="Click here to goto homepage"
          >
            <ImmutableLogoFullWordmarkAndSymbolLight
              className={css`
                min-height: 36px;
              `}
            />
          </NextLink>
        }
        localNavItems={
          !useAlternateHeaderStyling ? (
            <AppHeaderBarNavItem selected={isMarketplaceSelected}>
              <StyledNextLink
                href={ROUTES.homePath()}
                testId="localNavBtn--marketplace"
              >
                <Icon
                  ligature="action_search"
                  className={cx(
                    styles.TopLevelNavIcon___isMobile,
                    styles.TopLevelNavIcon___isDesktop,
                  )}
                />
                {COLLECTION_PAGE.label}
              </StyledNextLink>
            </AppHeaderBarNavItem>
          ) : (
            <></>
          )
        }
        globalNavItems={
          <>
            {walletAddress && (
              <>
                <AppHeaderBarNavItem selected={isInventorySelected}>
                  <StyledNextLink
                    href={ROUTES.inventoryAssetsPath()}
                    testId="globalNavBtn--inventory"
                  >
                    <Icon
                      ligature="misc_inventory"
                      className={cx(
                        styles.TopLevelNavIcon___isMobile,
                        styles.TopLevelNavIcon___isDesktop,
                      )}
                    />
                    {INVENTORY_PAGE.label}
                  </StyledNextLink>
                </AppHeaderBarNavItem>

                {!showRevampedDepositFlow && (
                  <AppHeaderBarNavItem testId="globalNavBtn--buyCrypto">
                    <StyledLink onClick={link.fiatToCrypto} asButton>
                      <Icon
                        ligature="misc_credit_card"
                        className={cx(
                          styles.TopLevelNavIcon___isMobile,
                          styles.TopLevelNavIcon___isDesktop,
                        )}
                      />
                      Buy Crypto
                    </StyledLink>
                  </AppHeaderBarNavItem>
                )}

                <FeatureFlag name={FEATURE_FLAG.ENABLE_SELL_CRYPTO}>
                  <AppHeaderBarNavItem testId="globalNavBtn--sellCrypto">
                    <StyledLink onClick={link.cryptoToFiat} asButton>
                      <Icon
                        ligature="misc_credit_card"
                        className={cx(
                          styles.TopLevelNavIcon___isMobile,
                          styles.TopLevelNavIcon___isDesktop,
                        )}
                      />
                      Sell Crypto
                    </StyledLink>
                  </AppHeaderBarNavItem>
                </FeatureFlag>

                <AppHeaderBarItemWithContainer
                  onClick={handleBalancesButtonClick}
                  title={<CustomUiText>{BALANCE_CONTROL.title}</CustomUiText>}
                  titleIcon={
                    <Icon ligature="currency_dollar__circle" iconSize="29px" />
                  }
                  itemContainerHeading={
                    <CustomUiText fontSize="large" fontWeight="bold">
                      {BALANCE_CONTROL.containerTitle}
                    </CustomUiText>
                  }
                  testId="globalNavBtn--balances"
                >
                  <BalanceControl testId="balanceControl" />
                </AppHeaderBarItemWithContainer>
              </>
            )}
          </>
        }
        walletFunctionalityClassName={cx({
          [styles.WalletFunctionalityContainer]: !walletAddress,
          [styles.WalletFunctionalityContainer___isDesktop]: !walletAddress,
        })}
        walletFunctionality={
          walletAddress ? (
            <AppHeaderBarItemWithContainer
              title={
                <InlineEllipsizedTextLine
                  TextComponentTag={CustomUiText}
                  text={walletAddress}
                  leftSideLength={6}
                />
              }
              statusIcon={
                <div
                  className={styles.WalletFunctionalityContainer_StatusIcon}
                />
              }
              className={
                styles.WalletFunctionalityContainer_DropdownButton___isDesktop
              }
              itemContainerHeading={
                <InlineEllipsizedTextLine
                  TextComponentTag={HeadingText}
                  text={walletAddress}
                  leftSideLength={6}
                />
              }
              testId="walletFunctionalityBtn--dropdown"
            >
              <WalletDropdownMenu />
            </AppHeaderBarItemWithContainer>
          ) : (
            <AppHeaderBarNavItem
              className={cx(
                styles.WalletFunctionalityContainer_ConnectButton___isDesktop,
              )}
              disabled={walletLoading}
              testId="walletFunctionalityBtn--connect"
            >
              <StandardButton
                onClick={handleConnectWalletClick}
                buttonKind="ultimate-cta"
                testId="walletFunctionalityBtn--connectWallet"
              >
                Connect Wallet
              </StandardButton>
            </AppHeaderBarNavItem>
          )
        }
        className={
          useAlternateHeaderStyling
            ? styles.AppHeaderBar___isAlternateStyling
            : ''
        }
      />
      {!route.includes('deprecation-notice') && (
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '15px',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: colors.blue[300],
          }}
        >
          <CustomUiText
            fontSize="small"
            fontWeight="regular"
            style={{
              marginRight: '20px',
            }}
          >
            <b>This marketplace will be retired shortly</b>
            <br />
            Check out one of the many other marketplaces on Immutable X!
          </CustomUiText>
          <StandardButton
            onClick={() => {
              window.location.href = 'https://tokentrove.com/';
            }}
            buttonKind="primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25px"
              height="25px"
              viewBox="0 0 41.013519 30.314939"
              style={{
                marginRight: '5px',
              }}
            >
              <path
                d="m 112.16185,191.12824 c -2.60373,0.0421 -7.8788,2.20111 -7.91373,8.3628 l -0.0227,3.9765 h 4.49792 l 0.0139,-3.96048 c 0.0157,-4.41658 3.36074,-7.32529 5.20382,-8.37882 z m 4.38268,0 c -1.22132,0.87948 -5.61092,3.05526 -5.66477,8.39019 l -0.0398,3.94911 h 9.186 v -0.87437 h 9.864 v 0.87437 h 2.6634 6.06785 l -0.0398,-3.94911 c -0.0539,-5.33493 -4.44346,-7.51071 -5.66478,-8.39019 h -0.36328 -5.04465 z m 18.97558,0 c 1.84309,1.05353 5.18813,3.96224 5.20382,8.37882 l 0.0139,3.96048 h 4.49792 l -0.0227,-3.9765 c -0.0349,-6.16169 -5.31,-8.3207 -7.91372,-8.3628 z m -13.97382,13.147 v 9.42268 h 6.82284 v -9.42268 z m 3.50418,1.64021 a 1.6335346,1.6335346 0 0 1 1.63401,1.63298 1.6335346,1.6335346 0 0 1 -0.81701,1.41283 v 1.85415 h -1.63401 v -1.85363 a 1.6335346,1.6335346 0 0 1 -0.81648,-1.41335 1.6335346,1.6335346 0 0 1 1.63349,-1.63298 z m -20.82509,0.4625 v 5.29167 h 15.80059 v -5.29167 z m 25.66459,0 v 5.29167 h 15.34583 v -5.29167 z m -25.66149,8.0822 v 2.24172 c 2.56774,0.13054 4.67853,2.14938 4.99195,4.74131 h 30.76143 c 0.32419,-2.68046 2.57103,-4.74854 5.25704,-4.74854 v -2.23449 h -15.34893 v 0.91364 h -9.864 v -0.91364 z m 41.01042,3.6313 c -1.98829,0 -3.60413,1.45425 -3.91656,3.35173 h 3.91656 z m -41.01042,0.0134 v 3.3383 h 3.65146 c -0.29829,-1.81213 -1.78608,-3.21538 -3.65146,-3.3383 z"
                style={{
                  display: 'inline',
                  fill: '#f7c331',
                  fillOpacity: 1,
                  stroke: 'none',
                  strokeWidth: 0.43375894,
                  strokeLinecap: 'round',
                  strokeMiterlimit: 4,
                  strokeDasharray: 'none',
                  strokeOpacity: 1,
                }}
                transform="translate(-104.22538,-191.12824)"
              />
            </svg>
            TokenTrove
          </StandardButton>
          <span style={{ margin: '0 6px' }} />
          <StandardButton
            onClick={() => {
              window.location.href = 'https://wax.atomichub.io/';
            }}
            buttonKind="primary"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="25px"
              height="25px"
              viewBox="0 0 260.000000 260.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{
                marginRight: '5px',
              }}
            >
              <g
                transform="translate(0.000000,260.000000) scale(0.100000,-0.100000)"
                fill="#ea923e"
                stroke="none"
              >
                <path d="M987 2568 c-54 -11 -86 -30 -134 -78 -70 -71 -116 -173 -147 -323 -14 -68 -20 -109 -31 -242 -2 -16 -3 -33 -4 -36 0 -3 -13 -6 -28 -7 -53 -1 -168 -23 -258 -48 -232 -65 -365 -182 -365 -321 0 -64 14 -106 56 -166 61 -85 153 -159 287 -228 45 -23 80 -39 77 -36 -3 2 -42 32 -88 66 -367 271 -338 496 75 581 134 28 250 37 250 19 1 -55 16 -209 27 -284 l14 -90 -53 -70 c-364 -482 -437 -918 -174 -1034 453 -200 1542 772 1746 1558 27 104 33 246 14 311 -33 109 -127 180 -243 185 -114 4 -293 -61 -441 -160 -28 -19 -55 -35 -59 -35 -4 0 -20 24 -34 53 -75 147 -206 299 -301 350 -62 33 -133 46 -186 35z m172 -119 c72 -36 181 -139 264 -250 31 -42 57 -79 57 -84 0 -4 -15 -22 -33 -39 -18 -17 -26 -25 -17 -18 9 6 21 12 27 12 6 0 16 7 23 15 11 14 17 10 41 -27 34 -50 37 -47 10 8 l-20 39 96 46 c157 77 249 103 363 102 216 -1 256 -191 113 -528 -19 -44 -35 -81 -37 -82 -1 -2 -40 13 -87 32 -65 27 -283 101 -354 120 -6 1 -26 38 -44 81 -19 44 -38 84 -43 91 -6 8 -74 11 -229 11 l-221 -1 -21 -43 -21 -44 -111 1 -111 2 2 61 c9 208 49 373 109 452 62 82 139 95 244 43z m-159 -693 c52 -4 102 -9 110 -11 8 -2 40 -6 70 -10 107 -11 280 -45 435 -86 108 -28 365 -116 365 -125 0 -6 -63 -106 -111 -177 l-47 -67 -60 137 -60 138 -84 23 c-105 29 -345 78 -419 87 -31 3 -65 7 -75 9 -11 2 -56 7 -102 11 l-82 7 -16 -38 c-17 -40 -15 -70 12 -229 33 -190 121 -531 144 -560 6 -7 73 -10 177 -9 92 0 172 -2 177 -4 12 -7 -216 -204 -295 -255 l-26 -17 -46 103 c-99 226 -200 587 -232 827 -3 25 -8 56 -10 70 -9 52 -16 170 -12 177 6 9 46 9 187 -1z m333 -346 c20 -52 47 -124 61 -160 l24 -65 -127 0 c-84 0 -126 4 -126 11 0 22 119 324 125 316 4 -4 23 -50 43 -102z m-665 -344 c-43 -100 -83 -190 -88 -201 -5 -11 -32 -71 -59 -132 l-49 -113 61 -1 c302 -2 400 0 407 11 5 8 21 -13 42 -53 l35 -65 -96 -50 c-211 -110 -370 -127 -440 -47 -105 120 -30 439 192 818 l52 88 11 -37 c10 -35 6 -48 -68 -218z" />
                <path d="M2184 1498 c-24 -48 -25 -53 -9 -63 226 -136 334 -258 321 -363 -6 -50 -18 -72 -67 -120 -56 -55 -227 -129 -364 -156 l-30 -6 -55 126 c-30 70 -60 129 -65 130 -6 2 -43 -38 -82 -89 -40 -51 -107 -127 -148 -169 -41 -42 -75 -82 -75 -89 0 -29 25 -79 40 -79 18 -1 380 -1 427 0 l32 0 -30 70 c-16 39 -27 75 -24 80 3 6 21 10 39 10 28 0 72 7 161 24 44 9 151 49 166 62 8 8 20 14 25 14 18 0 93 79 114 121 25 49 30 122 12 185 -29 102 -158 237 -329 345 l-32 20 -27 -53z" />
                <path d="M1172 361 l-43 -27 31 -40 c272 -347 560 -342 649 11 7 28 15 56 17 63 2 6 0 12 -4 12 -5 0 -16 -24 -25 -53 -19 -59 -61 -130 -102 -171 -111 -111 -266 -53 -424 159 -25 33 -47 63 -50 66 -4 4 -25 -5 -49 -20z" />
              </g>
            </svg>
            AtomicHub
          </StandardButton>
        </div>
      )}
    </div>
  );
}
