import { injectTrackingPixel } from 'libs/analytics/akuma';
import { useEffect } from 'react';

/**
 * NOTE: This custom hook is meant to be used
 * only once, within the main 'pages/_app.tsx'.
 */
export const useInjectTrackingPixel = () => {
  useEffect(() => injectTrackingPixel(), []);
};
