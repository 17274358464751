import {
  filterUTMParams,
  resetUTMParams,
  setUTMParams,
} from 'libs/analytics/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/**
 * NOTE: This custom hook is meant to be used
 * only once, within the main 'pages/_app.tsx'.
 */
export const useSetResetUTMParams = () => {
  const { query: q = {} } = useRouter();
  const utmParams = filterUTMParams(q);

  useEffect(() => {
    resetUTMParams();
  }, []);

  useEffect(() => {
    setUTMParams(utmParams);
  }, [utmParams]);
};
