import {
  ERC20TokenType,
  ERC721TokenType,
  ETHTokenType,
  ImmutableMethodParams,
  ImmutableMethodResults,
} from '@imtbl/imx-sdk';
import { useImxLink } from 'context/ImxLink';
import { useMemo } from 'react';
import useSWR from 'swr';
import { errorLogger } from 'utils/error-logger';

import { GetBalanceResponse } from './balances';
import { API_URL } from './constants';

export type SingleTokenDetails =
  ImmutableMethodResults.ImmutableListTokensResult['result'][0];
export type TokenDetailsList = SingleTokenDetails[];

export function useTokens() {
  const { imxClient } = useImxLink();
  const params = {} as ImmutableMethodParams.ImmutableListTokensParams;

  const { data, error } =
    useSWR<ImmutableMethodResults.ImmutableListTokensResult>(
      `${API_URL}/tokens`,
      () => imxClient.listTokens(params),
    );

  if (error) {
    errorLogger('Failed to fetch tokens', error);
  }

  const tokenSymbolStr = data?.result
    ? data.result.reduce((str, token, i) => {
        return str + (i > 0 ? '_' : '') + token.symbol;
      }, '')
    : '';

  /* NOTE: Data / Result is mostly static, unless its for a diff token! */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedResult = useMemo(() => data?.result, [tokenSymbolStr]);

  /* NOTE: Data / Result is mostly static, unless its for a diff token! */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedData = useMemo(() => data, [memoizedResult, data?.cursor]);

  /* NOTE: When error changes the message changes too! */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedError = useMemo(() => error, [error?.message]);

  const tokensFactory = () => ({
    tokens: memoizedData,
    tokensError: memoizedError,
    tokensLoading: !memoizedData && !memoizedError,
  });

  const memoizedTokens = useMemo(tokensFactory, [memoizedData, memoizedError]);
  return memoizedTokens;
}

export function pickTokenDetails({
  tokenSymbol,
  tokenAddress,
  tokensList,
}: {
  tokenSymbol?: string;
  tokenAddress?: string;
  tokensList?: TokenDetailsList;
}): ImmutableMethodResults.ImmutableGetTokenResult | undefined {
  if (!tokensList?.length) return undefined;
  return tokensList.find(
    token =>
      tokenSymbol === token.symbol || token.token_address === tokenAddress,
  );
}

export function pickTokenImagery({
  tokenSymbol,
  tokenAddress,
  tokensList,
}: {
  tokenSymbol?: string;
  tokenAddress?: string;
  tokensList: TokenDetailsList;
}): string {
  return (
    pickTokenDetails({ tokenSymbol, tokenAddress, tokensList })?.image_url || ''
  );
}

export function pickTokenSymbol({
  tokenAddress,
  tokensList,
}: {
  tokenAddress: string;
  tokensList: TokenDetailsList;
}): string {
  /* @NOTE: API doesn't return a token_address for ETH, so we default
     to ETH if no matches are found. However, note that the balance endpoint
     requires 'eth' as a token symbol for ETH tokens, but link.deposit
     requires an empty string instead. */
  return pickTokenDetails({ tokenAddress, tokensList })?.symbol || 'ETH';
}

export function useGetTokenImagery({
  tokenSymbol,
  tokenAddress,
}: {
  tokenSymbol?: string;
  tokenAddress?: string;
}): string {
  const { result: tokensList = [] } = useTokens().tokens ?? {};
  return pickTokenImagery({ tokenSymbol, tokenAddress, tokensList });
}

export function pickTokenDetailsFromBalanceItem(
  tokensList: TokenDetailsList,
  balanceItem: GetBalanceResponse,
) {
  return pickTokenDetails({ tokenSymbol: balanceItem?.symbol, tokensList });
}

export function pickTokenDetailsForOrder(
  order?: ImmutableMethodResults.ImmutableGetOrderV3ResultTS,
  tokensList?: TokenDetailsList,
) {
  switch (order?.sell?.type) {
    case ERC20TokenType.ERC20:
      return pickTokenDetails({
        tokensList,
        tokenAddress: order.sell.data.token_address,
      });
    case ERC721TokenType.ERC721:
      if (order.buy.type === ERC20TokenType.ERC20) {
        return pickTokenDetails({
          tokensList,
          tokenAddress: order.buy.data.token_address,
        });
      } else
        return pickTokenDetails({
          tokensList,
          tokenAddress: '',
        });

    case ETHTokenType.ETH:
    default:
      return pickTokenDetails({ tokensList, tokenSymbol: ETHTokenType.ETH });
  }
}
