// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { marketplaceVersion } from 'utils/versions';

import { isProdBuildEnvironment } from './environment';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_TRACES_SAMPLE_RATE = Number.parseFloat(
  process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE ?? '1.0',
);
const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
const SENTRY_FORCE_DISABLED = process.env.SENTRY_FORCE_DISABLED === 'true';

Sentry.init({
  enabled: !SENTRY_FORCE_DISABLED && isProdBuildEnvironment,
  dsn:
    SENTRY_DSN ||
    'https://e7a0b295a8de4d79a053b1a23d333c8f@o1118369.ingest.sentry.io/6152246',
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  environment: SENTRY_ENVIRONMENT,
  release: marketplaceVersion,
  integrations: [new BrowserTracing()],

  // @NOTE: connecting Sentry up to LogRocket, per the docs instructions:
  // https://docs.logrocket.com/docs/sentry
  beforeSend(event: Sentry.Event) {
    const logRocketSession = LogRocket.sessionURL;
    if (logRocketSession !== null && event.extra) {
      event.extra.LogRocket = logRocketSession;
    }

    return event;
  },
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

// @NOTE: connecting Sentry up to LogRocket, per the docs instructions:
// https://docs.logrocket.com/docs/sentry
LogRocket.getSessionURL(sessionURL => {
  Sentry.configureScope(scope => {
    scope.setTag('recording', 'yes');
    scope.setExtra('sessionURL', sessionURL);
  });
});
