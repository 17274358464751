export enum COLLECTION_PAGE {
  matcher = 'collections/.*',
  title = 'Immutable',
  label = 'Browse NFTs',
  description = 'Immutable is the first layer 2 for NFTs on Ethereum. Zero gas fees, instant trades and up to 9,000 TPS. Our marketplace is https://market.x.immutable.com Get trading, gas free.',
  twitterTitle = 'Immutable Marketplace for NFTs',
  twitterDescription = 'Immutable is the first layer 2 for NFTs on Ethereum. Zero gas fees, instant trades and up to 9,000 TPS. Our marketplace is https://market.x.immutable.com Get trading, gas free.',
}

export enum HOME_PAGE {
  matcher = '/',
  title = 'Immutable Marketplace - Buy & Sell NFTs gas free',
  label = 'Home',
  description = 'A gas-free marketplace for NFTs on Immutable. Buy and sell NFTs without paying any gas fees.',
  twitterTitle = 'Immutable Marketplace - Buy & Sell NFTs gas free',
  twitterDescription = 'A gas-free marketplace for NFTs on Immutable. Buy and sell NFTs without paying any gas fees.',
}

export enum INVENTORY_PAGE {
  matcher = '/inventory',
  label = 'My Assets',
  title = 'My Assets',
  description = 'Inventory Description',
}

export enum BALANCE_CONTROL {
  title = 'Balances',
  containerTitle = 'Balances',
}

export enum ERROR_PAGE {
  matcher = '/error',
  label = 'Error',
  title = 'Error',
  description = 'Unknown Error',
}

export enum NOT_FOUND_PAGE {
  /**
   * @NOTE: This route doesnt exist in the app's routes, but it doesn't need
   * to, as the error boundary will catch it and show the '<NotFound />' page.
   */
  route = '/404',
  label = 'Not Found',
  title = 'Not Found',
  description = '404 Error: Not found',
}

export enum TRANSACTIONS_PAGE {
  label = 'Transactions',
}

const IMMUTABLE_BASE_SITE_URL = 'https://www.immutable.com';

export const PRIVACY_PAGE = {
  url: `${IMMUTABLE_BASE_SITE_URL}/privacy-policy`,
  label: 'Privacy',
};

export const COLLECTION_STATEMENT_PAGE = {
  url: `${IMMUTABLE_BASE_SITE_URL}/collection-statement`,
  label: 'Collection Statement',
};

export const TERMS_PAGE = {
  url: `https://support.immutable.com/hc/en-us/articles/4404514460303-Immutable-X-Protocol-Licence-Agreement`,
  label: 'Terms & Conditions',
};

export enum SUPPORT_PAGE {
  label = 'Support',
  url = 'https://support.immutable.com',
}
