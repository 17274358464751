import { css } from '@emotion/css';
import { colors, layoutHelpers, styleHelpers } from '@imtbl/design-system';

export const TopLevelNavIcon___isMobile = css`
  font-size: ${layoutHelpers.gridUnits(7)};
  margin-right: ${layoutHelpers.gridUnits(3)};
`;

export const TopLevelNavIcon___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery('medium', `display: none`);

export const WalletFunctionalityContainer = css`
  border: none;
`;

export const WalletFunctionalityContainer___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `&::before { display: none; }`,
  );

export const WalletFunctionalityContainer_DropdownButton___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `
    margin-right: 0;

    &:last-child {
      margin-right: 0;
    }`,
  );

export const WalletFunctionalityContainer_ConnectButton___isDesktop =
  styleHelpers.renderEmotionStylesWithMediaQuery(
    'medium',
    `flex-grow: 1;
      width: 100%;
      min-height: auto;
      margin-right: 0;
      margin-top: 11px;
      max-height: 40px;
      padding-left: ${layoutHelpers.gridUnits(3)};
      padding-right: ${layoutHelpers.gridUnits(3)};
      border: none;
    `,
  );

export const WalletFunctionalityContainer_StatusIcon = css`
  height: ${layoutHelpers.gridUnits(2)};
  width: ${layoutHelpers.gridUnits(2)};
  border-radius: 50%;
  background: ${colors.green[500]};
`;

export const AppHeaderBar___isAlternateStyling = css`
  &::before {
    height: 0px;
  }
`;
