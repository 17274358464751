import { ToastProvider, ToastSystem } from '@imtbl/design-system';
import { useBrowserLayoutEffect } from 'hooks/useBrowserLayoutEffect.hook';
import React, { ReactNode, useRef, useState } from 'react';

export function DsToastProvider({ children }: { children: ReactNode }) {
  const mountDom = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useBrowserLayoutEffect(() => {
    mountDom.current = document.querySelector('#toast');
    setMounted(true);
  }, []);

  return (
    <ToastProvider>
      {mounted && mountDom.current && (
        <ToastSystem portalMountPoint={mountDom.current} />
      )}
      {children}
      <div id="toast" />
    </ToastProvider>
  );
}
