import { ImxLinkProvider } from 'context/ImxLink';
import { useShouldRenderIframe } from 'hooks/useShouldRenderIframe.hook';
import { useRef } from 'react';
import { SWRConfig } from 'swr';

import { AppFooter } from './AppFooter';
import { AppHeaderBar } from './AppHeaderBar';
import { InflightPurchaseProvider } from './AssetDetailsContent/InflightPurchase';
import { AutoConnectWallet } from './AutoConnectWallet';
import { BodyOverflowService } from './BodyOverflowService';
import { DsModalProvider, DsTooltipProvider } from './DsProviders';
import { DsSidebarPanelProvider } from './DsProviders/DsSidebarPanelProvider.component';
import { DsToastProvider } from './DsProviders/DsToastProvider.component';
import { GlobalErrorBoundary } from './GlobalErrorBoundary';
import { LinkSideBar } from './LinkSideBar';

export const LinkProvidedApp = ({ pageProps, Component }) => {
  const { shouldRenderIframe } = useShouldRenderIframe();
  const elementRef = useRef<HTMLElement>();

  return (
    <ImxLinkProvider
      shouldRenderIframe={shouldRenderIframe}
      mountDomElement={elementRef}
    >
      <DsTooltipProvider>
        <DsModalProvider>
          <DsToastProvider>
            <InflightPurchaseProvider>
              <DsSidebarPanelProvider>
                <GlobalErrorBoundary>
                  <SWRConfig value={{ shouldRetryOnError: false }}>
                    <BodyOverflowService />
                    <AutoConnectWallet />
                    {shouldRenderIframe && (
                      <LinkSideBar mountElementRef={elementRef} />
                    )}
                    <AppHeaderBar />
                    <Component {...pageProps} />
                    <AppFooter />
                  </SWRConfig>
                </GlobalErrorBoundary>
              </DsSidebarPanelProvider>
            </InflightPurchaseProvider>
          </DsToastProvider>
        </DsModalProvider>
      </DsTooltipProvider>
    </ImxLinkProvider>
  );
};
